import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";
import versionUpdate from "@/libs/versionUpdate";

const routes = [
  {
    path: "/",
    redirect: "/personal_page",
  },
  {
    path: "/home",
    name: "Home",
    meta: {
      title: "WizConnect",
    },
    component: Home,
  },
  {
    path: "/splash",
    name: "Splash",
    meta: {
      title: "启动中",
    },
    component: () => import("../views/Splash.vue"),
  },
  {
    path: "/about",
    name: "About",
    meta: {
      title: "about",
    },
    component: () => import("../views/About.vue"),
  },
  {
    path: "/license",
    name: "License",
    meta: {
      title: "激活验证码",
    },
    component: () => import("../views/License.vue"),
  },
  {
    path: "/active",
    name: "Active",
    meta: {
      title: "获取激活码",
    },
    component: () => import("../views/Active.vue"),
  },
  {
    path: "/personal_page",
    name: "PersonalPage",
    meta: {
      title: "WizConnect客户支持",
    },
    component: () => import("../views/PersonalPage.vue"),
  },
  {
    path: "/active_success",
    name: "ActiveSuccess",
    meta: {
      title: "成功获取激活码",
    },
    component: () => import("../views/ActiveSuccess.vue"),
  },
  {
    path: "/register",
    name: "Register",
    meta: {
      title: "登录管理注册码",
    },
    component: () => import("../views/Register.vue"),
  },
  {
    path: "/admin",
    name: "Admin",
    meta: {
      title: "管理注册码",
    },
    component: () => import("../views/Admin.vue"),
  },
  {
    path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
    name: "NotFound",
    meta: {
      title: "NotFound",
    },
    component: () => import("../views/NotFoundComponent.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(() => {
  //判断当前代码版本是否与服务器中代码版本一致，如不一致则刷新页面获取最新
  versionUpdate.isNewVersion();
});

export default router;
