<template>
  <div
    class="bottom_container"
    id="myDebugTimeChartContainer"
    v-loading="loadingData"
  >
    <el-button
      class="divider-btn"
      @click="restoreDimensions"
      v-show="this.timeDimensions"
      >恢复至原始窗口</el-button
    >
    <div class="debug-time-chart-container" v-loading="loadingDbGraphData">
      <div
        id="myDebugTimeChart"
        class="debug-time-chart-container-canvas"
      ></div>
      <div id="legendDebugTime" class="debug-time-chart-container-legend"></div>
    </div>
  </div>
</template>

<script>
import { getDeviceDataDebugGraph } from "../api/device";
import { inject, toRefs, ref } from "vue";
import Dygraph from "dygraphs";

export default {
  name: "MyDatabaseShowDebugGraph",
  props: {},
  components: {},
  methods: {
    restoreDimensions() {
      this.initDbData(true);
    },
    initDbData(reset = false) {
      if (this.loadingData) {
        console.log("getDeviceDataDebugGraph is loading");
        return;
      }
      console.log("getDeviceDataDebugGraph initDbData", reset);
      const graphFlag = Date.now();
      this.graphFlag = graphFlag;
      if (reset) {
        this.timeDimensions = null;
      }
      this.loadingData = true;
      getDeviceDataDebugGraph(
        {
          deviceNameSelect: this.deviceNameSelect,
          runIdSelect: this.runIdSelect,
          timeDimensions: this.timeDimensions,
        },
        (data) => {
          this.loadingData = false;
          console.log("getDeviceDataDebugGraph data for graph", data);
          if (data && data.data && data.data.data && data.data.code > 0) {
            this.myDebugTimeChartData = data.data.data.list || [];
            Object.values(this.myDebugTimeChartData).forEach((value) => {
              value[0] = new Date(value[0]);
            });
            this.myDebugTimeChartDataValueRange = [
              data.data.data.min || 0,
              data.data.data.max || 2,
            ];
            const valueDelta =
              (this.myDebugTimeChartDataValueRange[1] -
                this.myDebugTimeChartDataValueRange[0]) /
              10;
            this.myDebugTimeChartDataValueRange[0] =
              this.myDebugTimeChartDataValueRange[0] - valueDelta;
            this.myDebugTimeChartDataValueRange[1] =
              this.myDebugTimeChartDataValueRange[1] + valueDelta;
            this.myDebugTimeChartDataDateWindow = [
              data.data.data.startTime || 0,
              data.data.data.endTime || 0,
            ];
            const dateDelta =
              (this.myDebugTimeChartDataDateWindow[1] -
                this.myDebugTimeChartDataDateWindow[0]) /
              10;
            this.myDebugTimeChartDataDateWindow[0] =
              this.myDebugTimeChartDataDateWindow[0] - dateDelta;
            this.myDebugTimeChartDataDateWindow[1] =
              this.myDebugTimeChartDataDateWindow[1] + dateDelta;
            const dbDataDeviceNameExperiments = data.data.data.labels || [];
            if (dbDataDeviceNameExperiments.length === 0) {
              dbDataDeviceNameExperiments.push("设备");
            }
            this.myDebugTimeChartDataLabels = [
              "时间",
              ...dbDataDeviceNameExperiments,
            ];
            this.reloadGraph(graphFlag);
          }
        },
        (err) => {
          this.loadingData = false;
          console.log("getDeviceDataDebugGraph err for graph", err);
        }
      );
    },
    reloadGraph(graphFlag) {
      if (this.graphFlag !== graphFlag) {
        console.log(
          "getDeviceDataDebugGraph no need to reload Grapth",
          graphFlag,
          this.graphFlag,
          this.graphFlag - graphFlag
        );
        return;
      }
      const optionsDebugTime = {
        file: this.myDebugTimeChartData,
        labels: this.myDebugTimeChartDataLabels,
        valueRange: this.myDebugTimeChartDataValueRange,
        dateWindow: this.myDebugTimeChartDataDateWindow,
      };
      console.log(
        "getDeviceDataDebugGraph reloadGraph optionsDebugTime",
        optionsDebugTime
      );
      if (this.myDebugTimeChart) {
        this.myDebugTimeChart.updateOptions(optionsDebugTime);
      }
      this.resize();
    },
    resize() {
      this.$nextTick(() => {
        if (this.myDebugTimeChart) {
          const resizeSt = Date.now();
          const graphDebugTime = document.getElementById(
            "myDebugTimeChartContainer"
          );
          console.log(
            "getDeviceDataDebugGraph graphDebugTime",
            graphDebugTime,
            graphDebugTime.offsetWidth,
            graphDebugTime.offsetHeight
          );
          this.myDebugTimeChart.resize(400, 400);
          this.myDebugTimeChart.resize(graphDebugTime.offsetWidth - 200, 400);
          console.log(
            "getDeviceDataDebugGraph resize time cost",
            Date.now() - resizeSt
          );
        }
      });
    },
    updateDebugTimeDimensions(minDate, maxDate, yRanges) {
      console.log(
        "getDeviceDataDebugGraph updateDebugTimeDimensions",
        minDate,
        maxDate,
        yRanges
      );
      if (this.timeDimensions != [minDate, maxDate]) {
        this.timeDimensions = [minDate, maxDate];
        this.initDbData();
      }
    },
  },
  mounted() {
    const graphDebugTime = document.getElementById("myDebugTimeChart");
    this.myDebugTimeChart = new Dygraph(
      graphDebugTime,
      this.myDebugTimeChartData,
      {
        drawPoints: true,
        highlightSeriesOpts: { strokeWidth: 2 },
        labelsKMB: true,
        connectSeparatedPoints: true,
        legend: "always",
        animatedZooms: true,
        valueRange: this.myDebugTimeChartDataValueRange,
        labels: this.myDebugTimeChartDataLabels,
        labelsDiv: document.getElementById("legendDebugTime"),
        labelsSeparateLines: true,
        zoomCallback: this.updateDebugTimeDimensions,
        dateWindow: this.myDebugTimeChartDataDateWindow,
      }
    );
    this.resize();
    this.initDbData();
  },
  unmounted() {
    this.myDebugTimeChartData = ref([]);
    this.myDebugTimeChartDataValueRange = [0, 2];
    this.myDebugTimeChartDataDateWindow = [0, 0];
    this.myDebugTimeChartDataLabels = ["时间", "设备"];
    if (this.myDebugTimeChart) {
      this.myDebugTimeChart.destroy();
      this.myDebugTimeChart = null;
    }
  },
  setup() {
    const deviceData = inject("deviceData");
    const menuData = inject("menuData");
    const myDebugTimeChart = ref(null);
    const myDebugTimeChartData = ref([]);
    const myDebugTimeChartDataValueRange = ref([0, 2]);
    const myDebugTimeChartDataDateWindow = ref([0, 0]);
    const myDebugTimeChartDataLabels = ref(["时间", "设备"]);
    const graphFlag = ref(Date.now());
    const loadingData = ref(false);
    const timeDimensions = ref(null);

    const data = {
      ...toRefs(deviceData),
      ...toRefs(menuData),
      myDebugTimeChart,
      myDebugTimeChartData,
      myDebugTimeChartDataValueRange,
      myDebugTimeChartDataDateWindow,
      myDebugTimeChartDataLabels,
      graphFlag,
      loadingData,
      timeDimensions,
    };
    return data;
  },
};
</script>

<style scoped>
.bottom_container {
  min-height: 400px;
  margin-top: 60px;
}
.debug-time-chart-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 20px;
}

.debug-time-chart-container-canvas {
  width: 400px;
  height: 400px;
  display: inline-block;
  vertical-align: top;
}

.debug-time-chart-container-legend {
  display: inline-block;
  vertical-align: top;
}

.divider-btn {
  height: 36px;
}
</style>
