<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { getTime, setTime, updateTime } from "./api/device";

export default {
  name: "App",
  watch: {
    $route(to) {
      if (to.meta.title) {
        document.title = to.meta.title;
      }
    },
  },
  created() {
    getTime(
      {},
      (res) => {
        console.log("getTime res", res);
        if (res && res.data && res.data.data) {
          const currentSec = Date.now() / 1000;
          const serverSec = res.data.data;
          // larger than 30 seconds
          if (Math.abs(currentSec - serverSec) > 30) {
            setTime(
              {
                time: currentSec,
              },
              (res) => {
                console.log("setTime res", res);
                if (res && res.data && res.data.data) {
                  updateTime(
                    {},
                    (res) => {
                      console.log("updateTime res", res);
                    },
                    (err) => {
                      console.log("updateTime err", err);
                    }
                  );
                }
              },
              (err) => {
                console.log("setTime err", err);
              }
            );
          }
        }
      },
      (err) => {
        console.log("getTime err", err);
      }
    );
  },
};
</script>

<style>
#app {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size: small;
  height: 100%;
  min-width: 800px;
  max-width: 4096px;
}
</style>
