<template>
  <el-select
    ref="dragSelect"
    v-model="selectVal"
    v-bind="$attrs"
    class="drag-select"
    multiple
    @change="selectChange"
    v-on="$attrs"
  >
    <slot />
  </el-select>
</template>

<script>
import { ref } from "vue";
import Sortable from "sortablejs";
export default {
  name: "DragSelect",
  props: {
    value: {
      type: Array,
    },
    change: {
      type: Function,
    },
  },
  setup(props, context) {
    const selectVal = ref(props.value || "");
    const upDateValue = (e) => {
      const targetValue = e.target.value;
      selectVal.value = targetValue;
      context.emit("update:value", targetValue);
    };
    const defaultChange = () => {};
    const selectChange = ref(props.change || defaultChange);
    return {
      selectVal,
      upDateValue,
      selectChange,
    };
  },
  mounted() {
    this.setSort();
  },
  methods: {
    setSort() {
      const el = this.$refs.dragSelect.$el.querySelectorAll(
        ".el-select__tags > span"
      )[0];
      this.sortable = Sortable.create(el, {
        ghostClass: "sortable-ghost", // Class name for the drop placeholder,
        setData: function (dataTransfer) {
          dataTransfer.setData("Text", "");
          // to avoid Firefox bug
          // Detail see : https://github.com/RubaXa/Sortable/issues/1012
        },
        onEnd: (evt) => {
          console.log(
            "onEnd",
            "evt",
            evt,
            "selectVal",
            this.selectVal,
            "selectChange",
            this.selectChange
          );
          const targetRow = this.selectVal.splice(evt.oldIndex, 1)[0];
          this.selectVal.splice(evt.newIndex, 0, targetRow);
          this.selectChange && this.selectChange(this.selectVal);
        },
      });
    },
  },
};
</script>

<style scoped>
.drag-select > :deep(.sortable-ghost) {
  opacity: 0.8;
  color: #fff !important;
  background: #42b983 !important;
}

.drag-select > :deep(.el-tag) {
  cursor: pointer;
}
</style>
