import { reactive } from "vue";
import {
  milliUnit,
  formatDateFromNs,
  formatTimeFromNs,
} from "../util/date_util";
import { uuid } from "../util/uuid_util";
const addDeviceItemInterfaceOptionsRS232 = "RS232";
const addDeviceItemInterfaceOptionsTcp = "TCP";
const addDeviceItemInterfaceOptionsCCR = "CCR";
const addDeviceItemInterfaceOptionsWebService = "AMC";
const addDeviceItemInterfaceOptionsFile = "File Monitor";
const InterfaceWebServiceProtocolOptionsProtocolRs232 = "xbpi";
const InterfaceWebServiceProtocolOptionsProtocolCCR = "ccr_tcp_client";
const InterfaceWebServiceProtocolOptionsProtocolWebCCR = "ccr_web";
const InterfaceWebServiceProtocolOptionsProtocolSbiTcpClient = "sbi_tcp_client";
const InterfaceWebServiceProtocolOptions = {};
// InterfaceWebServiceProtocolOptions[
//   InterfaceWebServiceProtocolOptionsProtocolCCR
// ] = "CCR 机器人";
InterfaceWebServiceProtocolOptions[
  InterfaceWebServiceProtocolOptionsProtocolSbiTcpClient
] = "TCP Client";
const InterfaceWebServiceProtocolOptionsProtocolValidCCRs = [
  InterfaceWebServiceProtocolOptionsProtocolCCR,
  InterfaceWebServiceProtocolOptionsProtocolWebCCR,
];

const deviceData = {
  loading: false,
  services: [],
  required_color: "#303133",
  startParamsInternalId: null,
  experimentId: "",
  lastExperimentId: "",
  manualIdEnabled: false,
  startWithEndEnabled: false,
  startWithEndPreciseDateEnabled: false,
  startWithEndDeltaSec: 0,
  startWithEndPreciseDate: "",
  addDeviceItem: {},
  addDeviceItemModelOptions: [
    "Cubis II MCA 系列",
    "Cubis II MCE 系列",
    "Entris II BCA/BCE 系列",
    "Secura/Quintix/Practum 系列",
    "BSA/GL/CPA 系列",
    "Cubis MCM/MPS/MSA/MSU 系列",
    "Cubis MSE 系列",
    "Speedcal Mobile",
    "CCR",
  ],
  addDeviceItemInterfaceOptionsRS232: addDeviceItemInterfaceOptionsRS232,
  addDeviceItemInterfaceOptionsTcp: addDeviceItemInterfaceOptionsTcp,
  addDeviceItemInterfaceOptionsCCR: addDeviceItemInterfaceOptionsCCR,
  addDeviceItemInterfaceOptionsWebService:
    addDeviceItemInterfaceOptionsWebService,
  addDeviceItemInterfaceOptionsFile: addDeviceItemInterfaceOptionsFile,
  addDeviceItemInterfaceOptions: [
    addDeviceItemInterfaceOptionsRS232,
    addDeviceItemInterfaceOptionsTcp,
    addDeviceItemInterfaceOptionsCCR,
    addDeviceItemInterfaceOptionsWebService,
  ],
  addDeviceItemInterfaceOptionsDisabled: [addDeviceItemInterfaceOptionsFile],
  addDeviceItemInterfaceRS232ComOptions: [],
  InterfaceWebServiceProtocolOptionsProtocolRs232:
    InterfaceWebServiceProtocolOptionsProtocolRs232,
  InterfaceWebServiceProtocolOptionsProtocolCCR:
    InterfaceWebServiceProtocolOptionsProtocolCCR,
  InterfaceWebServiceProtocolOptionsProtocolWebCCR:
    InterfaceWebServiceProtocolOptionsProtocolWebCCR,
  InterfaceWebServiceProtocolOptionsProtocolValidCCRs:
    InterfaceWebServiceProtocolOptionsProtocolValidCCRs,
  InterfaceWebServiceProtocolOptionsProtocolSbiTcpClient:
    InterfaceWebServiceProtocolOptionsProtocolSbiTcpClient,
  InterfaceWebServiceProtocolOptions: InterfaceWebServiceProtocolOptions,
  addDeviceItemInterfaceRS232RateOptions: ["1200", "9600", "19200"],
  addDeviceItemInterfaceRS232BitsOptions: ["7", "8"],
  addDeviceItemInterfaceRS232CrcOptions: [
    "奇（Odd）",
    "偶（Even）",
    "无（None）",
  ],
  addDeviceItemInterfaceRS232StopOptions: ["1", "2"],
  addDeviceItemInterfaceRS232ControlOptions: [
    "硬件 (RTS/CTS)",
    "软件 (XON/XOFF)",
    "无 (None)",
  ],
  addDeviceItemScreenOptions: [],
  screenLockEnabled: false, //控制是否锁定目标窗口
  addDeviceItemKeyboardOptions: ["时间", "日期", "重量值", "单位"],
  errorTipsForAutoPrintAndTareSameKeyBoard:
    "打印和去皮快捷键设置不能一样，请检查！",

  reportTemplateSelect: "",
  reportTemplates: [],
  lastDeviceQueryData: {
    key: "lastDeviceQueryData",
    value: undefined,
  },
  sendToClipboardInfo: {
    key: "sendToClipboardInfo",
    value: undefined,
  },
  exportDataTemplateInfo: {
    key: "exportDataTemplateInfo",
    value: undefined,
  },
  lastDeviceQueryEnabled: false,
  allDeviceQueryEnabled: false,
  deviceTagNames: [],
  deviceTags: [],
  deviceNameSelect: [],
  deviceNameEnabledItem: null,
  deviceNames: [],
  rawDeviceNames: [],
  runIdSelect: [],
  runIds: [],
  runValidIds: [],
  dbTypeSelectOnlyCcr: false,
  dbTypeSelectOnlyNormal: false,
  selectStartTime: "",
  selectEndTime: "",
  dbDataList: [],
  dbDataDeviceList: [],
  dbDataDeviceNames: [],
  dbDataKey: Math.random(),
  groupByData: {},
  dbDataListCurrentPage: 1,
  dbDataListPageSize: 10,
  dbDataListTotal: 0,
  loadingDbData: false,
  loadingDbGraphData: false,
  loadingDbDataAgain: false,
  loadingDbGraphDataAgain: false,
  loadingPorts: false,
  loadingShortcutPrint: false,
  loadingShortcutTare: false,
  loadingShortcutCmd: false,
  addDeviceItemInterfaceRS232ComOptionsParams: {},

  enableFloat: false,
  enableModify: false,
  enableCopy: false,
  enableStart: false,
  enableStop: false,
  enableDelete: false,
  selectedData: [],
  multiDeviceShow: false,
  selectedDataListIds: [],
  selectedDataListAllTheDeviceIds: {},
  selectedDataListIdAll: false,
  selectedDataDeviceListIds: [],
  selectedDataDeviceListIdAll: false,

  experimentIdDisabled: false,
  autoStopDisabled: false,
  ccrDeviceEnabled: false,
  tianpingDeviceEnabled: false,

  isWizConnector: false,

  setRequiredColor(value) {
    this.required_color = value;
  },

  setDeviceNames(value) {
    this.deviceNames = value;
  },

  setDeviceTagNames(value) {
    this.deviceTagNames = value;
  },

  setDeviceTags(value) {
    this.deviceTags = value;
  },

  setRawDeviceNames(value) {
    this.rawDeviceNames = value;
  },

  setRunIds(value) {
    this.runIds = value;
    const runValidIds = [];
    Object.values(this.runIds).forEach((item) => {
      let valid = this.runIdSelect.includes(item.experiment_id);
      if (!valid) {
        if (!this.deviceNameSelect || !this.deviceNameSelect.length) {
          valid = true;
        } else if (item.service_saved_ret) {
          Object.values(item.service_saved_ret).forEach((itemRet) => {
            if (!valid && this.deviceNameSelect.includes(itemRet.name)) {
              valid = true;
            }
          });
        }
      }
      if (valid) {
        runValidIds.push(item);
      }
    });
    this.runValidIds = runValidIds;
  },

  setRunIdSelect(value) {
    console.log("setRunIdSelect", this.runIdSelect, value);
    this.runIdSelect = value;
    this.updateDbTypeSelect();
  },

  setDeviceNameSelect(value) {
    console.log("setDeviceNameSelect", this.deviceNameSelect, value);
    this.deviceNameSelect = value;
  },

  updateDbTypeSelect() {
    this.dbTypeSelectOnlyCcr = false;
    this.dbTypeSelectOnlyNormal = false;
    Object.values(this.runIds).forEach((item) => {
      if (this.runIdSelect.includes(item.experiment_id)) {
        if (item.ccr_file_name) {
          this.dbTypeSelectOnlyCcr = true;
          this.dbTypeSelectOnlyNormal = false;
        } else {
          this.dbTypeSelectOnlyCcr = false;
          this.dbTypeSelectOnlyNormal = true;
        }
      }
    });
    Object.values(this.runIds).forEach((item) => {
      if (this.dbTypeSelectOnlyCcr) {
        item.disabled = !item.ccr_file_name;
      } else if (this.dbTypeSelectOnlyNormal) {
        item.disabled = !!item.ccr_file_name;
      } else {
        item.disabled = false;
      }
    });
  },

  setStartParams(value) {
    if (value) {
      this.startParamsInternalId = value.internal_id;
      this.lastExperimentId = value.last_experiment_id;
      this.manualIdEnabled = value.manual_id_enabled;
      this.startWithEndEnabled = value.start_with_end_enabled;
      this.startWithEndPreciseDateEnabled =
        value.start_with_end_precise_date_enabled;
      this.startWithEndDeltaSec = (value.start_with_end_delta_sec || 0) / 1000;
      this.startWithEndPreciseDate = value.start_with_end_precise_date;
    }
    if (!this.startParamsInternalId) {
      this.startParamsInternalId = uuid();
    }
  },

  setAddDeviceItem(value) {
    console.log("setAddDeviceItem", value);
    this.addDeviceItem = value;
    if (value.param_data) {
      this.addDeviceItem.Name = value.param_data.name;
      this.addDeviceItem.Model = value.param_data.model;
      this.addDeviceItem.Interface = value.param_data.interface;
      this.addDeviceItem.AutoPrint = value.param_data.auto_print;
      this.addDeviceItem.AutoPrintDeltaMs =
        value.param_data.auto_print_delta_ms || 100;
      this.addDeviceItem.AutoPrintDeltaSec =
        this.addDeviceItem.AutoPrintDeltaMs / 1000;
      this.addDeviceItem.AutoTareOnPrint = value.param_data.auto_tare_on_print;
      this.addDeviceItem.AutoTareOnStart = value.param_data.auto_tare_on_start;
      this.addDeviceItem.OnlyStable = value.param_data.only_stable;
      this.addDeviceItem.ShortcutPrint = value.param_data.shortcut_print;
      this.addDeviceItem.ShortcutTare = value.param_data.shortcut_tare;
      this.addDeviceItem.outputKeyBoardEnabled =
        value.param_data.output_key_board_enabled;
      this.addDeviceItem.outputKeyBoardEnabledLast =
        value.param_data.output_key_board_enabled_last;
      this.addDeviceItem.outputScreenEnabled =
        value.param_data.output_screen_enabled;
      this.addDeviceItem.outputScreenName = value.param_data.output_screen_name;
      this.addDeviceItem.keyboardData = value.param_data.keyboard_data;
      this.addDeviceItem.InterfaceRS232Com = value.param_data.port;
      this.addDeviceItem.InterfaceWebServiceAddress =
        value.param_data.tcp_address;
      this.addDeviceItem.InterfaceWebServicePort = value.param_data.tcp_port;
      this.addDeviceItem.InterfaceWebServiceComparator =
        value.param_data.comparator;
      this.addDeviceItem.InterfaceWebServiceProtocol =
        value.param_data.protocol;
      this.addDeviceItem.InterfaceWebServiceProtocolLabel =
        this.InterfaceWebServiceProtocolOptions[value.param_data.protocol] ||
        "";
      this.addDeviceItem.InterfaceRS232Rate = value.param_data.baudrate;
      this.addDeviceItem.InterfaceRS232Bits = value.param_data.bytesize;
      this.addDeviceItem.InterfaceRS232Crc = value.param_data.parity;
      this.addDeviceItem.InterfaceRS232Stop = value.param_data.stopbits;
      this.addDeviceItem.InterfaceRS232Control = value.param_data.xonxoff;
    }
    if (!this.addDeviceItem.AutoPrintDeltaMs) {
      this.addDeviceItem.AutoPrintDeltaMs = 100;
      this.addDeviceItem.AutoPrintDeltaSec =
        this.addDeviceItem.AutoPrintDeltaMs / 1000;
    }
    if (this.addDeviceItem.keyboardData) {
      this.addDeviceItem.keyboardData.sort((a, b) => {
        return a.index - b.index;
      });
    }
    if (!this.addDeviceItem.Interface) {
      this.addDeviceItem.Interface = this.addDeviceItemInterfaceOptionsRS232;
    }
    this.addDeviceItem.StartTime = formatDateFromNs(value.start_time);
    this.addDeviceItem.EndTime = formatDateFromNs(value.end_time);
    this.addDeviceItem.DeltaTime = formatTimeFromNs(value.time_delta);
    if (value.time_delta) {
      this.addDeviceItem.DeltaTimeMilli =
        parseInt(value.time_delta / milliUnit) % 1000;
    }
    this.addDeviceItem.DeltaTimeInstant = value.instant;
    this.addDeviceItem.AutoTare = value.auto_tare;
    if (!this.addDeviceItem.DeltaTime) {
      this.addDeviceItem.DeltaTime = "00:00:00";
    }
    if (!this.addDeviceItem.keyboardData) {
      this.addDeviceItem.keyboardData = [
        {
          data: "",
          cmd: "",
          index: 1,
        },
      ];
    } else {
      Object.keys(this.addDeviceItem.keyboardData).forEach((key) => {
        const item = this.addDeviceItem.keyboardData[key];
        item.cmd = "";
      });
    }
    this.required_color = "#303133";
    var com = this.addDeviceItem.InterfaceRS232Com;
    var comParams = null;
    if (com) {
      comParams = this.addDeviceItemInterfaceRS232ComOptionsParams[com];
    }
    if (comParams) {
      this.addDeviceItem.InterfaceRS232ComSerialTips = comParams;
    } else {
      this.addDeviceItem.InterfaceRS232ComSerialTips = "";
    }
    console.log("addDeviceItem", this.addDeviceItem);
  },

  setServices(value) {
    this.services = value;
  },

  setDbDataList(value) {
    this.dbDataList = value;
  },

  setDbDataDeviceList() {
    const dbDataDeviceList = [];
    const dbDataDeviceListIds = [];
    Object.keys(this.dbDataList).forEach((key) => {
      const item = this.dbDataList[key];
      Object.keys(this.dbDataDeviceNames || []).forEach((deviceKey) => {
        const device_name = this.dbDataDeviceNames[deviceKey];
        const deviceItem =
          this.groupByData[item.internal_id + "_" + device_name] || {};
        const addItem = { ...item, ...deviceItem };
        if (!dbDataDeviceListIds.includes(addItem.internal_id)) {
          dbDataDeviceListIds.push(addItem.internal_id);
          dbDataDeviceList.push(addItem);
        }
      });
    });
    this.dbDataDeviceList = dbDataDeviceList;
    console.log("dbDataDeviceList", dbDataDeviceList);
  },

  setDbDataDeviceNames(value) {
    console.log("setDbDataDeviceNames", value);
    this.dbDataDeviceNames = value;
  },

  setDbDataKey() {
    this.dbDataKey = Math.random();
  },

  setGroupByData(value) {
    console.log("setGroupByData", value);
    this.groupByData = value;
  },

  deleteServices(value) {
    const deleteData = [];
    const rawDeviceNames = this.rawDeviceNames;
    Object.values(this.services).forEach((key) => {
      Object.values(value).forEach((deleteItem) => {
        if (deleteItem.internal_id == key.internal_id) {
          deleteData.push(key);
          Object.keys(rawDeviceNames).forEach((deleteKey) => {
            var deleteNameItem = rawDeviceNames[deleteKey];
            if (deleteNameItem.name == key.name) {
              deleteNameItem.device_deleted = true;
            }
          });
        }
      });
    });
    Object.values(deleteData).forEach((key) => {
      const index = this.services.indexOf(key);
      if (index >= 0) {
        this.services.splice(index, 1);
      }
    });
    Object.keys(this.services).forEach((key) => {
      if (key < this.services.length) {
        const item = this.services[key];
        item.selected = false;
      }
    });
    this.updateSelect();
    this.setRawDeviceNames(rawDeviceNames);
  },

  clearSelect() {
    Object.keys(this.services).forEach((key) => {
      if (key < this.services.length) {
        const item = this.services[key];
        item.selected = false;
      }
    });
    this.updateSelect();
  },

  toggleAllSelect() {
    const selectedData = [];
    Object.keys(this.services).forEach((key) => {
      if (key < this.services.length) {
        const item = this.services[key];
        if (item.selected) {
          selectedData.push(item);
        }
      }
    });
    const newSelect = selectedData.length == 0;
    Object.keys(this.services).forEach((key) => {
      if (key < this.services.length) {
        const item = this.services[key];
        item.selected = newSelect;
      }
    });
    this.updateSelect();
  },

  updateSelect(singleItem = null) {
    if (singleItem && singleItem.selected) {
      Object.keys(this.services).forEach((key) => {
        if (key < this.services.length) {
          const item = this.services[key];
          if (item.internal_id != singleItem.internal_id) {
            item.selected = false;
          }
        }
      });
    }
    this.experimentIdDisabled = false;
    this.autoStopDisabled = false;
    this.ccrDeviceEnabled = false;
    this.tianpingDeviceEnabled = false;
    Object.keys(this.services).forEach((key) => {
      if (key < this.services.length) {
        const item = this.services[key];
        if (item.selected) {
          if (
            item.param_data &&
            InterfaceWebServiceProtocolOptionsProtocolValidCCRs.includes(
              item.param_data.protocol
            )
          ) {
            this.ccrDeviceEnabled = true;
            this.tianpingDeviceEnabled = false;
          } else {
            this.ccrDeviceEnabled = false;
            this.tianpingDeviceEnabled = true;
          }
        }
      }
    });
    if (this.ccrDeviceEnabled) {
      this.experimentIdDisabled = true;
      this.autoStopDisabled = true;
    }
    const selectedData = [];
    Object.keys(this.services).forEach((key) => {
      if (key < this.services.length) {
        const item = this.services[key];
        if (this.ccrDeviceEnabled) {
          if (
            item.param_data &&
            InterfaceWebServiceProtocolOptionsProtocolValidCCRs.includes(
              item.param_data.protocol
            )
          ) {
            item.disabled = false;
          } else {
            item.selected = false;
            item.disabled = true;
          }
        }
        if (this.tianpingDeviceEnabled) {
          if (
            item.param_data &&
            InterfaceWebServiceProtocolOptionsProtocolValidCCRs.includes(
              item.param_data.protocol
            )
          ) {
            item.selected = false;
            item.disabled = true;
          } else {
            item.disabled = false;
          }
        }
        if (item.selected) {
          selectedData.push(item);
        }
      }
    });
    this.enableDelete = selectedData.length > 0;
    this.enableModify = selectedData.length == 1;
    this.enableCopy = selectedData.length == 1;
    this.enableStart = selectedData.length > 0;
    this.enableStop = selectedData.length > 0;
    this.selectedData = selectedData;
    console.log("updateSelect", this);
  },
};
export default reactive(deviceData);
