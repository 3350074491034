<template>
  <el-drawer
    v-model="drawer"
    title="动态更新图表"
    direction="ttb"
    size="90%"
    @closed="handleClosed"
  >
    <div class="bottom_container" id="myLatestDrawerChartContainer">
      <div class="row">
        <el-button
          class="divider-btn"
          @click="restoreDimensions"
          v-show="this.timeDimensions"
          >恢复至原始窗口</el-button
        >
      </div>
      <div class="latest-drawer-chart-container" v-loading="loadingDbGraphData">
        <div
          id="myLatestDrawerChart"
          class="latest-drawer-chart-container-canvas"
        ></div>
        <div
          id="legendLatestDrawer"
          class="latest-drawer-chart-container-legend"
        ></div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { getDeviceGraph } from "../api/device";
import { inject, toRefs, ref } from "vue";
import Dygraph from "dygraphs";

export default {
  name: "MyDatabaseLatestGraphDrawer",
  props: {},
  components: {},
  methods: {
    showDrawer() {
      this.drawer = true;
      this.initDbData(true);
      this.createTimer();
    },
    handleClosed() {
      this.drawer = false;
      this.clearChart();
      this.destroyTimer();
    },
    restoreDimensions() {
      this.initDbData(true);
    },
    createTimer() {
      this.initDbData();
      if (!this.myTimer) {
        this.myTimer = setInterval(() => {
          this.initDbData();
        }, 1 * 1000);
      }
    },
    destroyTimer() {
      if (this.myTimer) {
        clearInterval(this.myTimer);
        this.myTimer = null;
      }
    },
    initDbData(reset = false) {
      if (!this.drawer) {
        console.log("getDeviceLatestGraph is not shown");
        return;
      }
      if (this.loadingData) {
        console.log("getDeviceLatestGraph is loading");
        return;
      }
      console.log("getDeviceLatestGraph initDbData", reset);
      const graphFlag = Date.now();
      this.graphFlag = graphFlag;
      if (reset) {
        this.timeDimensions = null;
      }
      this.loadingData = true;
      getDeviceGraph(
        {
          deviceNameSelect: this.deviceNameSelect,
          runIdSelect: this.runIdSelect,
          timeDimensions: this.timeDimensions,
        },
        (data) => {
          this.loadingData = false;
          console.log("getDeviceLatestGraph data for graph", data);
          if (data && data.data && data.data.data && data.data.code > 0) {
            this.myChartData = data.data.data.list || [];
            Object.values(this.myChartData).forEach((value) => {
              value[0] = new Date(value[0]);
            });
            this.myChartDataValueRange = [
              data.data.data.min || 0,
              data.data.data.max || 2,
            ];
            const valueDelta =
              (this.myChartDataValueRange[1] - this.myChartDataValueRange[0]) /
              10;
            this.myChartDataValueRange[0] =
              this.myChartDataValueRange[0] - valueDelta;
            this.myChartDataValueRange[1] =
              this.myChartDataValueRange[1] + valueDelta;
            this.myChartDataDateWindow = [
              data.data.data.startTime || 0,
              data.data.data.endTime || 0,
            ];
            const dateDelta =
              (this.myChartDataDateWindow[1] - this.myChartDataDateWindow[0]) /
              10;
            this.myChartDataDateWindow[0] =
              this.myChartDataDateWindow[0] - dateDelta;
            this.myChartDataDateWindow[1] =
              this.myChartDataDateWindow[1] + dateDelta;
            const dbDataDeviceNameExperiments = data.data.data.labels || [];
            if (dbDataDeviceNameExperiments.length === 0) {
              dbDataDeviceNameExperiments.push("设备");
            }
            this.myChartDataLabels = ["时间", ...dbDataDeviceNameExperiments];
            this.reloadGraph(graphFlag);
          }
        },
        (err) => {
          this.loadingData = false;
          console.log("getDeviceLatestGraph err for graph", err);
        }
      );
    },
    reloadGraph(graphFlag) {
      if (this.graphFlag !== graphFlag) {
        console.log(
          "getDeviceLatestGraph no need to reload Grapth",
          graphFlag,
          this.graphFlag,
          this.graphFlag - graphFlag
        );
        return;
      }
      this.initChart();
      const options = {
        file: this.myChartData,
        labels: this.myChartDataLabels,
        valueRange: this.myChartDataValueRange,
        dateWindow: this.myChartDataDateWindow,
      };
      console.log("getDeviceLatestGraph reloadGraph options", options);
      if (this.myChart) {
        this.myChart.updateOptions(options);
      }
      this.resize();
    },
    resize() {
      this.$nextTick(() => {
        if (this.myChart) {
          const resizeSt = Date.now();
          const graph = document.getElementById("myLatestDrawerChartContainer");
          console.log(
            "getDeviceLatestGraph graph",
            graph,
            graph.offsetWidth,
            graph.offsetHeight
          );
          this.myChart.resize(400, 400);
          this.myChart.resize(graph.offsetWidth - 200, 400);
          console.log(
            "getDeviceLatestGraph resize time cost",
            Date.now() - resizeSt
          );
        }
      });
    },
    updateDimensions(minDate, maxDate, yRanges) {
      console.log(
        "getDeviceLatestGraph updateDimensions",
        minDate,
        maxDate,
        yRanges
      );
      if (this.timeDimensions != [minDate, maxDate]) {
        this.timeDimensions = [minDate, maxDate];
        this.initDbData();
      }
    },
    clearChart() {
      this.myChartData = ref([]);
      this.myChartDataValueRange = [0, 2];
      this.myChartDataDateWindow = [0, 0];
      this.myChartDataLabels = ["时间", "设备"];
      if (this.myChart) {
        this.myChart.destroy();
        this.myChart = null;
      }
      this.destroyTimer();
    },
    initChart() {
      if (this.myChart) {
        return;
      }
      let el_drawer_ttb = document.getElementById(
        "myLatestDrawerChartContainer"
      );
      if (el_drawer_ttb.parentNode) {
        el_drawer_ttb = el_drawer_ttb.parentNode;
        if (el_drawer_ttb.parentNode) {
          el_drawer_ttb = el_drawer_ttb.parentNode;
          if (el_drawer_ttb.style) {
            el_drawer_ttb.style.top = "5%";
            el_drawer_ttb.style.left = "5%";
            el_drawer_ttb.style.width = "90%";
            el_drawer_ttb.style.height = "90%";
            console.log('myLatestDrawerChartContainer update style');
          }
        }
      }
      const graph = document.getElementById("myLatestDrawerChart");
      this.myChart = new Dygraph(graph, this.myChartData, {
        drawPoints: true,
        highlightSeriesOpts: { strokeWidth: 2 },
        labelsKMB: true,
        connectSeparatedPoints: true,
        legend: "always",
        animatedZooms: true,
        valueRange: this.myChartDataValueRange,
        labels: this.myChartDataLabels,
        labelsDiv: document.getElementById("legendLatestDrawer"),
        labelsSeparateLines: true,
        zoomCallback: this.updateDimensions,
        dateWindow: this.myChartDataDateWindow,
      });
      this.resize();
    },
  },
  mounted() {
    this.initDbData();
  },
  unmounted() {
    this.clearChart();
  },
  setup() {
    const deviceData = inject("deviceData");
    const menuData = inject("menuData");
    const myChart = ref(null);
    const myChartData = ref([]);
    const myChartDataValueRange = ref([0, 2]);
    const myChartDataDateWindow = ref([0, 0]);
    const myChartDataLabels = ref(["时间", "设备"]);
    const graphFlag = ref(Date.now());
    const loadingData = ref(false);
    const timeDimensions = ref(null);
    const drawer = ref(false);
    const myTimer = ref(null);

    const data = {
      ...toRefs(deviceData),
      ...toRefs(menuData),
      myChart,
      myChartData,
      myChartDataValueRange,
      myChartDataDateWindow,
      myChartDataLabels,
      graphFlag,
      loadingData,
      timeDimensions,
      drawer,
      myTimer,
    };
    return data;
  },
};
</script>

<style scoped>
.bottom_container {
  min-height: 400px;
  margin-top: 60px;
}
.latest-drawer-chart-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 20px;
}

.latest-drawer-chart-container-canvas {
  width: 400px;
  height: 400px;
  display: inline-block;
  vertical-align: top;
}

.latest-drawer-chart-container-legend {
  display: inline-block;
  vertical-align: top;
}

.divider-btn {
  height: 36px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
:deep(.el-drawer.ttb) {
  top: 5% !important;
  left: 5% !important;
  width: 90% !important;
  height: 90% !important;
}
</style>
