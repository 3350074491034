<template>
  <div class="row-max divider-middle">
    <div :class="idIsEmpty ? 'title error-tips' : 'title'">实验名称</div>
    <el-select
      class="title-select"
      filterable
      v-model="runIdSelect"
      placeholder="尚未选择任何实验名称，请选择！"
      multiple
      @change="_runIdSelectChanged"
      v-loading.fullscreen.lock="runIdLoading"
      element-loading-text="数据加载中，请稍后..."
    >
      <el-option
        v-for="item in runValidIds"
        :key="item.experiment_id"
        :label="item.experiment_id"
        :value="item.experiment_id"
        :disabled="item.disabled"
      >
      </el-option>
    </el-select>
  </div>

  <div class="row-max row-top-divider">
    <div class="title">设备名称</div>
    <el-drag-select
      class="title-select"
      filterable
      v-model="deviceNameSelect"
      placeholder="已选择全部设备名称，请重新选择！"
      multiple
      :change="_deviceNameSelectChanged"
    >
      <el-option
        v-for="item in deviceNames"
        :key="item"
        :label="item"
        :value="item"
      >
      </el-option>
    </el-drag-select>
  </div>

  <div class="row-max">
    <el-button
      class="divider-btn"
      @click="_save"
      @mouseover="saveEdit = true"
      @mouseleave="saveEdit = false"
    >
      <div class="divider-btn-row">
        <div class="divider-btn-left"></div>
        <div class="divider-btn-label">
          生成报告
          <el-tooltip
            v-if="dbTypeSelectOnlyCcr"
            placement="top"
            :offset="20"
            raw-content
            :content="`报告模版说明：<br />模板文件位于 C:\\WizConnect\\template 文件夹，均为 xlsx 格式<br /><br />模版写法如下：<br />1. {变量}$ 表示单元格被变量名称对应的值替换<br />2. {变量}+ 表示单元格被变量名称对应的值替换，并且有多个值，会自动保留相同格式增加多行填入对应的值<br />3. {变量}&ltright&gt&ltdown&gt 表示单元格被变量名称对应的值替换，存在多个值，会向右再向下移动一个单元格然后插入新的值，right和down可以出现多个<br />4. 所有可选变量名参见 输出变量表-变量名称 列。如果变量是多个值，模板会用合并单元格表示<br /><br />注：超过1万条数据，将会被保存为csv文件，丢失excel格式等信息。`"
          >
            <el-icon class="desc-icon">
              <QuestionFilled />
            </el-icon>
          </el-tooltip>
        </div>
        <div class="divider-btn-right">
          <input
            id="m_in"
            ref="m_in"
            class="m_in"
            type="file"
            @change="saveEditFileChange"
            accept=".xlsx"
          />
          <el-icon
            class="more-icon"
            v-if="saveEdit && dbTypeSelectOnlyCcr"
            @click.stop="_saveEdit"
          >
            <More />
          </el-icon>
        </div>
      </div>
    </el-button>
    <el-button
      class="divider-btn"
      @click="_sendDataToClipboard"
      :disabled="!dbTypeSelectOnlyCcr"
      @mouseover="sendDataToClipboardEdit = true"
      @mouseleave="sendDataToClipboardEdit = false"
    >
      <div class="divider-btn-row">
        <div class="divider-btn-left"></div>
        <div class="divider-btn-label">发送至剪贴板</div>
        <div class="divider-btn-right">
          <el-icon
            class="more-icon"
            v-if="sendDataToClipboardEdit"
            @click.stop="_sendDataToClipboardEdit"
            :disabled="!dbTypeSelectOnlyCcr"
          >
            <More />
          </el-icon>
        </div>
      </div>
    </el-button>
    <el-button class="divider-btn" @click="_saveTag">
      <div class="divider-btn-row">
        <div class="divider-btn-left"></div>
        <div class="divider-btn-label">
          保存为筛选标签
          <el-tooltip
            placement="top"
            :offset="20"
            raw-content
            :content="`将当前查询条件保存为标签<br />标签名将出现在数据库菜单的子菜单列表中`"
          >
            <el-icon class="desc-icon">
              <QuestionFilled />
            </el-icon>
          </el-tooltip>
        </div>
        <div class="divider-btn-right"></div>
      </div>
    </el-button>
    <el-button
      class="divider-btn"
      @click="showStandardDeviceInfo"
      :disabled="!dbTypeSelectOnlyCcr"
    >
      <div class="divider-btn-row">
        <div class="divider-btn-left"></div>
        <div class="divider-btn-label">砝码设备信息</div>
        <div class="divider-btn-right"></div>
      </div>
    </el-button>
    <el-button
      class="divider-btn"
      @click="deleteAllSelectedValue"
      v-if="databaseOptimize == 'true'"
    >
      <div class="divider-btn-row">
        <div class="divider-btn-left"></div>
        <div class="divider-btn-label">
          {{ dbTypeSelectOnlyCcr ? "删除选中的数据" : "删除图表中的所有数据" }}
        </div>
        <div class="divider-btn-right"></div>
      </div>
    </el-button>
  </div>

  <div class="row-max error-tips-margin" v-if="errorTips">
    <div class="error-tips">{{ errorTips }}</div>
  </div>

  <el-table
    v-if="!dbTypeSelectOnlyCcr"
    class="my-table"
    :data="dbDataList"
    :border="true"
    :resizable="true"
    v-loading="loadingDbData"
    :row-class-name="tableRowClassName"
    :key="dbDataKey"
    empty-text="没有选中任何数据"
  >
    <el-table-column
      prop="experiment_internal_id"
      label="实验名称"
      sort-by="'experiment_internal_id'"
      align="center"
      min-width="200"
    />
    <el-table-column
      prop="date"
      label="日期 & 时间"
      sort-by="'date'"
      align="center"
      min-width="200"
    />

    <el-table-column
      v-for="item in dbDataDeviceNames"
      :key="item"
      :label="`${item}`"
      align="center"
      min-width="200"
    >
      <template #default="scope">
        <div class="table_cell_row">
          <div class="table_cell_left">
            {{ (groupByData[scope.row.internal_id + "_" + item] || {}).data }}
          </div>
          <div class="table_cell_divider">{{ ` ` }}</div>
          <div class="table_cell_right">
            {{ (groupByData[scope.row.internal_id + "_" + item] || {}).unit }}
          </div>
        </div>
      </template>
    </el-table-column>
  </el-table>
  <div class="multi_device_show">
    <el-checkbox
      v-if="dbTypeSelectOnlyCcr"
      v-model="multiDeviceShow"
      @change="multiDeviceShowChanged"
      >多设备并列显示</el-checkbox
    >
  </div>
  <el-table
    v-if="dbTypeSelectOnlyCcr && !multiDeviceShow"
    class="my-table"
    :data="dbDataList"
    :border="true"
    :resizable="true"
    v-loading="loadingDbData"
    :row-class-name="tableRowClassName"
    :key="dbDataKey"
    @row-click="rowClick"
    @header-click="headerClick"
    empty-text="没有选中任何数据"
  >
    <el-table-column
      prop="selected"
      :label="`已选(${
        selectedDataListIdAll ? '全部页' : selectedDataListIds.length
      })`"
      width="120px"
      header-align="center"
    >
      <template #header>
        <el-space>
          <div>
            {{
              `已选(${
                selectedDataListIdAll ? "全部页" : selectedDataListIds.length
              })`
            }}
          </div>
          <el-tooltip
            class="box-item"
            effect="dark"
            :content="`已选说明：<br /><br />1.点击已选文字可以 “全选” 或者 “取消全选”<br />2.括号中展示了已选中数量<br />3.点击下面选择框可以多选<br />4.点击下表格中非选择框部分会单选中该行<br />5.选择框灰色表示改行不能被选中`"
            placement="top-start"
            :offset="20"
            raw-content
          >
            <el-icon><QuestionFilled /></el-icon>
          </el-tooltip>
        </el-space>
      </template>
      <template #default="scope">
        <div class="row-center">
          <el-checkbox
            v-model="scope.row.selected"
            label=" "
            @change="updateSelectDataListIds(scope.row)"
          >
          </el-checkbox>
        </div>
      </template>
    </el-table-column>
    <el-table-column
      prop="ccr_file_name"
      label="实验名称"
      sort-by="'ccr_file_name'"
      align="center"
      min-width="300"
    />
    <el-table-column
      prop="ccr_date"
      label="日期 & 时间"
      sort-by="'ccr_date'"
      align="center"
      min-width="200"
    />

    <el-table-column
      v-for="item in dbDataDeviceNames"
      :key="item"
      :label="`${item}`"
      align="center"
      min-width="200"
    >
      <el-table-column
        prop="nominalA"
        label="标称值 [g]"
        align="center"
        min-width="200"
      >
        <template #default="scope">
          {{ (groupByData[scope.row.internal_id + "_" + item] || {}).nominalA }}
        </template>
      </el-table-column>
      <el-table-column
        prop="positionA"
        label="位置 A"
        align="center"
        min-width="200"
      >
        <template #default="scope">
          {{
            (groupByData[scope.row.internal_id + "_" + item] || {}).positionA
          }}
        </template>
      </el-table-column>
      <el-table-column
        prop="positionB"
        label="位置 B"
        align="center"
        min-width="200"
      >
        <template #default="scope">
          {{
            (groupByData[scope.row.internal_id + "_" + item] || {}).positionB
          }}
        </template>
      </el-table-column>
      <el-table-column
        prop="delta_mass_mg"
        label="约定质量平均差值 [mg]"
        align="center"
        min-width="200"
      >
        <template #default="scope">
          {{
            (groupByData[scope.row.internal_id + "_" + item] || {})
              .delta_mass_mg
          }}
        </template>
      </el-table-column>
    </el-table-column>
  </el-table>
  <el-table
    v-if="dbTypeSelectOnlyCcr && multiDeviceShow"
    class="my-table"
    :data="dbDataDeviceList"
    :border="true"
    :resizable="true"
    v-loading="loadingDbData"
    :row-class-name="tableRowClassName"
    :key="dbDataKey"
    @row-click="rowClick"
    @header-click="headerClick"
    empty-text="没有选中任何数据"
  >
    <el-table-column
      prop="selected"
      :label="`已选(${
        selectedDataDeviceListIdAll
          ? '全部页'
          : selectedDataDeviceListIds.length
      })`"
      width="120px"
      header-align="center"
    >
      <template #header>
        <el-space>
          <div>
            {{
              `已选(${
                selectedDataDeviceListIdAll
                  ? "全部页"
                  : selectedDataDeviceListIds.length
              })`
            }}
          </div>
          <el-tooltip
            class="box-item"
            effect="dark"
            :content="`已选说明：<br /><br />1.点击已选文字可以 “全选” 或者 “取消全选”<br />2.括号中展示了已选中数量<br />3.点击下面选择框可以多选<br />4.点击下表格中非选择框部分会单选中该行<br />5.选择框灰色表示改行不能被选中`"
            placement="top-start"
            :offset="20"
            raw-content
          >
            <el-icon><QuestionFilled /></el-icon>
          </el-tooltip>
        </el-space>
      </template>
      <template #default="scope">
        <div class="row-center">
          <el-checkbox
            v-model="scope.row.selected"
            label=" "
            @change="updateSelectDataDeviceListIds(scope.row)"
          >
          </el-checkbox>
        </div>
      </template>
    </el-table-column>
    <el-table-column
      prop="ccr_file_name"
      label="实验名称"
      sort-by="'ccr_file_name'"
      align="center"
      min-width="300"
    />
    <el-table-column
      prop="device_name"
      label="设备名称"
      sort-by="'device_name'"
      align="center"
      min-width="200"
    />
    <el-table-column
      prop="ccr_date"
      label="日期 & 时间"
      sort-by="'ccr_date'"
      align="center"
      min-width="200"
    />
    <el-table-column
      prop="nominalA"
      label="标称值 [g]"
      align="center"
      min-width="200"
    >
    </el-table-column>
    <el-table-column
      prop="positionA"
      label="位置 A"
      align="center"
      min-width="200"
    />
    <el-table-column
      prop="positionB"
      label="位置 B"
      align="center"
      min-width="200"
    />
    <el-table-column
      prop="delta_mass_mg"
      label="约定质量平均差值 [mg]"
      align="center"
      min-width="200"
    >
    </el-table-column>
  </el-table>
  <el-pagination
    class="my-table"
    v-model:currentPage="dbDataListCurrentPage"
    v-model:page-size="dbDataListPageSize"
    :page-sizes="[10, 20, 50, 100, 200, 300, 400, 500, 1000]"
    :small="false"
    :disabled="false"
    :background="true"
    layout="total, sizes, prev, pager, next, jumper"
    :total="dbDataListTotal"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  />

  <el-tabs
    v-if="dbTypeSelectOnlyCcr"
    class="tabs"
    type="card"
    v-model="activeTabName"
    @tab-change="activeTabNameChanged"
  >
    <el-tab-pane label="动态图表" name="dynamic_graph"></el-tab-pane>
    <el-tab-pane label="计算结果" name="calculate_result"></el-tab-pane>
    <el-tab-pane label="不确定度计算" name="uncertain_calculate"></el-tab-pane>
    <el-tab-pane label="称量过程数据" name="measure_data"></el-tab-pane>
    <el-tab-pane label="输出变量表" name="output_variable_table"></el-tab-pane>
  </el-tabs>

  <div
    v-show="!dbTypeSelectOnlyCcr || activeTabName == 'dynamic_graph'"
    class="bottom_container"
  >
    <MyDatabaseGraph ref="MyDatabaseGraph" />
  </div>

  <div
    v-if="dbTypeSelectOnlyCcr && activeTabName == 'calculate_result'"
    class="bottom_container"
    element-loading-text="正在更新数据，请稍后..."
    v-loading="calculateResultUpdateLoading"
  >
    <el-button
      class="calculate_result_btn"
      @click="calculateResultUpdate"
      :disabled="!calculateResultNeedUpdate"
      :color="calculateResultNeedUpdate ? '#F56C6C' : ''"
      >{{ `批量更新选中的${this.activeDataItemSelected.length || 0}条数据` }}
      <el-tooltip
        placement="top"
        :offset="20"
        raw-content
        :content="`批量更新选中的数据，所选中数据都会被表格中红色高亮数值更新替换。`"
      >
        <el-icon class="desc-icon">
          <QuestionFilled />
        </el-icon>
      </el-tooltip>
    </el-button>
    <el-table
      class="calculate_result_selected"
      :data="calculateResultSelected || []"
      border
      empty-text="没有选中任何数据"
    >
      <el-table-column prop="name" label="参数名称" min-width="120">
        <template #default="scope">
          <el-space>
            <div>{{ scope.row.name }}</div>
            <el-tooltip
              v-if="scope.row.tooltips"
              class="box-item"
              effect="dark"
              placement="top-start"
              :offset="20"
              raw-content
              :content="scope.row.tooltips"
            >
              <el-icon><QuestionFilled /></el-icon>
            </el-tooltip>
          </el-space>
        </template>
      </el-table-column>
      <el-table-column prop="symbol" label="符号">
        <template #default="scope"
          ><vue-latex
            class="latex"
            :expression="scope.row.symbol || ''"
            display-mode
          />
        </template>
      </el-table-column>
      <el-table-column prop="value" label="值">
        <template #default="scope">
          <div v-if="scope.row.edit">
            <el-input
              v-model="scope.row.valueInput"
              placeholder=""
              :class="scope.row.valueChanged ? 'color_changed' : ''"
              @change="calculateResultInputChanged"
              @input="calculateResultInputNewValue(scope.row)"
            />
          </div>
          <div v-else>{{ scope.row.value || "NA" }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="unit" label="单位" />
    </el-table>
  </div>

  <div
    v-if="dbTypeSelectOnlyCcr && activeTabName == 'uncertain_calculate'"
    class="bottom_container"
  >
    <el-table
      class="uncertain_calculate_selected"
      :data="uncertainCalculateSelected || []"
      row-key="key"
      @row-click="expandRowClick"
      ref="ExpandedTable"
      border
      empty-text="没有选中任何数据"
    >
      <el-table-column prop="name" label="参数名称" min-width="120" />
      <el-table-column prop="symbol" label="符号">
        <template #default="scope">
          <vue-latex :expression="scope.row.symbol || ''" display-mode />
        </template>
      </el-table-column>
      <el-table-column prop="value" label="值">
        <template #default="scope">
          <div>{{ scope.row.value || "NA" }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="unit" label="单位" />
    </el-table>
  </div>

  <div
    v-if="dbTypeSelectOnlyCcr && activeTabName == 'measure_data'"
    class="bottom_container"
  >
    <el-table
      class="measure_data_selected"
      :data="measureDataSelected.list || []"
      :span-method="measureDataSelectedSpanMethod"
      border
      empty-text="没有选中任何数据"
    >
      <el-table-column prop="cycle" label="Cycle" width="80">
        <template #default="scope">
          <div>{{ parseInt(scope.row.cycle) + 1 }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="seq" label="Seq" width="80" />
      <el-table-column
        prop="num"
        :label="`Num [${measureDataSelected.unit || 'mg'}]`"
      />
      <el-table-column prop="diff" label="2nd Diff. [mg]" />
      <el-table-column prop="mean" label="Mean [mg]" />
    </el-table>
  </div>

  <div
    v-if="dbTypeSelectOnlyCcr && activeTabName == 'output_variable_table'"
    class="bottom_container"
  >
    <el-table
      class="output_variable_table_selected"
      :data="outputVariableTableSelectedParams || []"
      border
      empty-text="没有任何变量"
    >
      <el-table-column prop="name" label="变量名称" />
      <el-table-column prop="symbol" label="符号">
        <template #default="scope">
          <vue-latex :expression="scope.row.symbol || ''" display-mode />
        </template>
      </el-table-column>
      <el-table-column prop="value" label="变量值">
        <template #default="scope">
          <div>
            {{ outputVariableTableSelectedData[scope.row.key] || "NA" }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="unit" label="单位">
        <template #default="scope">
          <div>
            {{
              scope.row.unit
                ? scope.row.unit === "*"
                  ? outputVariableTableSelectedData.unit || "mg"
                  : scope.row.unit
                : ""
            }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="tips" label="说明" min-width="200" />
    </el-table>
  </div>

  <MyDatabaseShowDebugGraph
    ref="MyDatabaseShowDebugGraph"
    v-show="showDebugTimeData"
  />

  <EditStandardDeviceInfo
    ref="EditStandardDeviceInfo"
    :onUpdateCalculateResults="onUpdateCalculateResults"
  />
  <EditSendToClipboardInfo ref="EditSendToClipboardInfo" />

  <div
    class="templateChoose"
    :style="{ left: templateChooseLeft, top: templateChooseTop }"
    v-if="templateChooseVisible"
  >
    <PinyinSelect
      id="templateChoose"
      :style="{ 'min-width': '200px' }"
      v-model="templateChoose"
      :options="templateChooseList"
      :props="{
        label: 'tips',
        value: 'name',
      }"
      @optionClick="templateChooseClose"
      @cancelSelect="templateChooseClose"
      :placeholder="`${
        templateChooseList.length > 0
          ? '请选择报告模板'
          : '暂无报告模版、请新建模版'
      }`"
      :emptyData="`  模版xlsx文件放在 C:\\WizConnect\\template 文件夹  `"
    />
  </div>
</template>

<script>
import dayjs from "dayjs";
import {
  getDeviceData,
  getRunIds,
  exportDeviceData,
  setConfig,
  setDeviceTag,
  updateCalculateResult,
  uploadDataTemplate,
  getTemplate,
  deleteDataWithIds,
  deleteDataWithCondition,
} from "../api/device";
import { inject, toRefs, ref, computed } from "vue";
import { VueLatex } from "vatex";
import { ElMessage, ElMessageBox, ElLoading } from "element-plus";
import { secUnit, formatDateFromNs } from "../util/date_util";
import {
  outputTableVariableParams,
  copyItem,
  getClipboardInfoObj,
  getExportDataInfo,
  getClipboardCopyText,
  getOutputTableData,
  formatDataPrecision,
  getPrecisionByKey,
} from "../util/clipboard_util";
import { formatCompareData } from "../util/formats_util";
import ElDragSelect from "@/components/DragSelect";
import EditStandardDeviceInfo from "./EditStandardDeviceInfo.vue";
import EditSendToClipboardInfo from "./EditSendToClipboardInfo.vue";
import PinyinSelect from "./PinyinSelect.vue";
import MyDatabaseShowDebugGraph from "./MyDatabaseShowDebugGraph.vue";
import MyDatabaseGraph from "./MyDatabaseGraph.vue";

export default {
  name: "MyDatabase",
  props: {
    msg: String,
  },
  components: {
    ElDragSelect,
    EditStandardDeviceInfo,
    EditSendToClipboardInfo,
    VueLatex,
    PinyinSelect,
    MyDatabaseShowDebugGraph,
    MyDatabaseGraph,
  },
  methods: {
    deleteAllSelectedValue() {
      if (this.dbDataListTotal <= 0) {
        ElMessage.error("没有任何数据可以删除");
        return;
      }
      if (this.dbTypeSelectOnlyCcr) {
        let allSelected = false;
        if (this.multiDeviceShow) {
          allSelected = this.selectedDataDeviceListIdAll;
        } else {
          allSelected = this.selectedDataListIdAll;
        }
        if (allSelected) {
          const loading = ElLoading.service({
            lock: true,
            text: "正在删除数据，请稍后...",
            background: "rgba(0, 0, 0, 0.7)",
          });
          deleteDataWithCondition(
            {
              deviceNameSelect: this.deviceNameSelect,
              runIdSelect: this.runIdSelect,
            },
            (data) => {
              loading.close();
              console.log("deleteDataWithCondition data for copy send", data);
              if (data && data.data && data.data.data && data.data.code > 0) {
                this._loadingData(true, false);
                ElMessage.success("数据删除成功！");
              } else {
                ElMessage.error("删除失败1！");
              }
            },
            (err) => {
              loading.close();
              ElMessage.error("删除失败2！");
              console.log("deleteDataWithCondition err for copy send", err);
            }
          );
        } else {
          if (this.activeDataItemSelected.length == 0) {
            ElMessage.error("没有选中任何数据，请先选择要删除的数据");
          } else {
            const internal_ids = [];
            Object.values(this.activeDataItemSelected).forEach((value) => {
              internal_ids.push(value.internal_id);
            });
            const loading = ElLoading.service({
              lock: true,
              text: "正在删除数据，请稍后...",
              background: "rgba(0, 0, 0, 0.7)",
            });
            deleteDataWithIds(
              {
                internalIds: internal_ids,
              },
              (data) => {
                loading.close();
                console.log("deleteDataWithIds data for copy send", data);
                if (data && data.data && data.data.data && data.data.code > 0) {
                  this._loadingData(true, false);
                  ElMessage.success("数据删除成功！");
                } else {
                  ElMessage.error("删除失败1！");
                }
              },
              (err) => {
                loading.close();
                ElMessage.error("删除失败2！");
                console.log("deleteDataWithIds err for copy send", err);
              }
            );
          }
        }
      } else {
        const loading = ElLoading.service({
          lock: true,
          text: "正在删除数据，请稍后...",
          background: "rgba(0, 0, 0, 0.7)",
        });
        deleteDataWithCondition(
          {
            deviceNameSelect: this.deviceNameSelect,
            runIdSelect: this.runIdSelect,
          },
          (data) => {
            loading.close();
            console.log("deleteDataWithCondition data for copy send", data);
            if (data && data.data && data.data.data && data.data.code > 0) {
              this._loadingData(true, false);
              ElMessage.success("数据删除成功！");
            } else {
              ElMessage.error("删除失败1！");
            }
          },
          (err) => {
            loading.close();
            ElMessage.error("删除失败2！");
            console.log("deleteDataWithCondition err for copy send", err);
          }
        );
      }
    },
    onUpdateCalculateResults() {
      this._loadingData(true, false);
    },
    calculateResultUpdate() {
      console.log(
        "calculateResultUpdate",
        this.calculateResultSelected,
        this.activeDataItemSelected
      );
      if (this.activeDataItemSelected.length === 0) {
        return;
      }
      const loadingProgress = {
        size: this.activeDataItemSelected.length,
        index: 0,
        error: 0,
      };
      this.calculateResultUpdateLoading = true;
      Object.keys(this.activeDataItemSelected).forEach((key) => {
        const params = {};
        params.internal_id = this.activeDataItemSelected[key].internal_id;
        Object.keys(this.calculateResultSelected).forEach((key) => {
          if (this.calculateResultSelected[key].edit) {
            if (
              this.calculateResultSelected[key].valueInput !=
              this.calculateResultSelected[key].value
            ) {
              params[this.calculateResultSelected[key].key] =
                this.calculateResultSelected[key].valueInput;
            }
          }
        });
        updateCalculateResult(
          params,
          (data) => {
            loadingProgress.index += 1;
            if (loadingProgress.index == loadingProgress.size) {
              this.calculateResultUpdateLoading = false;
            }
            console.log("updateCalculateResult data", data);
            if (data && data.data && data.data.data && data.data.code > 0) {
              if (loadingProgress.index == loadingProgress.size) {
                this._loadingData(true, false);
                if (loadingProgress.error == 0) {
                  ElMessage.success("已成功更新数据！");
                } else {
                  ElMessage.error("更新数据部分失败！");
                }
              }
            } else {
              loadingProgress.error += 1;
              if (loadingProgress.index == loadingProgress.size) {
                ElMessage.error("更新数据失败！");
              }
            }
          },
          (err) => {
            loadingProgress.index += 1;
            if (loadingProgress.index == loadingProgress.size) {
              this.calculateResultUpdateLoading = false;
            }
            console.log("updateCalculateResult err", err);
            loadingProgress.error += 1;
            if (loadingProgress.index == loadingProgress.size) {
              ElMessage.error("更新数据失败1！");
            }
          }
        );
      });
    },
    calculateResultInputChanged() {
      let calculateResultNeedUpdate = false;
      Object.keys(this.calculateResultSelected).forEach((key) => {
        if (this.calculateResultSelected[key].edit) {
          if (
            formatCompareData(this.calculateResultSelected[key].value) !=
            formatCompareData(this.calculateResultSelected[key].valueInput)
          ) {
            calculateResultNeedUpdate = true;
          }
        }
      });
      this.calculateResultNeedUpdate = calculateResultNeedUpdate;
      console.log(
        "calculateResultInputChanged",
        this.calculateResultNeedUpdate,
        this.calculateResultSelected
      );
    },
    calculateResultInputNewValue(row) {
      console.log("calculateResultInputNewValue", row);
      row.valueChanged = row.value != row.valueInput;
    },
    measureDataSelectedSpanMethod({ rowIndex, columnIndex }) {
      if (columnIndex === 0 || columnIndex === 3) {
        if (rowIndex % (this.measureDataSelected.size || 1) === 0) {
          return [this.measureDataSelected.size || 1, 1];
        } else {
          return [0, 0];
        }
      }
      if (columnIndex === 4) {
        if (rowIndex === 0) {
          return [(this.measureDataSelected.list || []).length, 1];
        } else {
          return [0, 0];
        }
      }
    },
    multiDeviceShowChanged() {
      this.updateItemSelected();
    },
    activeTabNameChanged() {
      console.log("activeTabNameChanged", this.activeTabName);
      this.updateItemSelected();
    },
    updateItemSelected() {
      this.activeDataItemSelected = [];
      if (this.multiDeviceShow) {
        Object.keys(this.dbDataDeviceList).forEach((key) => {
          const item = this.dbDataDeviceList[key];
          if (item.selected && item.ccr_raw_info) {
            this.activeDataItemSelected.push(item);
          }
        });
      } else {
        Object.keys(this.dbDataList).forEach((key) => {
          const item = this.dbDataList[key];
          if (item.selected) {
            Object.keys(this.dbDataDeviceNames).forEach((dKey) => {
              const device_name = this.dbDataDeviceNames[dKey];
              const dItem =
                this.groupByData[item.internal_id + "_" + device_name];
              if (dItem && dItem.ccr_raw_info) {
                this.activeDataItemSelected.push(dItem);
              }
            });
          }
        });
      }
      if (this.activeTabName === "measure_data") {
        let measureDataSelected = {};
        if (this.activeDataItemSelected.length > 0) {
          measureDataSelected =
            this.activeDataItemSelected[0].ccr_raw_info.measure_data || {};
        }
        Object.keys(measureDataSelected.list || []).forEach((key) => {
          const item = measureDataSelected.list[key];
          if (item.diff) {
            item.diff = formatDataPrecision(
              item.diff,
              getPrecisionByKey("diff")
            );
          }
          if (item.mean) {
            item.mean = formatDataPrecision(
              item.mean,
              getPrecisionByKey("mean")
            );
          }
        });
        this.measureDataSelected = measureDataSelected;
        console.log("measureDataSelected", measureDataSelected);
      } else if (this.activeTabName === "calculate_result") {
        let calculateResult = {};
        if (this.activeDataItemSelected.length > 0) {
          calculateResult =
            this.activeDataItemSelected[0].ccr_raw_info.calculate_result || {};
        }
        const calculateResultSelected = [
          {
            name: "比较仪名称",
            key: "comparator",
            edit: true,
            valueInput: "",
          },
          {
            name: "名称 A",
            key: "nameA",
            tooltips:
              "该名称是在创建比对任务时输入，应包含标准砝码的型号规格、编号，以及托盘的编号（如果有托盘）。<br />多个信息无前后之分，但应采用下划线“_”连接。<br />名称A所含信息将用于匹配“砝码设备信息页”标准砝码、砝码密度和托盘表格中的相关信息。",
            edit: true,
            valueInput: "",
          },
          {
            name: "名称 B",
            key: "nameB",
            tooltips:
              "该名称是在创建比对任务时输入，应包含被测砝码的型号规格、编号，以及托盘的编号（如果有托盘）。<br />多个信息无前后之分，但应采用下划线“_”连接。<br />名称B所含信息将用于匹配“砝码设备信息页”砝码密度和托盘表格中的相关信息。",
            edit: true,
            valueInput: "",
          },
          {
            name: "循环方法",
            key: "method",
          },
          {
            name: "循环次数",
            key: "cycles",
          },
          {
            name: "托盘B-托盘A重量差值",
            key: "delta_pan_mg",
          },
          {
            name: "称量结果平均差值",
            key: "delta_indication_mg",
          },
          {
            name: "称量结果差值标准偏差",
            key: "sd_delta_indication_mg",
          },
          {
            name: "空气浮力修正值",
            key: "boyancy_corr_mg",
          },
          {
            name: "约定质量平均差值",
            key: "delta_mass_mg",
          },
          {
            name: "标准砝码约定质量值",
            key: "mass_ref_g",
          },
          {
            name: "被测砝码约定质量值",
            key: "mass_test_g",
          },
          {
            name: "被测砝码约定质量修正值",
            key: "mass_corr_test_mg",
          },
          {
            name: "扩展不确定度 (k=2)",
            key: "eu_mass_test_mg",
          },
        ];
        Object.values(calculateResultSelected).forEach((value) => {
          Object.values(outputTableVariableParams).forEach((paramValue) => {
            if (value.key === paramValue.key) {
              Object.assign(value, {
                ...paramValue,
                name: value.name,
              });
            }
          });
        });
        Object.values(calculateResultSelected).forEach((item) => {
          if (item.isNumber && calculateResult[item.key]) {
            item.value =
              formatDataPrecision(calculateResult[item.key], item.precision) ||
              "";
          } else {
            item.value = calculateResult[item.key] || "";
          }
          if (item.edit) {
            item.valueInput = item.value;
          }
        });
        this.calculateResultNeedUpdate = false;
        this.calculateResultSelected = calculateResultSelected;
        console.log("calculateResultSelected", calculateResultSelected);
      } else if (this.activeTabName === "uncertain_calculate") {
        let uncertainCalculate = {};
        if (this.activeDataItemSelected.length > 0) {
          uncertainCalculate =
            this.activeDataItemSelected[0].ccr_raw_info.uncertain_calculate;
        }
        const uncertainCalculateSelected = [
          {
            name: "称量过程A类标准不确定度",
            key: "u_weighing_mg",
          },
          {
            name: "比较仪的重复性",
            key: "repeatability_mg",
          },
          {
            name: "循环次数",
            key: "cycles",
          },
          {
            name: "标准砝码约定质量值的标准不确定度",
            key: "u_mass_ref_mg",
          },
          {
            name: "空气浮力修正的标准不确定度",
            key: "u_boyancy_corr_mg",
          },
          {
            name: "标准砝码约定质量值",
            key: "mass_ref_g",
          },
          {
            name: "标准砝码密度",
            key: "density_ref_kg",
          },
          {
            name: "标准砝码密度的标准不确定度",
            key: "u_density_ref_kg",
          },
          {
            name: "被测砝码密度",
            key: "density_test_kg",
          },
          {
            name: "被测砝码密度的标准不确定度",
            key: "u_density_test_kg",
          },
          {
            name: "空气密度",
            key: "density_air_kg",
          },
          {
            name: "空气密度的标准不确定度",
            key: "u_density_air_kg",
          },
          {
            name: "比较仪的系统性标准不确定度",
            key: "u_comparator_sys_mg",
          },
          {
            name: "托盘重量差值的标准不确定度",
            key: "u_delta_pan_mg",
          },
          {
            name: "合成标准不确定度",
            key: "cu_mass_test_mg",
          },
          {
            name: "扩展不确定度 (k=2)",
            key: "eu_mass_test_mg",
          },
        ];
        Object.values(uncertainCalculateSelected).forEach((value) => {
          Object.values(outputTableVariableParams).forEach((paramValue) => {
            if (value.key === paramValue.key) {
              Object.assign(value, {
                ...paramValue,
                name: value.name,
              });
            }
          });
        });
        Object.values(uncertainCalculateSelected).forEach((item) => {
          if (item.isNumber && uncertainCalculate[item.key]) {
            item.value =
              formatDataPrecision(
                uncertainCalculate[item.key],
                item.precision
              ) || "";
          } else {
            item.value = uncertainCalculate[item.key] || "";
          }
        });
        uncertainCalculateSelected[0].children =
          uncertainCalculateSelected.splice(1, 2);
        uncertainCalculateSelected[2].children =
          uncertainCalculateSelected.splice(3, 7);
        this.uncertainCalculateSelected = uncertainCalculateSelected;
        console.log("uncertainCalculateSelected", uncertainCalculateSelected);
      } else if (this.activeTabName === "output_variable_table") {
        this.outputVariableTableSelectedData = {};
        if (this.activeDataItemSelected.length > 0) {
          this.outputVariableTableSelectedData = getOutputTableData(
            this.activeDataItemSelected[0]
          );
        }
      }
      console.log("activeDataItemSelected", this.activeDataItemSelected);
    },
    headerClick(column) {
      if (column.property == "selected") {
        if (this.multiDeviceShow) {
          this.toggleSelectDataDeviceListIds();
        } else {
          this.toggleSelectDataListIds();
        }
      }
    },
    expandRowClick(row, column, event) {
      console.log("expandRowClick", row, column, event);
      this.$refs.ExpandedTable.toggleRowExpansion(row);
    },
    rowClick(row, column, event) {
      if (column.property != "selected") {
        console.log("_updateSelect click1 row", row, column);
        if (this.multiDeviceShow) {
          this.updateSelectDataDeviceListIds(row, true);
        } else {
          this.updateSelectDataListIds(row, true);
        }
      } else {
        var target = "" + event.target.className;
        if (target.indexOf("el-checkbox") < 0) {
          console.log("_updateSelect click2 row", row, column, event, target);
          if (this.multiDeviceShow) {
            this.updateSelectDataDeviceListIds(row);
          } else {
            this.updateSelectDataListIds(row);
          }
        }
      }
    },
    updateSelectDataListIds(item, single_select = false) {
      console.log("updateSelectDataListIds", item, single_select);
      const index = this.selectedDataListIds.indexOf(item.internal_id);
      if (index >= 0) {
        this.selectedDataListIds.splice(index, 1);
        delete this.selectedDataListAllTheDeviceIds[item.internal_id];
      } else {
        if (single_select) {
          this.selectedDataListIds = [];
          this.selectedDataListAllTheDeviceIds = {};
        }
        this.selectedDataListIds.push(item.internal_id);
        this.selectedDataListAllTheDeviceIds[item.internal_id] = [];
        Object.keys(this.dbDataList).forEach((key) => {
          const dbItem = this.dbDataList[key];
          if (dbItem.internal_id === item.internal_id) {
            Object.keys(this.dbDataDeviceNames).forEach((dKey) => {
              const device_name = this.dbDataDeviceNames[dKey];
              const dItem =
                this.groupByData[dbItem.internal_id + "_" + device_name];
              if (dItem && dItem.internal_id) {
                this.selectedDataListAllTheDeviceIds[item.internal_id].push(
                  dItem.internal_id
                );
              }
            });
          }
        });
      }
      this.selectedDataListIdAll = false;
      this.updateDataListSelected();
    },
    toggleSelectDataListIds() {
      console.log("toggleSelectDataListIds");
      this.selectedDataListIdAll = !this.selectedDataListIdAll;
      this.selectedDataListIds = [];
      this.selectedDataListAllTheDeviceIds = {};
      this.updateDataListSelected();
    },
    updateDataListSelected() {
      Object.keys(this.dbDataList).forEach((key) => {
        this.dbDataList[key].selected =
          this.selectedDataListIdAll ||
          this.selectedDataListIds.includes(this.dbDataList[key].internal_id);
      });
      this.updateItemSelected();
    },
    updateSelectDataDeviceListIds(item, single_select = false) {
      console.log("updateSelectDataDeviceListIds", item, single_select);
      const index = this.selectedDataDeviceListIds.indexOf(item.internal_id);
      if (index >= 0) {
        this.selectedDataDeviceListIds.splice(index, 1);
      } else {
        if (single_select) {
          this.selectedDataDeviceListIds = [];
        }
        this.selectedDataDeviceListIds.push(item.internal_id);
      }
      this.selectedDataDeviceListIdAll = false;
      this.updateDataDeviceListSelected();
    },
    toggleSelectDataDeviceListIds() {
      console.log("toggleSelectDataDeviceListIds");
      this.selectedDataDeviceListIdAll = !this.selectedDataDeviceListIdAll;
      this.selectedDataDeviceListIds = [];
      this.updateDataDeviceListSelected();
    },
    updateDataDeviceListSelected() {
      Object.keys(this.dbDataDeviceList).forEach((key) => {
        this.dbDataDeviceList[key].selected =
          this.selectedDataDeviceListIdAll ||
          this.selectedDataDeviceListIds.includes(
            this.dbDataDeviceList[key].internal_id
          );
      });
      this.updateItemSelected();
    },
    showStandardDeviceInfo() {
      this.$refs.EditStandardDeviceInfo &&
        this.$refs.EditStandardDeviceInfo.show();
    },
    handleSizeChange(val) {
      console.log(`${val} items per page`);
      this.dbDataListPageSize = val;
      this._loadingData(true, false);
    },
    handleCurrentChange(val) {
      console.log(`current page: ${val}`);
      this.dbDataListCurrentPage = val;
      this._loadingData(true, false);
    },
    tableRowClassName({ row }) {
      if (row.selected) {
        return "selected-row";
      }
      return "unselected-row";
    },
    initDbData() {
      this.setRunIdSelect([]);
      this.runIdLoading = true;
      this.runIdSelected = false;
      this.selectStartTime = "";
      this.selectEndTime = "";
      if (this.deviceNameEnabledItem) {
        this.deviceNameEnabledItemForRunIds = this.deviceNameEnabledItem;
        this.deviceNameEnabledItemForLoadingData = this.deviceNameEnabledItem;
        this.deviceNameEnabledItem = null;
      }
      getRunIds(
        {},
        (data) => {
          console.log("getRunIds", data);
          if (data && data.data && data.data.data && data.data.code > 0) {
            let runIdSelectedValueDefault = [];
            let runIdSelectedValue = [];
            const newRunIds = [];
            const deviceNameEnabledItem = this.deviceNameEnabledItemForRunIds;
            this.deviceNameEnabledItemForRunIds = null;
            Object.keys(data.data.data).forEach((key) => {
              const item = data.data.data[key];
              newRunIds.push(item);
              if (runIdSelectedValue.length === 0) {
                if (
                  deviceNameEnabledItem &&
                  deviceNameEnabledItem.tag &&
                  deviceNameEnabledItem.tag.deviceNameSelect
                ) {
                  if (item.service_saved_ret) {
                    Object.keys(item.service_saved_ret).forEach((key) => {
                      if (
                        deviceNameEnabledItem.tag.deviceNameSelect.includes(
                          item.service_saved_ret[key].name
                        )
                      ) {
                        runIdSelectedValue = [item.experiment_id];
                      }
                    });
                  }
                } else {
                  runIdSelectedValue = [item.experiment_id];
                }
              }
              if (runIdSelectedValueDefault.length === 0) {
                runIdSelectedValueDefault = [item.experiment_id];
              }
            });
            if (runIdSelectedValue.length === 0) {
              console.log(
                "not found valid runIdSelectedValue",
                this.runIdSelect,
                deviceNameEnabledItem
              );
              // runIdSelectedValue = runIdSelectedValueDefault;
            }
            this.setRunIds(newRunIds);
            this.runIdSelectedValueDefault = runIdSelectedValueDefault;
            if (!this.runIdSelected) {
              this.runIdSelected = true;
              if (newRunIds.length > 0 && this.runIdSelect.length == 0) {
                if (
                  runIdSelectedValue &&
                  (!deviceNameEnabledItem ||
                    !deviceNameEnabledItem.tag ||
                    !deviceNameEnabledItem.tag.runIdSelect)
                ) {
                  console.log(
                    "setRunIdSelect runIdSelectedValue",
                    runIdSelectedValue
                  );
                  this.setRunIdSelect(runIdSelectedValue);
                  this.runIdSelectedValue = runIdSelectedValue;
                }
              }
            }
          }
          this.runIdLoading = false;
          this.$nextTick(() => {
            this.setRunIds(this.runIds);
          });
          this._loadingData();
        },
        (err) => {
          console.log("getRunIds err", err);
          this.runIdLoading = false;
          this._loadingData();
        }
      );
      this._reloadTemplate();
    },
    _reloadTemplate() {
      getTemplate(
        {},
        (data) => {
          console.log("getTemplate", data);
          if (data && data.data && data.data.data && data.data.code > 0) {
            this.templateChooseList = [];
            Object.values(data.data.data).forEach((name) => {
              this.templateChooseList.push({
                name,
                tips: name,
              });
            });
          }
        },
        (err) => {
          console.log("getTemplate err", err);
        }
      );
    },
    _runIdSelectChanged() {
      console.log("_runIdSelectChanged");
      this.selectStartTime = "";
      this.selectEndTime = "";
      this.setRunIdSelect(this.runIdSelect);
      this.setRunIds(this.runIds);
      this._loadingData();
    },
    _deviceNameSelectChanged() {
      console.log("_deviceNameSelectChanged");
      this.selectStartTime = "";
      this.selectEndTime = "";
      this.setRunIds(this.runIds);
      this._loadingData();
    },
    _loadingData(loading = true, resetSelected = true) {
      console.log("_loadingData", loading, resetSelected);
      if (this.unmountedFlag) {
        return;
      }
      if (resetSelected) {
        this.selectedDataListIds = [];
        this.selectedDataListAllTheDeviceIds = {};
        this.selectedDataListIdAll = false;
        this.selectedDataDeviceListIds = [];
        this.selectedDataDeviceListIdAll = false;
      }
      let device_data_params = {
        deviceNameSelect: this.deviceNameSelect,
        runIdSelect: this.runIdSelect,
        selectStartTime: this.selectStartTime,
        selectEndTime: this.selectEndTime,
        dbDataListCurrentPage: this.dbDataListCurrentPage,
        dbDataListPageSize: this.dbDataListPageSize,
        groupBy: ["time", "experiment_internal_id"],
        groupByData: true,
      };
      if (this.lastDeviceQueryEnabled) {
        this.lastDeviceQueryEnabled = false;
        if (this.lastDeviceQueryData && this.lastDeviceQueryData.value) {
          device_data_params = this.lastDeviceQueryData.value;
          this.setRunIdSelect(device_data_params.runIdSelect || []);
          this.setDeviceNameSelect(device_data_params.deviceNameSelect || []);
          this.selectStartTime = device_data_params.selectStartTime || "";
          this.selectEndTime = device_data_params.selectEndTime || "";
          this.dbDataListCurrentPage =
            device_data_params.dbDataListCurrentPage || 1;
          this.dbDataListPageSize = device_data_params.dbDataListPageSize || 10;
        }
        console.log(
          "lastDeviceQueryEnabled",
          device_data_params,
          this.lastDeviceQueryData
        );
      }
      if (this.allDeviceQueryEnabled) {
        this.allDeviceQueryEnabled = false;
        device_data_params = {
          deviceNameSelect: [],
          runIdSelect: this.runIdSelectedValueDefault,
          selectStartTime: "",
          selectEndTime: "",
          dbDataListCurrentPage: 1,
          dbDataListPageSize: 10,
          groupBy: ["time", "experiment_internal_id"],
          groupByData: true,
        };
        this.setRunIdSelect(device_data_params.runIdSelect || []);
        this.setDeviceNameSelect(device_data_params.deviceNameSelect || []);
        this.selectStartTime = device_data_params.selectStartTime || "";
        this.selectEndTime = device_data_params.selectEndTime || "";
        this.dbDataListCurrentPage =
          device_data_params.dbDataListCurrentPage || 1;
        this.dbDataListPageSize = device_data_params.dbDataListPageSize || 10;
      }
      const deviceNameEnabledItem = this.deviceNameEnabledItemForLoadingData;
      this.deviceNameEnabledItemForLoadingData = null;
      if (deviceNameEnabledItem) {
        device_data_params = {
          deviceNameSelect:
            deviceNameEnabledItem.tag.deviceNameSelect === undefined
              ? []
              : deviceNameEnabledItem.tag.deviceNameSelect,
          runIdSelect:
            deviceNameEnabledItem.tag.runIdSelect === undefined
              ? this.runIdSelectedValue
              : deviceNameEnabledItem.tag.runIdSelect,
          selectStartTime:
            deviceNameEnabledItem.tag.selectStartTime === undefined
              ? ""
              : deviceNameEnabledItem.tag.selectStartTime,
          selectEndTime:
            deviceNameEnabledItem.tag.selectEndTime === undefined
              ? ""
              : deviceNameEnabledItem.tag.selectEndTime,
          dbDataListCurrentPage:
            deviceNameEnabledItem.tag.dbDataListCurrentPage === undefined
              ? 1
              : deviceNameEnabledItem.tag.dbDataListCurrentPage,
          dbDataListPageSize:
            deviceNameEnabledItem.tag.dbDataListPageSize === undefined
              ? 10
              : deviceNameEnabledItem.tag.dbDataListPageSize,
          groupBy:
            deviceNameEnabledItem.tag.groupBy === undefined
              ? device_data_params.groupBy
              : deviceNameEnabledItem.tag.groupBy,
          groupByData:
            deviceNameEnabledItem.tag.groupByData === undefined
              ? device_data_params.groupByData
              : deviceNameEnabledItem.tag.groupByData,
        };
        this.setRunIdSelect(device_data_params.runIdSelect || []);
        this.setDeviceNameSelect(device_data_params.deviceNameSelect || []);
        this.selectStartTime = device_data_params.selectStartTime || "";
        this.selectEndTime = device_data_params.selectEndTime || "";
        this.dbDataListCurrentPage =
          device_data_params.dbDataListCurrentPage || 1;
        this.dbDataListPageSize = device_data_params.dbDataListPageSize || 10;
        console.log(
          "deviceNameEnabledItemForLoadingData",
          device_data_params,
          deviceNameEnabledItem
        );
      }
      this.lastDeviceQueryData.value = device_data_params;
      console.log("loading data", device_data_params);
      this.deviceDataParams = device_data_params;
      setConfig(
        this.lastDeviceQueryData,
        (res) => {
          if (res && res.data && res.data.data) {
            this.lastDeviceQueryData = res.data.data;
          }
          console.log("setConfig res", res);
        },
        (err) => {
          console.log("setConfig err", err);
        }
      );
      if (this.runIdSelect.length == 0) {
        this.idIsEmpty = true;
        this.errorTips = "要查看数据必须先选择需要查看的实验名称";
        this.$nextTick(() => {
          this.dbDataListTotal = 0;
          this.lastLoadingDataParams = "";
          this.setDbDataDeviceNames([]);
          this.setDbDataList([]);
          this.setGroupByData({});
          this.setDbDataDeviceList();
          this.updateDataDeviceListSelected();
          this.updateDataListSelected();
          this.setDbDataKey();
        });
        return;
      }
      this.idIsEmpty = false;
      if (this.loadingDbData) {
        console.log("is loading db data, load again");
        this.loadingDbDataAgain = true;
        return;
      }
      this.loadingDbData = loading;
      console.log("start loading page data");
      const callback = () => {
        this.loadingDbData = false;
        this.loadGraphData();
      };
      // 分页获取表格数据
      device_data_params.selectStartTime = null;
      device_data_params.selectEndTime = null;
      getDeviceData(
        {
          ...device_data_params,
          selectKeys: [
            "time",
            "experiment_internal_id",
            "internal_created_time",
            "internal_id",
            "device_name",
            "data",
            "unit",
            "ccr_raw_info",
            "ccr_s",
          ],
        },
        (data) => {
          callback();
          console.log("getDeviceData data", data);
          if (data && data.data && data.data.data && data.data.code > 0) {
            this.dbDataListTotal = data.data.data.dbDataListTotal;
            this.dbDataListCurrentPage = data.data.data.dbDataListCurrentPage;
            this.dbDataListPageSize = data.data.data.dbDataListPageSize;
            var dbDataList = data.data.data.dbDataList || [];
            var allIsSec = true;
            if (dbDataList.length > 0) {
              var startIndex = 0;
              var endIndex = dbDataList.length - 1;
              var middleIndex = parseInt(dbDataList.length / 2 - 1);
              if (middleIndex < 0) {
                middleIndex = 0;
              }
              if (dbDataList[startIndex].time % secUnit !== 0) {
                allIsSec = false;
              }
              if (dbDataList[middleIndex].time % secUnit !== 0) {
                allIsSec = false;
              }
              if (dbDataList[endIndex].time % secUnit !== 0) {
                allIsSec = false;
              }
            }
            var groupByData = data.data.data.groupByData || {};
            var dbDataDeviceNames = [];
            Object.keys(dbDataList).forEach((key) => {
              var item = dbDataList[key];
              if (item.ccr_raw_info) {
                if (item.ccr_raw_info.nominalA) {
                  item["nominalA"] = formatDataPrecision(
                    item.ccr_raw_info.nominalA,
                    getPrecisionByKey("nominalA")
                  );
                }
                if (item.ccr_raw_info.delta_mass_mg) {
                  item["delta_mass_mg"] = formatDataPrecision(
                    item.ccr_raw_info.delta_mass_mg,
                    getPrecisionByKey("delta_mass_mg")
                  );
                }
                item["positionA"] = item.ccr_raw_info.positionA;
                item["positionB"] = item.ccr_raw_info.positionB;
                item["ccr_file_name"] = item.ccr_raw_info.ccr_file_name;
                item["ccr_job_name"] = item.ccr_raw_info.JobName;
                item["ccr_date"] = item.ccr_raw_info.ccr_date
                  ? formatDateFromNs(item.ccr_raw_info.ccr_date)
                  : "";
              }
              if (item.ccr_s) {
                item.ccr_s = formatDataPrecision(
                  item.ccr_s,
                  getPrecisionByKey("mean")
                );
              }
              item.date = formatDateFromNs(item.time);
              if (!allIsSec) {
                item.date +=
                  "." + parseInt(((item.time % secUnit) * 10) / secUnit);
              }
              var groupByDataKey = item.internal_id;
              var groupByDataItem = groupByData[groupByDataKey];
              if (groupByDataItem) {
                Object.values(groupByDataItem).forEach((value) => {
                  if (value.ccr_raw_info) {
                    if (value.ccr_raw_info.nominalA) {
                      value["nominalA"] = formatDataPrecision(
                        value.ccr_raw_info.nominalA,
                        getPrecisionByKey("nominalA")
                      );
                    }
                    if (value.ccr_raw_info.delta_mass_mg) {
                      value["delta_mass_mg"] = formatDataPrecision(
                        value.ccr_raw_info.delta_mass_mg,
                        getPrecisionByKey("delta_mass_mg")
                      );
                    }
                    value["positionA"] = value.ccr_raw_info.positionA;
                    value["positionB"] = value.ccr_raw_info.positionB;
                    value["ccr_file_name"] = value.ccr_raw_info.ccr_file_name;
                    value["ccr_job_name"] = value.ccr_raw_info.JobName;
                    value["ccr_date"] = value.ccr_raw_info.ccr_date
                      ? formatDateFromNs(value.ccr_raw_info.ccr_date)
                      : "";
                  }
                  if (value.ccr_s) {
                    value.ccr_s = formatDataPrecision(
                      value.ccr_s,
                      getPrecisionByKey("mean")
                    );
                  }
                  groupByData[item.internal_id + "_" + value.device_name] =
                    value;
                  if (dbDataDeviceNames.indexOf(value.device_name) < 0) {
                    dbDataDeviceNames.push(value.device_name);
                  }
                });
              }
            });
            dbDataDeviceNames.sort();
            if (this.deviceNameSelect.length > 0) {
              const dbDataDeviceNamesSelect = [];
              Object.keys(this.deviceNameSelect).forEach((deviceNameKey) => {
                const deviceName = this.deviceNameSelect[deviceNameKey];
                if (dbDataDeviceNames.includes(deviceName)) {
                  dbDataDeviceNamesSelect.push(deviceName);
                }
              });
              Object.keys(dbDataDeviceNames).forEach((deviceNameKey) => {
                const deviceName = dbDataDeviceNames[deviceNameKey];
                if (!this.deviceNameSelect.includes(deviceName)) {
                  dbDataDeviceNamesSelect.push(deviceName);
                }
              });
              dbDataDeviceNames = dbDataDeviceNamesSelect;
            }
            this.setDbDataDeviceNames(dbDataDeviceNames);
            this.setDbDataList(dbDataList);
            this.setGroupByData(groupByData);
            this.setDbDataDeviceList();
            this.updateDataDeviceListSelected();
            this.updateDataListSelected();
            this.setDbDataKey();
          }
          if (this.dbDataListTotal <= 0) {
            this.errorTips = "当前选中的设备和实验名称和时间段没有产生任何数据";
          } else {
            this.errorTips = "";
          }
        },
        (err) => {
          callback();
          console.log("getDeviceData err", err);
          this.errorTips = "读取数据库失败！！";
        }
      );
      if (resetSelected) {
        if (this.showDebugTimeData) {
          this.$refs.MyDatabaseShowDebugGraph.initDbData(true);
        }
        this.$refs.MyDatabaseGraph.initDbData(true);
      }
    },
    loadGraphData() {
      const loadingDataParams = JSON.stringify({
        deviceNameSelect: this.deviceNameSelect,
        runIdSelect: this.runIdSelect,
        // selectStartTime: this.selectStartTime,
        // selectEndTime: this.selectEndTime,
      });
      const callback = () => {
        this.loadingDbGraphData = false;
        if (this.loadingDbDataAgain) {
          console.log("load again");
          this.loadingDbDataAgain = false;
          this._loadingData();
        }
        if (this.loadingDbGraphDataAgain) {
          console.log("load graph again");
          this.loadingDbGraphDataAgain = false;
          this.loadGraphData();
        }
      };
      if (this.loadingDbGraphData) {
        console.log("is loading graph db data, load again");
        this.loadingDbGraphDataAgain = true;
        return;
      }
      this.loadingDbGraphData = true;
      if (this.lastLoadingDataParams === loadingDataParams) {
        console.log("no need to load graph again", this.lastLoadingDataParams);
        callback();
      } else {
        this.lastLoadingDataParams = loadingDataParams;
        callback();
      }
    },
    saveTag(tag_name) {
      let deviceTag = this.deviceTags.find(
        (item) => item.device_name === tag_name
      );
      if (!deviceTag) {
        deviceTag = {
          device_name: tag_name,
          tag: this.deviceDataParams,
        };
      } else {
        deviceTag.tag = this.deviceDataParams;
      }
      setDeviceTag(
        deviceTag,
        (data) => {
          console.log("setDeviceTag data", data);
          if (data && data.data && data.data.data && data.data.code > 0) {
            if (!deviceTag.internal_id) {
              this.deviceTags.push(data.data.data);
              this.deviceTagNames.push(data.data.data.device_name);
            }
          }
        },
        (err) => {
          console.log("setDeviceTag err", err);
        }
      );
    },
    _saveTag() {
      ElMessageBox.prompt("请输入标签名称", "保存标签", {
        confirmButtonText: "保存",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          if (value) {
            if (this.deviceTagNames.includes(value)) {
              ElMessageBox.confirm(
                "已有名称为：" + value + " 的标签，是否覆盖标签的查询条件？",
                "标签重名",
                {
                  confirmButtonText: "覆盖",
                  cancelButtonText: "取消",
                  type: "warning",
                }
              )
                .then(() => {
                  this.saveTag(value);
                })
                .catch(() => {});
            } else {
              this.saveTag(value);
            }
          }
        })
        .catch(() => {});
    },
    _sendDataToClipboard() {
      console.log("sendDataToClipboard");
      const clipboardInfoObj = getClipboardInfoObj(
        (this.sendToClipboardInfo.value || {}).data
      );
      if (!clipboardInfoObj) {
        this._sendDataToClipboardEdit();
        return;
      }
      const selectedInternalIds = [];
      let selectedInternalIdsCount = 0;
      let noDataSelected = true;
      if (this.multiDeviceShow) {
        if (
          this.selectedDataDeviceListIdAll ||
          this.selectedDataDeviceListIds.length > 0
        ) {
          noDataSelected = false;
          selectedInternalIds.push(...this.selectedDataDeviceListIds);
          selectedInternalIdsCount = selectedInternalIds.length;
          if (this.selectedDataDeviceListIdAll) {
            selectedInternalIdsCount = this.dbDataListTotal;
          }
        }
      } else {
        if (this.selectedDataListIdAll || this.selectedDataListIds.length > 0) {
          noDataSelected = false;
          Object.values(this.selectedDataListAllTheDeviceIds).forEach(
            (item) => {
              selectedInternalIds.push(...item);
            }
          );
          selectedInternalIdsCount = selectedInternalIds.length;
          if (this.selectedDataDeviceListIdAll) {
            selectedInternalIdsCount = this.dbDataListTotal;
          }
        }
      }
      if (selectedInternalIdsCount > 2000) {
        ElMessage.error(
          "复制到剪切板不能超过2千条数据，请减少选择的数据量或者生成报告的功能！"
        );
        return;
      }
      if (noDataSelected) {
        ElMessage.error("请先选择要发送的数据或者全选所有数据！");
        return;
      }
      const loading = ElLoading.service({
        lock: true,
        text: "正在复制到剪切板，请稍后...",
        background: "rgba(0, 0, 0, 0.7)",
      });
      getDeviceData(
        {
          deviceNameSelect: this.deviceNameSelect,
          runIdSelect: this.runIdSelect,
          dbDataListCurrentPage: 1,
          dbDataListPageSize: 10000,
          selectedInternalIds: selectedInternalIds,
          selectKeys: [
            "time",
            "experiment_internal_id",
            "internal_created_time",
            "internal_id",
            "device_name",
            "data",
            "unit",
            "ccr_raw_info",
            "ccr_s",
          ],
        },
        (data) => {
          loading.close();
          console.log("getDeviceData data for copy send", data);
          if (
            data &&
            data.data &&
            data.data.data &&
            data.data.data.dbDataList &&
            data.data.data.dbDataList.length > 0 &&
            data.data.code > 0
          ) {
            const textHtml = getClipboardCopyText(
              clipboardInfoObj,
              data.data.data.dbDataList
            );
            const text = textHtml.text;
            const html = textHtml.html;
            if (text) {
              const clipboardItem = new ClipboardItem({
                "text/plain": new Blob([text], { type: "text/plain" }),
                "text/html": new Blob([html], { type: "text/html" }),
              });
              copyItem(clipboardItem, (text) => {
                if (text) {
                  ElMessage.success("已经成功发送至剪切板！");
                } else {
                  ElMessage.error("发送至剪切板失败！");
                }
              });
            } else {
              ElMessage.error("未解析到有效数据可发送至剪切板");
            }
          } else {
            ElMessage.error("未找到任何数据！");
          }
        },
        (err) => {
          loading.close();
          ElMessage.error("获取任何数据失败！");
          console.log("getDeviceData err for copy send", err);
        }
      );
    },
    _sendDataToClipboardEdit() {
      console.log("sendDataToClipboardEdit");
      this.$refs.EditSendToClipboardInfo &&
        this.$refs.EditSendToClipboardInfo.show(
          this.activeDataItemSelected.length > 0
            ? this.activeDataItemSelected[0]
            : null
        );
    },
    _setConfigTemplate(name) {
      setConfig(
        {
          ...this.exportDataTemplateInfo,
          value: {
            path: name,
            name: name,
          },
        },
        (res) => {
          if (res && res.data && res.data.data) {
            this.exportDataTemplateInfo = res.data.data;
          }
          console.log("setConfig res", res);
        },
        (err) => {
          console.log("setConfig err", err);
        }
      );
    },
    saveEditFileChange(event) {
      const file =
        event &&
        event.target &&
        event.target.files &&
        event.target.files.length > 0 &&
        event.target.files[0];
      if (file && file.name) {
        uploadDataTemplate(
          file,
          (data) => {
            console.log("uploadDataTemplate data", data);
            if (data && data.data && data.data.data && data.data.code > 0) {
              this._setConfigTemplate(file.name);
            } else {
              ElMessage.error("模板选择失败，请重启应用！");
            }
          },
          (err) => {
            console.log("uploadDataTemplate err", err);
            ElMessage.error("模板选择失败，请重启应用！");
          }
        );
      }
    },
    templateChooseClose() {
      if (!this.templateChooseVisible) {
        console.log("templateChooseClose twice");
        return;
      }
      this.templateChooseVisible = false;
      if (this.templateChoose) {
        this._setConfigTemplate(this.templateChoose);
      }
      this.templateChoose = "";
    },
    _saveEdit() {
      console.log("saveEdit");
      // this.$refs.m_in.value = "";
      // this.$refs.m_in.click();
      this._reloadTemplate();
      const pos = document.getElementById("m_in").getBoundingClientRect();
      this.templateChooseLeft = pos.left + "px";
      this.templateChooseTop = pos.top + "px";
      this.templateChooseVisible = true;
      this.templateChoose =
        (this.exportDataTemplateInfo.value || {}).name || "";
      this.$nextTick(() => {
        document.getElementById("templateChoose").focus();
      });
    },
    _save() {
      console.log("save");
      if (!this.dbTypeSelectOnlyCcr) {
        const loading = ElLoading.service({
          lock: true,
          text: "正在生成报告，请稍后...",
          background: "rgba(0, 0, 0, 0.7)",
        });
        exportDeviceData(
          {
            deviceNameSelect: this.deviceNameSelect,
            runIdSelect: this.runIdSelect,
            selectStartTime: this.selectStartTime,
            selectEndTime: this.selectEndTime,
            dbDataListCurrentPage: this.dbDataListCurrentPage,
            dbDataListPageSize: this.dbDataListPageSize,
            groupBy: ["time", "experiment_internal_id"],
            groupByData: true,
          },
          (data) => {
            loading.close();
            console.log("exportDeviceData data", data);
            if (data && data.data && data.data.data && data.data.code > 0) {
              if (data.data.data.downloadUrl) {
                window.location.href =
                  window.location.protocol +
                  "//" +
                  window.location.host.replace("8080", "8777") +
                  "/" +
                  data.data.data.downloadUrl;
                ElMessage.success("成功生成报告！");
              } else {
                ElMessage.error("报告模板有错误，请更正后重新选择正确的模版！");
              }
            } else {
              if (data.data.code == -12) {
                ElMessage.error("报告模板文件未找到，请重新选择报告模板！");
              } else {
                ElMessage.error("生成报告失败！");
              }
            }
          },
          (err) => {
            loading.close();
            console.log("exportDeviceData err", err);
            ElMessage.error("生成报告失败1！");
          }
        );
        return;
      }
      if (!this.exportDataTemplateInfo.value) {
        this._saveEdit();
        return;
      }
      const selectedInternalIds = [];
      let noDataSelected = true;
      if (this.multiDeviceShow) {
        if (
          this.selectedDataDeviceListIdAll ||
          this.selectedDataDeviceListIds.length > 0
        ) {
          noDataSelected = false;
          selectedInternalIds.push(...this.selectedDataDeviceListIds);
        }
      } else {
        if (this.selectedDataListIdAll || this.selectedDataListIds.length > 0) {
          noDataSelected = false;
          Object.values(this.selectedDataListAllTheDeviceIds).forEach(
            (item) => {
              selectedInternalIds.push(...item);
            }
          );
        }
      }
      if (noDataSelected) {
        ElMessage.error("请先选择要导出的数据或者全选所有数据！");
        return;
      }
      const loading = ElLoading.service({
        lock: true,
        text: "正在生成报告，请稍后...",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const csv_time = dayjs().format("YYYY_MM_DD_HH_mm_ss");
      const total = this.dbDataListTotal;
      let maxLimit = 10000;
      if (selectedInternalIds && selectedInternalIds.length > 0 && total > 0) {
        maxLimit = total; //不可能手动选择超过1万条，目前不支持全选后反选，所以没问题
      }
      const maxIndex = total / maxLimit;
      const export_with_csv = total > maxLimit;
      const callback = (nextIndex, hasNext, downloadUrl) => {
        if (hasNext) {
          this.exportTemplateData(
            nextIndex,
            callback,
            loading,
            maxLimit,
            total,
            selectedInternalIds,
            csv_time,
            export_with_csv
          );
        } else {
          loading.close();
          window.location.href =
            window.location.protocol +
            "//" +
            window.location.host.replace("8080", "8777") +
            "/" +
            downloadUrl;
          ElMessage.success("成功生成报告！");
        }
      };
      this.exportTemplateData(
        Math.ceil(maxIndex),
        callback,
        loading,
        maxLimit,
        total,
        selectedInternalIds,
        csv_time,
        export_with_csv
      );
    },
    exportTemplateData(
      index,
      callback,
      loading,
      maxLimit,
      total,
      selectedInternalIds,
      csv_time,
      export_with_csv
    ) {
      // const seconds = 6.34 * total; // 6.34 秒一条数据
      // const finishedTime = formatDate(Date.now() + seconds * 1000);
      // loading.setText(
      //   `正在生成报告，耗时可能较长，预计最晚完成时间：${finishedTime} ，请耐心等候...`
      // );
      loading.setText("正在生成报告，耗时可能较长，请耐心等候...");
      const nextIndex = index - 1;
      const hasNext = nextIndex > 0;
      let valid_item_count = maxLimit;
      if (index * maxLimit > total) {
        valid_item_count = total - (index - 1) * maxLimit;
      }
      if (selectedInternalIds && selectedInternalIds.length > 0) {
        valid_item_count = selectedInternalIds.length;
      }
      getDeviceData(
        {
          deviceNameSelect: this.deviceNameSelect,
          runIdSelect: this.runIdSelect,
          dbDataListCurrentPage: index,
          dbDataListPageSize: maxLimit,
          selectedInternalIds: selectedInternalIds,
          selectKeys: [
            "time",
            "experiment_internal_id",
            "internal_created_time",
            "internal_id",
            "device_name",
            "data",
            "unit",
            "ccr_raw_info",
            "ccr_s",
          ],
        },
        (data) => {
          console.log("getDeviceData data for export data", data);
          if (
            data &&
            data.data &&
            data.data.data &&
            data.data.data.dbDataList &&
            data.data.data.dbDataList.length > 0 &&
            data.data.code > 0
          ) {
            const exportDataInfo = getExportDataInfo(data.data.data.dbDataList);
            exportDeviceData(
              {
                deviceNameSelect: this.deviceNameSelect,
                runIdSelect: this.runIdSelect,
                dbDataListCurrentPage: index,
                dbDataListPageSize: maxLimit,
                selectedInternalIds: selectedInternalIds,
                exportDataTemplateInfo: this.exportDataTemplateInfo.value,
                exportDataInfo,
                csv_time,
                export_with_csv,
                csv_has_next: hasNext,
                csv_index: index,
                valid_item_count,
              },
              (data) => {
                console.log("exportDeviceData data", data);
                if (data && data.data && data.data.data && data.data.code > 0) {
                  if (data.data.data.downloadUrl) {
                    callback(nextIndex, hasNext, data.data.data.downloadUrl);
                  } else {
                    loading.close();
                    ElMessage.error(
                      "报告模板有错误，请更正后重新选择正确的模版！"
                    );
                  }
                } else {
                  loading.close();
                  if (data.data.code == -12) {
                    ElMessage.error("报告模板文件未找到，请重新选择报告模板！");
                  } else {
                    ElMessage.error("生成报告失败！");
                  }
                }
              },
              (err) => {
                loading.close();
                console.log("exportDeviceData err", err);
                ElMessage.error("生成报告失败1！");
              }
            );
          } else {
            loading.close();
            ElMessage.error("未找到任何数据！");
          }
        },
        (err) => {
          loading.close();
          ElMessage.error("获取任何数据失败！");
          console.log("getDeviceData err for export data", err);
        }
      );
    },
    notifyLoadingDatabase() {
      console.log("notifyLoadingDatabase");
      this.initDbData();
      if (this.showDebugTimeData) {
        this.$refs.MyDatabaseShowDebugGraph.initDbData(true);
      }
      this.$refs.MyDatabaseGraph.initDbData(true);
    },
  },
  mounted() {
    this.showDebugTimeData =
      (this.$route.query && this.$route.query.showDebugTimeData) || "";
    this.databaseOptimize =
      ((this.$route.query && this.$route.query.databaseOptimize) || "") + "";
    this.unmountedFlag = false;

    this.$bus.$on("notifyLoadingDatabase", this.notifyLoadingDatabase);

    this.initDbData();
  },
  unmounted() {
    this.unmountedFlag = true;
    this.$bus.$off("notifyLoadingDatabase", this.notifyLoadingDatabase);
    this.loadingDbData = false;
    this.loadingDbGraphData = false;
    this.loadingDbDataAgain = false;
    this.loadingDbGraphDataAgain = false;
  },
  setup() {
    const deviceData = inject("deviceData");
    const menuData = inject("menuData");
    const errorTips = ref("");
    const idIsEmpty = ref(false);
    const latestGraphDataLoading = ref(false);
    const latestGraphDataTime = ref(0);
    const latestGraphDataTimeDelta = ref(500);
    const graphTips = ref("");
    const isAdd = computed(
      () => deviceData.addDeviceItem.internal_id == undefined
    );
    const runIdSelected = ref(false);
    const runIdLoading = ref(true);
    const lastLoadingDataParams = ref("");
    const isDelta = computed(
      () =>
        (deviceData.addDeviceItem.DeltaTime != undefined &&
          deviceData.addDeviceItem.DeltaTime != "00:00:00" &&
          deviceData.addDeviceItem.DeltaTime != 0) ||
        (deviceData.addDeviceItem.DeltaTimeMilli != undefined &&
          deviceData.addDeviceItem.DeltaTimeMilli != 0)
    );
    const deviceNameEnabledItemForRunIds = ref(null);
    const deviceNameEnabledItemForLoadingData = ref(null);
    const runIdSelectedValue = ref([]);
    const runIdSelectedValueDefault = ref([]);
    const deviceDataParams = ref(null);
    const unmountedFlag = ref(false);
    const activeTabName = ref("dynamic_graph");
    const measureDataSelected = ref({});
    const calculateResultSelected = ref([]);
    const calculateResultNeedUpdate = ref(false);
    const calculateResultUpdateLoading = ref(false);
    const uncertainCalculateSelected = ref([]);
    const activeDataItemSelected = ref(null);
    const outputVariableTableSelectedData = ref({});
    const outputVariableTableSelectedParams = ref(outputTableVariableParams);
    const sendDataToClipboardEdit = ref(false);
    const saveEdit = ref(false);
    const templateChooseVisible = ref(false);
    const templateChoose = ref("");
    const templateChooseLeft = ref("0px");
    const templateChooseTop = ref("0px");
    const templateChooseList = ref([]);

    const showDebugTimeData = ref("");
    const databaseOptimize = ref("");

    const data = {
      ...toRefs(deviceData),
      ...toRefs(menuData),
      isAdd,
      isDelta,
      errorTips,
      idIsEmpty,
      runIdSelected,
      runIdLoading,
      lastLoadingDataParams,
      latestGraphDataTime,
      latestGraphDataTimeDelta,
      latestGraphDataLoading,
      graphTips,
      deviceNameEnabledItemForRunIds,
      deviceNameEnabledItemForLoadingData,
      runIdSelectedValue,
      runIdSelectedValueDefault,
      deviceDataParams,
      unmountedFlag,
      activeTabName,
      measureDataSelected,
      calculateResultSelected,
      calculateResultNeedUpdate,
      calculateResultUpdateLoading,
      uncertainCalculateSelected,
      activeDataItemSelected,
      outputVariableTableSelectedData,
      outputVariableTableSelectedParams,
      sendDataToClipboardEdit,
      saveEdit,
      templateChooseVisible,
      templateChoose,
      templateChooseLeft,
      templateChooseTop,
      templateChooseList,
      showDebugTimeData,
      databaseOptimize,
    };
    return data;
  },
};
</script>

<style scoped>
.tabs {
  width: 100%;
  margin-top: 20px;
}
.calculate_result_btn {
  margin-top: 20px;
  padding: 20px 80px;
}
.calculate_result_selected {
  width: 100%;
  margin-top: 20px;
}
.uncertain_calculate_selected {
  width: 100%;
  margin-top: 20px;
}
.measure_data_selected {
  width: 100%;
  margin-top: 20px;
}
.output_variable_table_selected {
  width: 100%;
  margin-top: 20px;
}
.chart-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 20px;
}

.chart-container-canvas {
  width: 864px;
  height: 441px;
  display: inline-block;
  vertical-align: top;
}

.chart-container-legend {
  display: inline-block;
  vertical-align: top;
}

.chart-container-width {
  width: 864px !important;
}

.row-max {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.title {
  min-width: 90px;
  text-align: start;
}

.title-select {
  min-width: 485px;
}

.row-top-divider {
  margin-top: 10px;
}

.el-tabs--card :deep(.el-tabs__header .el-tabs__item.is-active) {
  background-color: #41419f !important;
  color: #ffffff !important;
}

.el-tabs {
  --el-tabs-header-height: 32px !important;
}

.multi_device_show {
  width: 100%;
  margin-bottom: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.el-table {
  --el-table-header-text-color: #ffffff !important;
  --el-table-text-color: #101010 !important;
  --el-table-header-bg-color: #41419f !important;
}

.el-table :deep(thead.is-group th.el-table__cell) {
  background-color: var(--el-table-header-bg-color) !important;
}

.table_cell_row {
  /* height: 50px; */
  display: flex;
  flex-direction: row;
  align-items: center;
}

.table_cell_left {
  flex: 5;
  /* max-height: 50px; */
  text-align: right;
}

.table_cell_right {
  flex: 2;
  /* max-height: 50px; */
  text-align: left;
}

.table_cell_divider {
  /* background-color: var(--el-table-border-color); */
  /* width: 1px; */
  /* height: 50px; */
  width: 10px;
}

.el-table .el-table__cell {
  margin: 0px;
}

.el-table--mini .el-table__cell {
  padding: 0px;
}

.divider-middle {
  margin-top: 16px;
}

.divider-bottom {
  margin-bottom: 60px;
}

.divider-time {
  margin-left: 20px;
  margin-right: 20px;
}

.divider-btn {
  margin: 20px 10px;
  padding: 0px 0px;
  height: 36px;
}

.divider-btn-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.divider-btn-left {
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  padding-left: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.divider-btn-label {
  padding: 0px 10px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.divider-btn-right {
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  padding-right: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.my-table {
  max-width: 100%;
  min-width: 576px;
}

.error-tips {
  color: #f56c6c;
}

.error-tips-margin {
  margin-bottom: 20px;
}

.graph-tips {
  color: lightgrey;
  flex: 1;
  text-align: right;
  font-size: xx-small;
  word-break: pre;
  word-wrap: pre;
  white-space: pre-line;
}

.tag-tips {
  word-break: pre;
  word-wrap: pre;
  white-space: pre-line;
}

.row-center {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.el-table :deep(.selected-row) {
  --el-table-tr-bg-color: #6666c4 !important;
  --el-table-row-hover-bg-color: #d1d1f0 !important;
}

.el-table :deep(.unselected-row) {
  --el-table-tr-bg-color: #ffffff !important;
  --el-table-row-hover-bg-color: #f5f7fa !important;
}

.bottom_container {
  min-height: 400px;
}

.latex {
  font-size: 16px;
  min-height: 32px;
}

.desc-icon {
  font-size: 16px;
  margin-left: 5px;
}

.more-icon {
  font-size: 18px;
  margin-left: 5px;
}

.m_in {
  width: 1px;
  height: 1px;
}

.templateChoose {
  position: absolute;
  z-index: 9000;
}

.color_changed :deep(.el-input__inner) {
  color: #f56c6c;
}

.color_changed :deep(.el-input__wrapper) {
  box-shadow: 0 0 0 1px #f56c6c inset;
}
</style>
