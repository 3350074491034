import { upload, request } from "./net";

const host =
  window.location.protocol +
  "//" +
  window.location.host.replace("8080", "8777") +
  "/web_interface";

const addTianpingAndService = (params, success, fail) => {
  request(
    `${host}/tianping/add_params_and_service`,
    "post",
    params,
    success,
    fail
  );
};

const getAllDeviceTags = (params, success, fail) => {
  request(
    `${host}/tianping/get_all_device_tags`,
    "post",
    params,
    success,
    fail
  );
};

const removeDeviceTag = (params, success, fail) => {
  request(`${host}/tianping/remove_device_tag`, "post", params, success, fail);
};

const setDeviceTag = (params, success, fail) => {
  request(`${host}/tianping/set_device_tag`, "post", params, success, fail);
};

const getNames = (params, success, fail) => {
  request(`${host}/tianping/get_names`, "post", params, success, fail);
};

const getRunIds = (params, success, fail) => {
  request(`${host}/tianping/get_run_ids`, "post", params, success, fail);
};

const getConfigByKey = (params, success, fail) => {
  request(`${host}/tianping/get_config_by_key`, "post", params, success, fail);
};

const getLatestGraphData = (params, success, fail) => {
  request(
    `${host}/tianping/get_latest_graph_data`,
    "post",
    params,
    success,
    fail
  );
};

const setConfig = (params, success, fail) => {
  request(`${host}/tianping/set_config`, "post", params, success, fail);
};

const getDeviceData = (params, success, fail) => {
  request(`${host}/tianping/get_device_data`, "post", params, success, fail);
};

const exportDeviceData = (params, success, fail) => {
  request(`${host}/tianping/export_device_data`, "post", params, success, fail);
};

const addStartParams = (params, success, fail) => {
  request(`${host}/tianping/add_start_params`, "post", params, success, fail);
};

const getStartParams = (params, success, fail) => {
  request(`${host}/tianping/get_start_params`, "post", params, success, fail);
};

const getScreenNames = (params, success, fail) => {
  request(`${host}/tianping/get_screen_names`, "post", params, success, fail);
};

const getPorts = (params, success, fail) => {
  request(`${host}/tianping/get_ports`, "post", params, success, fail);
};

const getLastKey = (params, success, fail) => {
  request(`${host}/tianping/get_last_key`, "post", params, success, fail);
};

const getDeviceInfo = (params, success, fail) => {
  request(`${host}/tianping/get_device_info`, "post", params, success, fail);
};

const getSystemInfo = (params, success, fail) => {
  request(`${host}/tianping/get_system_info`, "post", params, success, fail);
};

const isWizConnector = (params, success, fail) => {
  request(`${host}/tianping/is_wiz_connector`, "post", params, success, fail);
};

const handleWizConnectorKeyName = (params, success, fail) => {
  request(
    `${host}/tianping/handle_wiz_connector_key_name`,
    "post",
    params,
    success,
    fail
  );
};

const getService = (params, success, fail) => {
  request(`${host}/service/get_service`, "get", params, success, fail);
};

const deleteParamService = (params, success, fail) => {
  request(`${host}/tianping/delete_params`, "post", params, success, fail);
};

const getScreenStatus = (params, success, fail) => {
  request(`${host}/tianping/get_screen_status`, "post", params, success, fail);
};

const getConnectStatus = (params, success, fail) => {
  request(`${host}/tianping/get_connect_status`, "post", params, success, fail);
};

const startService = (params, success, fail) => {
  request(`${host}/service/start`, "post", params, success, fail);
};

const stopService = (params, success, fail) => {
  request(`${host}/service/stop`, "post", params, success, fail);
};

const getStandardDeviceInfo = (params, success, fail) => {
  request(
    `${host}/tianping/get_standard_device_info`,
    "post",
    params,
    success,
    fail
  );
};

const addStandardDeviceInfo = (params, success, fail) => {
  request(
    `${host}/tianping/add_standard_device_info`,
    "post",
    params,
    success,
    fail
  );
};

const deleteStandardDeviceInfo = (params, success, fail) => {
  request(
    `${host}/tianping/delete_standard_device_info`,
    "post",
    params,
    success,
    fail
  );
};

const getTestedWeightInfo = (params, success, fail) => {
  request(
    `${host}/tianping/get_tested_weight_info`,
    "post",
    params,
    success,
    fail
  );
};

const addTestedWeightInfo = (params, success, fail) => {
  request(
    `${host}/tianping/add_tested_weight_info`,
    "post",
    params,
    success,
    fail
  );
};

const deleteTestedWeightInfo = (params, success, fail) => {
  request(
    `${host}/tianping/delete_tested_weight_info`,
    "post",
    params,
    success,
    fail
  );
};

const getEnvironmentParamsInfo = (params, success, fail) => {
  request(
    `${host}/tianping/get_environment_params_info`,
    "post",
    params,
    success,
    fail
  );
};

const addEnvironmentParamsInfo = (params, success, fail) => {
  request(
    `${host}/tianping/add_environment_params_info`,
    "post",
    params,
    success,
    fail
  );
};

const deleteEnvironmentParamsInfo = (params, success, fail) => {
  request(
    `${host}/tianping/delete_environment_params_info`,
    "post",
    params,
    success,
    fail
  );
};

const getPalletMassDifferenceInfo = (params, success, fail) => {
  request(
    `${host}/tianping/get_pallet_mass_difference_info`,
    "post",
    params,
    success,
    fail
  );
};

const addPalletMassDifferenceInfo = (params, success, fail) => {
  request(
    `${host}/tianping/add_pallet_mass_difference_info`,
    "post",
    params,
    success,
    fail
  );
};

const deletePalletMassDifferenceInfo = (params, success, fail) => {
  request(
    `${host}/tianping/delete_pallet_mass_difference_info`,
    "post",
    params,
    success,
    fail
  );
};

const getTestedWeightUnitedInfo = (params, success, fail) => {
  request(
    `${host}/tianping/get_tested_weight_united_info`,
    "post",
    params,
    success,
    fail
  );
};

const addTestedWeightUnitedInfo = (params, success, fail) => {
  request(
    `${host}/tianping/add_tested_weight_united_info`,
    "post",
    params,
    success,
    fail
  );
};

const deleteTestedWeightUnitedInfo = (params, success, fail) => {
  request(
    `${host}/tianping/delete_tested_weight_united_info`,
    "post",
    params,
    success,
    fail
  );
};

const updateCalculateResult = (params, success, fail) => {
  request(
    `${host}/tianping/update_calculate_result`,
    "post",
    params,
    success,
    fail
  );
};

const needUpdateCalculateResults = (params, success, fail) => {
  request(
    `${host}/tianping/need_update_calculate_results`,
    "post",
    params,
    success,
    fail
  );
};

const updateCalculateResults = (params, success, fail) => {
  request(
    `${host}/tianping/update_calculate_results`,
    "post",
    params,
    success,
    fail
  );
};

const uploadDataTemplate = (file, success, fail) => {
  upload(`${host}/tianping/upload_data_template`, file, success, fail);
};

const getTemplate = (params, success, fail) => {
  request(`${host}/tianping/get_template`, "post", params, success, fail);
};

const getTime = (params, success, fail) => {
  request(
    `${host}/get_time`
      // .replace("localhost", "192.168.2.1")
      .replace("8777", "8776")
      .replace("8778", "8776")
      .replace("8779", "8776"),
    "post",
    params,
    success,
    fail
  );
};

const setTime = (params, success, fail) => {
  request(
    `${host}/set_time`
      // .replace("localhost", "192.168.2.1")
      .replace("8777", "8776")
      .replace("8778", "8776")
      .replace("8779", "8776"),
    "post",
    params,
    success,
    fail
  );
};

const updateTime = (params, success, fail) => {
  request(`${host}/tianping/update_time`, "post", params, success, fail);
};

const getUpgradeVersion = (params, success, fail) => {
  request(
    `${host}/tianping/get_upgrade_version`,
    "post",
    params,
    success,
    fail
  );
};

const startUpgradeVersion = (params, success, fail) => {
  request(
    `${host}/tianping/start_upgrade_version`,
    "post",
    params,
    success,
    fail
  );
};

const updateQueryConf = (params, success, fail) => {
  request(`${host}/tianping/update_query_conf`, "post", params, success, fail);
};

const getDeviceDataDebugGraph = (params, success, fail) => {
  request(
    `${host}/tianping/get_device_data_debug_graph`,
    "post",
    params,
    success,
    fail
  );
};

const getDeviceGraph = (params, success, fail) => {
  request(`${host}/tianping/get_device_graph`, "post", params, success, fail);
};

const deleteDataWithCondition = (params, success, fail) => {
  request(
    `${host}/tianping/delete_data_with_condition`,
    "post",
    params,
    success,
    fail
  );
};

const deleteDataWithIds = (params, success, fail) => {
  request(
    `${host}/tianping/delete_data_with_ids`,
    "post",
    params,
    success,
    fail
  );
};

export {
  addTianpingAndService,
  getPorts,
  getLastKey,
  getDeviceInfo,
  getSystemInfo,
  isWizConnector,
  handleWizConnectorKeyName,
  getAllDeviceTags,
  removeDeviceTag,
  setDeviceTag,
  getNames,
  getRunIds,
  getConfigByKey,
  getLatestGraphData,
  setConfig,
  getDeviceData,
  exportDeviceData,
  addStartParams,
  getStartParams,
  getScreenNames,
  getService,
  deleteParamService,
  getScreenStatus,
  getConnectStatus,
  startService,
  stopService,
  getStandardDeviceInfo,
  addStandardDeviceInfo,
  deleteStandardDeviceInfo,
  getTestedWeightInfo,
  addTestedWeightInfo,
  deleteTestedWeightInfo,
  getEnvironmentParamsInfo,
  addEnvironmentParamsInfo,
  deleteEnvironmentParamsInfo,
  getPalletMassDifferenceInfo,
  addPalletMassDifferenceInfo,
  deletePalletMassDifferenceInfo,
  getTestedWeightUnitedInfo,
  addTestedWeightUnitedInfo,
  deleteTestedWeightUnitedInfo,
  updateCalculateResult,
  needUpdateCalculateResults,
  updateCalculateResults,
  uploadDataTemplate,
  getTemplate,
  getTime,
  setTime,
  updateTime,
  getUpgradeVersion,
  startUpgradeVersion,
  updateQueryConf,
  getDeviceDataDebugGraph,
  getDeviceGraph,
  deleteDataWithCondition,
  deleteDataWithIds,
};
