<template>
  <el-table
    class="my-table"
    :data="list"
    :border="true"
    :resizable="true"
    v-loading="loadingDbData"
    :key="listKey"
  >
    <el-table-column
      v-for="item in drawerTableInfo"
      :key="item"
      :prop="`${item.prop}`"
      :label="`${item.label}`"
      :sort-by="`${item.prop}`"
      align="start"
    >
      <template #header>
        <el-space>
          <div>
            {{ item.label }}
          </div>
          <el-tooltip
            v-if="item.tooltips"
            class="box-item"
            effect="dark"
            placement="top-start"
            :offset="20"
            raw-content
            :content="item.tooltips"
          >
            <el-icon><QuestionFilled /></el-icon>
          </el-tooltip>
        </el-space>
      </template>
      <template #default="scope">
        <div
          v-if="item.editDisabled || (!scope.row.isAdd && !scope.row.isEdit)"
        >
          {{ scope.row[item.prop] || (item.type === "实时" ? "实时" : "") }}
        </div>
        <div v-else>
          <el-input
            v-if="item.type === 'number'"
            v-model="scope.row[item.prop]"
            placeholder=""
            :controls="false"
            @change="numberChanged(scope.row, item)"
          />
          <el-input
            id="realTimeDisabledInput"
            v-else-if="
              item.type === '实时' && realTimeSelected !== realTimeEnabledValue
            "
            v-model="scope.row[item.prop]"
            placeholder="实时"
            :controls="false"
            @change="numberChanged(scope.row, item)"
            @blur="numberChanged(scope.row, item)"
          />
          <el-select
            v-else-if="
              item.type === '实时' && realTimeSelected === realTimeEnabledValue
            "
            filterable
            v-model="realTimeSelected"
            @change="realTimeSelectedChanged"
          >
            <el-option
              v-for="option in realTimeOptions"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            >
            </el-option>
          </el-select>
          <el-date-picker
            v-else-if="item.type === 'date'"
            v-model="scope.row[item.prop]"
            type="date"
            :placeholder="'选择' + item.label"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
          />
          <el-select
            v-else-if="item.type === 'select'"
            filterable
            v-model="scope.row[item.prop]"
          >
            <el-option
              v-for="option in item.options"
              :key="option"
              :label="option"
              :value="option"
            >
            </el-option>
          </el-select>
          <el-input v-else v-model="scope.row[item.prop]" placeholder="" />
        </div>
      </template>
    </el-table-column>
    <el-table-column label="操作" :key="itemKey" min-width="140">
      <template #default="scope">
        <el-button
          v-if="scope.row.isAdd"
          size="small"
          @click="handleAdd(scope.$index, scope.row)"
          >新增</el-button
        >
        <el-button
          v-if="scope.row.isEdit"
          size="small"
          @click="handleConfirmEdit(scope.$index, scope.row)"
          >确认修改</el-button
        >
        <el-button
          v-if="!scope.row.isAdd && !scope.row.isEdit"
          size="small"
          @click="handleEdit(scope.$index, scope.row)"
          >修改</el-button
        >
        <el-button
          v-if="!hideDelete && !scope.row.isAdd && !scope.row.isEdit"
          size="small"
          type="danger"
          @click="handleDelete(scope.$index, scope.row)"
          >删除</el-button
        >
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { ref } from "vue";
import { ElNotification, ElMessageBox } from "element-plus";
import { uuid } from "../util/uuid_util";
import { formatCompareData } from "../util/formats_util";

const listAdd = { isAdd: true };

export default {
  name: "EditDrawerTableItemInfo",
  props: {
    type: String,
    title: String,
    getDrawerTableItemInfo: Function,
    addDrawerTableItemInfo: Function,
    deleteDrawerTableItemInfo: Function,
    onDrawerTableItemInfoChanged: Function,
    uniqueKeys: Array,
    drawerTableInfo: Array,
    maxSize: {
      type: Number,
      default: -1,
    },
    hideDelete: {
      type: Boolean,
      default: false,
    },
    checkKeyValid: {
      type: Function,
      default: null,
    },
  },
  setup() {
    const list = ref([{ ...listAdd }]);
    const listKey = ref(new Date().getTime());
    const itemKey = ref(new Date().getTime());
    const loadingDbData = ref(false);
    const showFlag = ref(false);
    const direction = ref("ltr");
    const realTimeEnabledValue = 1;
    const realTimeDisabledValue = 2;
    const realTimeOptions = ref([
      { label: "实时", value: realTimeEnabledValue },
      { label: "手动输入", value: realTimeDisabledValue },
    ]);
    const realTimeSelected = ref(realTimeEnabledValue);

    const data = {
      showFlag,
      direction,
      list,
      loadingDbData,
      listKey,
      itemKey,
      realTimeEnabledValue,
      realTimeDisabledValue,
      realTimeOptions,
      realTimeSelected,
    };
    return data;
  },
  mounted() {
    Object.values(this.drawerTableInfo).forEach((value) => {
      if (value.defaultValue) {
        listAdd[value.prop] = value.defaultValue;
        Object.values(this.list).forEach((listValue) => {
          if (listValue.isAdd && !listValue[value.prop]) {
            listValue[value.prop] = value.defaultValue;
          }
        });
      }
      if (value.type === "实时") {
        Object.values(this.list).forEach((listValue) => {
          this.checkRealTimeSelected(value, listValue);
        });
      }
    });
    const numberKeys = [];
    Object.keys(this.drawerTableInfo || []).forEach((key) => {
      const item = this.drawerTableInfo[key];
      if (item.type == "number" || item.type == "实时") {
        numberKeys.push(item);
      }
    });
    this.getDrawerTableItemInfo(
      {},
      (data) => {
        console.log("getDrawerTableItemInfo data", this.type, data);
        if (data && data.data && data.data.code > 0) {
          this.list = data.data.data || [];
          if (numberKeys.length > 0) {
            Object.keys(this.list).forEach((key) => {
              Object.values(numberKeys).forEach((nKeyItem) => {
                this.list[key][nKeyItem.prop] = this.numberFormat(
                  this.list[key][nKeyItem.prop],
                  nKeyItem.precision
                );
              });
            });
          }
          if (this.maxSize <= 0 || this.list.length < this.maxSize) {
            this.list.push({ ...listAdd });
          }
          Object.values(this.drawerTableInfo).forEach((value) => {
            if (value.type === "实时") {
              Object.values(this.list).forEach((listValue) => {
                this.checkRealTimeSelected(value, listValue);
              });
            }
          });
        } else {
          ElNotification.error(this.type + "获取数据失败1！");
        }
      },
      (err) => {
        console.log("getDrawerTableItemInfo err", this.type, err);
        ElNotification.error(this.type + "获取数据失败2！");
      }
    );
  },
  methods: {
    realTimeSelectedChanged() {
      let focusRealTimeEnabledSelector =
        this.realTimeSelected === this.realTimeDisabledValue;
      console.log("realTimeDisabledInput focus", focusRealTimeEnabledSelector);
      if (focusRealTimeEnabledSelector) {
        this.$nextTick(() => {
          document.getElementById("realTimeDisabledInput").focus();
        });
      }
    },
    checkRealTimeSelected(item, value) {
      if (!item || !value) {
        return;
      }
      if (item.type === "实时") {
        let realTimeSelected = this.realTimeEnabledValue;
        if (value[item.prop]) {
          realTimeSelected = this.realTimeDisabledValue;
        }
        this.realTimeSelected = realTimeSelected;
        console.log("realTimeSelected", this.realTimeSelected);
      }
    },
    numberChanged(row, item) {
      console.log("numberChanged", row, item);
      row[item.prop] = this.numberFormat(row[item.prop], item.precision);
      this.checkRealTimeSelected(item, row);
    },
    numberFormat(value, precision) {
      if (!value) {
        return undefined;
      }
      value = (value + "").replace(/[^\\.0-9]/g, "");
      const index = value.indexOf(".");
      if (index < 0) {
        return value;
      }
      precision = precision || 2;
      if (value.substring(index + 1).length <= precision) {
        return value;
      } else {
        return value.substring(0, index + 1 + precision);
      }
    },
    handleAdd(index, row) {
      console.log("handleAdd", index, row, this.type);
      let dataIsEmptyTips = [];
      Object.values(this.uniqueKeys).forEach((key) => {
        if (!formatCompareData(row[key])) {
          dataIsEmptyTips.push(
            (this.drawerTableInfo.find((item) => item.prop === key) || {}).label
          );
        }
      });
      if (dataIsEmptyTips.length > 0) {
        ElNotification.error(
          this.type +
            "必填项不能为空，为空的项目有：" +
            dataIsEmptyTips.join("、")
        );
        return;
      }
      let duplicatedUniqueKey = false;
      let duplicatedUniqueStr = "";
      let checkKeyValidTips = "";
      Object.values(this.uniqueKeys).forEach((key) => {
        duplicatedUniqueStr += formatCompareData(row[key]);
      });
      Object.keys(this.list).forEach((key) => {
        if (key != index) {
          let duplicatedUniqueStrItem = "";
          Object.values(this.uniqueKeys).forEach((itemKey) => {
            duplicatedUniqueStrItem += formatCompareData(
              this.list[key][itemKey]
            );
            if (!checkKeyValidTips && this.checkKeyValid) {
              checkKeyValidTips = this.checkKeyValid(
                itemKey,
                this.list[key],
                row
              );
            }
          });
          if (duplicatedUniqueStrItem == duplicatedUniqueStr) {
            duplicatedUniqueKey = true;
          }
        }
      });
      if (checkKeyValidTips) {
        ElNotification.error(this.type + checkKeyValidTips);
        return;
      }
      if (duplicatedUniqueKey) {
        let duplicatedUniqueKeyTips = [];
        Object.values(this.uniqueKeys).forEach((key) => {
          let label = (
            this.drawerTableInfo.find((item) => item.prop === key) || {}
          ).label;
          duplicatedUniqueKeyTips.push(`‘${label}’`);
        });
        ElNotification.error(
          this.type +
            "已经有重复的数据了，请修改 " +
            duplicatedUniqueKeyTips.join(" 或 ")
        );
        return;
      }
      row.isAdd = false;
      if (this.maxSize <= 0 || this.list.length < this.maxSize) {
        this.list.push({ ...listAdd });
      }
      this.updateTable();
      this.uploadItem(this.list[index]);
    },

    handleConfirmEdit(index, row) {
      console.log("handleConfirmEdit", index, row, this.type);
      row.isEdit = false;
      Object.values(this.drawerTableInfo).forEach((value) => {
        if (value.type === "实时") {
          this.checkRealTimeSelected(value, row);
        }
      });
      this.updateItem();
      this.uploadItem(this.list[index]);
    },

    handleEdit(index, row) {
      console.log("handleEdit", index, row, this.type);
      row.isEdit = true;
      this.updateItem();
    },

    handleDelete(index, row) {
      console.log("handleDelete", index, row, this.type);
      ElMessageBox.confirm("确认要删除吗？")
        .then(() => {
          const item = this.list.splice(index, 1)[0];
          this.updateTable();
          this.deleteItem(item);
        })
        .catch(() => {
          // catch error
        });
    },

    updateTable() {
      this.listKey = new Date().getTime();
    },

    updateItem() {
      this.itemKey = new Date().getTime();
    },

    uploadItem(item) {
      console.log("uploadItem", item, this.type);
      if (!item.internal_id) {
        item.internal_id = uuid();
      }
      this.addDrawerTableItemInfo(
        {
          ...item,
        },
        (data) => {
          console.log("addDrawerTableItemInfo data", this.type, data);
          if (data && data.data && data.data.code > 0) {
            // ElNotification.error(this.type + "更新成功");
            this.onDrawerTableItemInfoChanged &&
              this.onDrawerTableItemInfoChanged();
          } else {
            ElNotification.error(this.type + "更新失败1！");
          }
        },
        (err) => {
          console.log("addDrawerTableItemInfo err", this.type, err);
          ElNotification.error(this.type + "更新失败2！");
        }
      );
    },

    deleteItem(item) {
      console.log("deleteItem", item, this.type);
      this.deleteDrawerTableItemInfo(
        {
          ...item,
        },
        (data) => {
          console.log("deleteDrawerTableItemInfo data", this.type, data);
          if (data && data.data && data.data.code > 0) {
            // ElNotification.error(this.type + "删除成功");
            this.onDrawerTableItemInfoChanged &&
              this.onDrawerTableItemInfoChanged();
          } else {
            ElNotification.error(this.type + "删除失败1！");
          }
        },
        (err) => {
          console.log("deleteDrawerTableItemInfo err", this.type, err);
          ElNotification.error(this.type + "删除失败2！");
        }
      );
    },
  },
};
</script>
<style scoped>
.my-table {
  width: 100%;
  margin-bottom: 40px;
}

.el-table {
  --el-table-header-text-color: #ffffff !important;
  --el-table-text-color: #101010 !important;
  --el-table-header-bg-color: #41419f !important;
}

.el-table :deep(thead.is-group th.el-table__cell) {
  background-color: var(--el-table-header-bg-color) !important;
}

.el-table :deep(.el-date-editor.el-input) {
  width: 120px !important;
}
</style>
