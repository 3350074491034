const formatCompareData = (value) => {
  if (!value) {
    return value;
  }
  return (value + "")
    .replaceAll(" ", "")
    .replaceAll("\r", "")
    .replaceAll("\n", "")
    .replaceAll("\t", "");
};

const getWeightUnitValueByMg = (value) => {
  if (!value) {
    return 0;
  }
  const valueRaw = formatCompareData(value).toLowerCase();
  if (valueRaw.indexOf("kg") > 0) {
    return (parseFloat(valueRaw) || 0) * 1000000;
  } else if (valueRaw.indexOf("mg") > 0) {
    return (parseFloat(valueRaw) || 0) * 1;
  } else if (valueRaw.indexOf("g") > 0) {
    return (parseFloat(valueRaw) || 0) * 1000;
  }
  return 0;
};

const getWeightScopeRange = (value) => {
  const valueRaw = (value + "").split("-");
  if (valueRaw.length === 2) {
    return [
      getWeightUnitValueByMg(valueRaw[0]),
      getWeightUnitValueByMg(valueRaw[1]),
    ];
  }
  return [0, 0];
};

export { formatCompareData, getWeightUnitValueByMg, getWeightScopeRange };
