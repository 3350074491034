<template>
  <el-space class="container" direction="vertical" alignment="start">
    <el-space direction="horizontal">
      <!-- <span class="title title-bold"></span> -->
      <span class="title title-divider">WizConnect</span>
    </el-space>
    <span class="desc">{{ "Version: " + version }}</span>

    <div class="desc-divider">
      <span class="desc">本机设备号：{{ this.systemInfo.code || "" }}</span>
    </div>
    <span class="desc">激活码：{{ this.systemInfo.license_code || "" }}</span>
    <span class="desc"
      >激活时间：{{ this.systemInfo.active_time_str || "" }}</span
    >
    <span class="desc"
      >有效期至：{{ this.systemInfo.expire_time_str || "" }}</span
    >
    <el-space direction="horizontal">
      <span class="desc">激活版本：专业版</span>
      <el-tooltip content="专业版：支持所有功能；">
        <el-icon class="desc-icon">
          <QuestionFilled />
        </el-icon>
      </el-tooltip>
    </el-space>
    <el-button @click="updateActive">更新激活码</el-button>

    <div class="desc-divider">
      <span class="desc">如需帮助，请发送邮件至 support@wizconnect.cn</span>
    </div>

    <div class="desc-divider">
      <span class="desc">上海芳菲兴程信息科技有限公司</span>
    </div>
    <span class="desc">www.wizconnect.cn</span>
  </el-space>
</template>

<script>
import { getSystemInfo } from "../api/device";
import { formatDate } from "../util/date_util";
import { inject, toRefs } from "vue";
const version = localStorage.getItem("vueVersion");

export default {
  name: "SoftwareAbout",
  props: {
    msg: String,
  },
  components: {},
  methods: {
    updateActive() {
      this.$router.push("/active");
    },
  },
  mounted() {
    getSystemInfo(
      {},
      (res) => {
        console.log("get system info", res);
        if (res && res.data && res.data.data && res.data.data.open_modules) {
          Object.keys(res.data.data.open_modules).forEach((key) => {
            const item = res.data.data.open_modules[key];
            if (
              item.expire_time &&
              item.expire_time > (this.systemInfo.expire_time || 0)
            ) {
              item.key = key;
              item.active_time_str =
                item.active_time &&
                formatDate(item.active_time * 1000) &&
                formatDate(item.active_time * 1000).split(" ")[0];
              item.expire_time_str =
                item.expire_time &&
                formatDate(item.expire_time * 1000) &&
                formatDate(item.expire_time * 1000).split(" ")[0];
              this.setSystemInfo(item);
            }
          });
        }
      },
      () => {}
    );
  },
  setup() {
    const menuData = inject("menuData");

    const data = { ...toRefs(menuData), version };
    return data;
  },
};
</script>

<style scoped>
.container {
  margin-top: 30px;
  width: 100%;
}

.title {
  font-size: medium;
  color: #101010;
}

.title-bold {
  font-weight: bold;
}

.title-divider {
  margin-left: 2px;
}

.desc {
  font-size: small;
  color: #101010;
}

.desc-divider {
  margin-top: 22px;
}

.desc-icon {
  font-size: 16px;
}
</style>
