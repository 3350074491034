import { formatDateFromNs } from "./date_util";

const getClipboardInfoObj = (clipboardInfo) => {
  if (!clipboardInfo) {
    return null;
  }
  var raw = clipboardInfo.replaceAll("\n", "").replaceAll(" ", "");
  var infoTmp = handleInfoObj([], raw);
  if (!infoTmp || infoTmp.length == 0) {
    return null;
  }
  return infoTmp;
};

const handleInfoObj = (infoTmp, raw) => {
  if (!raw) {
    return infoTmp;
  }
  if (raw[0] == "{") {
    var index = raw.indexOf("}");
    if (index <= 1) {
      return null;
    }
    var value = raw.substring(1, index);
    if (
      value.indexOf("{") >= 0 ||
      value.indexOf("}") >= 0 ||
      value.indexOf("<") >= 0 ||
      value.indexOf(">") >= 0
    ) {
      return null;
    }
    let itemKey = value;
    let precision = "";
    if (itemKey.split(".").length === 2) {
      precision = itemKey.split(".")[1].replaceAll("f", "");
      itemKey = itemKey.split(".")[0];
    }
    if (!outputTableVariableNames.includes(itemKey)) {
      return null;
    }
    infoTmp.push({
      itemKey,
      precision,
    });
    raw = raw.substring(index + 1);
    infoTmp = handleInfoObj(infoTmp, raw);
    if (!infoTmp) {
      return null;
    }
  } else if (raw[0] == "<") {
    let index = raw.indexOf(">");
    if (index <= 1) {
      return null;
    }
    let value = raw.substring(1, index);
    if (
      value.indexOf("{") >= 0 ||
      value.indexOf("}") >= 0 ||
      value.indexOf("<") >= 0 ||
      value.indexOf(">") >= 0
    ) {
      return null;
    }
    if (!outputTableKeyNames.includes(value)) {
      return null;
    }
    infoTmp.push(value);
    raw = raw.substring(index + 1);
    infoTmp = handleInfoObj(infoTmp, raw);
    if (!infoTmp) {
      return null;
    }
  } else {
    return null;
  }
  return infoTmp;
};

const precisionValues = [
  {
    name: "0",
  },
  {
    name: "1",
  },
  {
    name: "2",
  },
  {
    name: "3",
  },
  {
    name: "4",
  },
  {
    name: "5",
  },
  {
    name: "6",
  },
  {
    name: "7",
  },
  {
    name: "8",
  },
  {
    name: "9",
  },
];

const outputTableVariableParams = [
  {
    tips: "设备名称",
    name: "设备",
    value: "",
    key: "device_name",
  },
  {
    tips: "实验名称",
    name: "实验",
    value: "",
    key: "ccr_file_name",
  },
  {
    tips: "比较仪名称",
    name: "比较仪",
    value: "",
    key: "comparator",
  },
  {
    tips: "日期时间",
    name: "日期时间",
    value: "",
    key: "ccr_date",
  },
  {
    tips: "砝码标称值",
    name: "标称值",
    value: "",
    key: "nominalA",
    symbol: "m_0",
    unit: "g",
  },
  {
    tips: "标准砝码位置",
    name: "位置A",
    value: "",
    key: "positionA",
  },
  {
    tips: "标准砝码名称",
    name: "砝码A",
    value: "",
    key: "nameA",
  },
  {
    tips: "被测砝码位置",
    name: "位置B",
    value: "",
    key: "positionB",
  },
  {
    tips: "被测砝码名称",
    name: "砝码B",
    value: "",
    key: "nameB",
  },
  {
    tips: "循环方法",
    name: "方法",
    value: "",
    key: "method",
  },
  {
    tips: "循环次数",
    name: "循环次数",
    value: "",
    key: "cycles",
    symbol: "n",
  },
  {
    tips: "托盘B-托盘A重量差值",
    name: "托盘差重",
    value: "",
    key: "delta_pan_mg",
    isNumber: true,
    precision: "6",
    symbol: "\\Delta I_{\\rm tare}",
    unit: "mg",
  },
  {
    tips: "托盘重量差值的标准不确定度",
    name: "u_托盘差重",
    value: "",
    key: "u_delta_pan_mg",
    isNumber: true,
    precision: "6",
    symbol: "u(\\Delta I_{\\rm tare})",
    unit: "mg",
  },
  {
    tips: "比较仪的重复性",
    name: "比较仪重复性",
    value: "",
    key: "repeatability_mg",
    isNumber: true,
    precision: "6",
    symbol: "s_{\\rm rep}",
    unit: "mg",
  },
  {
    tips: "比较仪的系统性标准不确定度",
    name: "u_比较仪_sys",
    value: "",
    key: "u_comparator_sys_mg",
    isNumber: true,
    precision: "6",
    symbol: "u_{\\rm ba}",
    unit: "mg",
  },
  {
    tips: "标准砝码约定质量值",
    name: "约定质量ref",
    value: "",
    key: "mass_ref_g",
    isNumber: true,
    precision: "6",
    symbol: "m_{\\rm cr}",
    unit: "g",
  },
  {
    tips: "标准砝码约定质量值的标准不确定度",
    name: "u_约定质量ref",
    value: "",
    key: "u_mass_ref_mg",
    isNumber: true,
    precision: "6",
    symbol: "u(m_{\\rm cr})",
    unit: "mg",
  },
  {
    tips: "标准砝码密度",
    name: "密度ref",
    value: "",
    key: "density_ref_kg",
    isNumber: true,
    precision: "6",
    symbol: "\\rho_{\\rm r}",
    unit: "kg/m³",
  },
  {
    tips: "标准砝码密度的标准不确定度",
    name: "u_密度ref",
    value: "",
    key: "u_density_ref_kg",
    isNumber: true,
    precision: "6",
    symbol: "u(\\rho_{\\rm r})",
    unit: "kg/m³",
  },
  {
    tips: "被测砝码密度",
    name: "密度test",
    value: "",
    key: "density_test_kg",
    isNumber: true,
    precision: "6",
    symbol: "\\rho_{\\rm t}",
    unit: "kg/m³",
  },
  {
    tips: "被测砝码密度的标准不确定度",
    name: "u_密度test",
    value: "",
    key: "u_density_test_kg",
    isNumber: true,
    precision: "6",
    symbol: "u(\\rho_{\\rm t})",
    unit: "kg/m³",
  },
  {
    tips: "空气密度",
    name: "密度air",
    value: "",
    key: "density_air_kg",
    isNumber: true,
    precision: "3",
    symbol: "\\rho_{\\rm a}",
    unit: "kg/m³",
  },
  {
    tips: "空气密度的标准不确定度",
    name: "u_密度air",
    value: "",
    key: "u_density_air_kg",
    isNumber: true,
    precision: "6",
    symbol: "u(\\rho_{\\rm a})",
    unit: "kg/m³",
  },
  {
    tips: "循环序列",
    name: "循环序列",
    value: "",
    key: "cycle",
  },
  {
    tips: "方法序列",
    name: "方法序列",
    value: "",
    key: "seq",
  },
  {
    tips: "重量值",
    name: "重量值",
    value: "",
    key: "num",
    isNumber: false,
    precision: "6",
    symbol: "I",
    unit: "*",
  },
  {
    tips: "循环结果",
    name: "循环结果",
    value: "",
    key: "diff",
    isNumber: true,
    precision: "6",
    symbol: "\\Delta I_i",
    unit: "mg",
  },
  {
    tips: "平均结果",
    name: "平均结果",
    value: "",
    key: "mean",
    isNumber: true,
    precision: "5",
    symbol: "mean",
    unit: "mg",
  },
  {
    tips: "称量结果平均差值",
    name: "称量结果",
    value: "",
    key: "delta_indication_mg",
    isNumber: true,
    precision: "5",
    symbol: "\\overline{\\Delta I}",
    unit: "mg",
  },
  {
    tips: "称量结果差值标准偏差",
    name: "称量结果sd",
    value: "",
    key: "sd_delta_indication_mg",
    isNumber: true,
    precision: "6",
    symbol: "s(\\Delta I)",
    unit: "mg",
  },
  {
    tips: "空气浮力修正值",
    name: "空气浮力修正",
    value: "",
    key: "boyancy_corr_mg",
    isNumber: true,
    precision: "6",
    symbol: "m_{\\rm cr}C",
    unit: "mg",
  },
  {
    tips: "约定质量平均差值",
    name: "约定质量差值",
    value: "",
    key: "delta_mass_mg",
    isNumber: true,
    precision: "6",
    symbol: "\\overline{\\Delta m_{\\rm c}}",
    unit: "mg",
  },
  {
    tips: "被测砝码约定质量值",
    name: "约定质量test",
    value: "",
    key: "mass_test_g",
    isNumber: true,
    precision: "7",
    symbol: "m_{\\rm ct}",
    unit: "g",
  },
  {
    tips: "被测砝码约定质量修正值",
    name: "约定质量修正test",
    value: "",
    key: "mass_corr_test_mg",
    isNumber: true,
    precision: "4",
    symbol: "\\delta m_{\\rm ct}",
    unit: "mg",
  },
  {
    tips: "称量过程A类标准不确定度",
    name: "u_称量过程",
    value: "",
    key: "u_weighing_mg",
    isNumber: true,
    precision: "6",
    symbol: "u_{\\rm w}(\\overline{\\Delta m_{\\rm c}})",
    unit: "mg",
  },
  {
    tips: "空气浮力修正的标准不确定度",
    name: "u_空气浮力修正",
    value: "",
    key: "u_boyancy_corr_mg",
    isNumber: true,
    precision: "6",
    symbol: "u_{\\rm b}",
    unit: "mg",
  },
  {
    tips: "合成标准不确定度",
    name: "合成不确定度",
    value: "",
    key: "cu_mass_test_mg",
    isNumber: true,
    precision: "5",
    symbol: "u_{\\rm c}(m_{\\rm ct})",
    unit: "mg",
  },
  {
    tips: "扩展不确定度(k=2)",
    name: "扩展不确定度",
    value: "",
    key: "eu_mass_test_mg",
    isNumber: true,
    precision: "4",
    symbol: "U(m_{\\rm ct})",
    unit: "mg",
  },
];

const tabKeyValue = "\u0009";
const enterKeyValue = "\u000A";
const emptyDataKeyValue = "";
const emptySpanValue = "!@@@@####@@@@!@#@!54c8c19f-16f3-41a8-83fe-2e65a9904d2b";

const outputTableKeyParams = [
  {
    name: "tab",
    value: tabKeyValue,
  },
  {
    name: "enter",
    value: enterKeyValue,
  },
];

const outputTableKeyNames = [];
Object.values(outputTableKeyParams).forEach((param) => {
  outputTableKeyNames.push(param.name);
});

const outputTableVariableNames = [];
Object.values(outputTableVariableParams).forEach((param) => {
  outputTableVariableNames.push(param.name);
});

const getOutputTableKeyValue = (name) => {
  return (
    (outputTableKeyParams.find((item) => item.name === name) || {}).value || ""
  );
};

const getOutputTableVariableItem = (name) => {
  return outputTableVariableParams.find((item) => item.name === name) || {};
};

const keyDates = ["ccr_date"];
const keyCycle = ["cycle"];

const copyKeyDirect = ["device_name"];

const copyKeyRaw = [
  "ccr_file_name",
  "comparator",
  "ccr_date",
  "nominalA",
  "positionA",
  "nameA",
  "positionB",
  "nameB",
  "method",
  "cycles",
];

const copyKeyRawCalculateResult = [
  "delta_pan_mg",
  "mass_ref_g",
  "delta_indication_mg",
  "sd_delta_indication_mg",
  "boyancy_corr_mg",
  "delta_mass_mg",
  "mass_test_g",
  "mass_corr_test_mg",
];

const copyKeyRawUncertainCalculate = [
  "u_delta_pan_mg",
  "repeatability_mg",
  "u_comparator_sys_mg",
  "u_mass_ref_mg",
  "density_ref_kg",
  "u_density_ref_kg",
  "density_test_kg",
  "u_density_test_kg",
  "density_air_kg",
  "u_density_air_kg",
  "u_weighing_mg",
  "u_boyancy_corr_mg",
  "cu_mass_test_mg",
  "eu_mass_test_mg",
];

const copyKeyRawMeasureData = ["cycle", "seq", "num", "diff", "mean"];

const level1RowSpanKeys = ["cycle", "diff"];

const level2RowSpanKeys = ["seq", "num"];

const type_variable = "variable";
const type_key = "key";

const getClipboardCopyItemText = (clipboardInfoObj, item, withPrecision) => {
  const measureDataList =
    (item &&
      item.ccr_raw_info &&
      item.ccr_raw_info.measure_data &&
      item.ccr_raw_info.measure_data.list) ||
    [];
  let maxRowSpan = 0;
  let level1RowSpan = 0;
  let level2RowSpan = 0;
  const infoObjs = [];
  Object.values(clipboardInfoObj).forEach((item) => {
    const infoObj = {};
    if (typeof item === "object") {
      infoObj.key = getOutputTableVariableItem(item.itemKey).key;
      infoObj.precision = item.precision;
      infoObj.type = type_variable;
    } else {
      infoObj.key = item;
      infoObj.type = type_key;
    }
    if (level1RowSpan === 0 && level1RowSpanKeys.includes(infoObj.key)) {
      let l1rs = 0;
      Object.values(measureDataList).forEach((dataItem) => {
        if (level1RowSpan === 0) {
          if (dataItem[infoObj.key]) {
            if (l1rs === 0) {
              l1rs += 1;
            } else {
              level1RowSpan = l1rs;
            }
          } else {
            l1rs += 1;
          }
        }
      });
    }
    if (level2RowSpan === 0 && level2RowSpanKeys.includes(infoObj.key)) {
      level2RowSpan = 1;
    }
    infoObjs.push(infoObj);
  });
  if (level2RowSpan > 0) {
    maxRowSpan = measureDataList.length;
  } else if (level1RowSpan > 0) {
    maxRowSpan = level1RowSpan;
    level1RowSpan = 1;
  }
  if (maxRowSpan > 0) {
    Object.values(infoObjs).forEach((infoObj) => {
      if (level1RowSpanKeys.includes(infoObj.key)) {
        infoObj.rowspan = level1RowSpan;
      } else if (level2RowSpanKeys.includes(infoObj.key)) {
        infoObj.rowspan = level2RowSpan;
      } else {
        infoObj.rowspan = maxRowSpan;
      }
      infoObj.maxRowSpan = maxRowSpan;
    });
  }
  Object.values(infoObjs).forEach((infoObj) => {
    infoObj.list = [];
    if (infoObj.type === type_variable) {
      let itemKey = infoObj.key;
      let precision = infoObj.precision;
      if (itemKey) {
        if (copyKeyRawMeasureData.includes(itemKey)) {
          Object.values(measureDataList).forEach((measureItem) => {
            let value = measureItem[itemKey];
            if (keyCycle.includes(itemKey) && value !== undefined) {
              value = parseInt(value) + 1;
            }
            if (value !== undefined) {
              if (value && withPrecision && precision) {
                value = formatDataPrecision(value, precision);
              }
              infoObj.list.push(value);
            }
          });
        } else {
          let value = "";
          if (copyKeyDirect.includes(itemKey)) {
            value = item[itemKey];
          } else if (copyKeyRaw.includes(itemKey)) {
            if (item.ccr_raw_info) {
              const valueItem = item.ccr_raw_info;
              value = valueItem[itemKey];
              if (keyDates.includes(itemKey) && value) {
                value = formatDateFromNs(value);
              }
            }
          } else if (copyKeyRawCalculateResult.includes(itemKey)) {
            if (item.ccr_raw_info && item.ccr_raw_info.calculate_result) {
              const valueItem = item.ccr_raw_info.calculate_result;
              value = valueItem[itemKey];
            }
          } else if (copyKeyRawUncertainCalculate.includes(itemKey)) {
            if (item.ccr_raw_info && item.ccr_raw_info.uncertain_calculate) {
              const valueItem = item.ccr_raw_info.uncertain_calculate;
              value = valueItem[itemKey];
            }
          }
          if (value && withPrecision && precision) {
            value = formatDataPrecision(value, precision);
          }
          if (!value) {
            value = emptyDataKeyValue;
          }
          infoObj.list.push(value);
        }
      } else {
        infoObj.list.push(emptyDataKeyValue);
      }
    } else if (infoObj.type === type_key) {
      infoObj.list.push(getOutputTableKeyValue(infoObj.key));
    } else {
      infoObj.list.push(emptyDataKeyValue);
    }
  });
  return infoObjs;
};

const getClipboardCopyText = (clipboardInfoObj, list) => {
  let text = "";
  let html = "";
  if (
    !clipboardInfoObj ||
    clipboardInfoObj.length == 0 ||
    !list ||
    list.length == 0
  ) {
    return { text, html };
  }
  html += '<table border="1"><colgroup><col><col><col></colgroup><tbody>';
  Object.values(list).forEach((item) => {
    const infoObjs = getClipboardCopyItemText(clipboardInfoObj, item, true);
    if (infoObjs.length > 0) {
      const rows = [];
      const maxRowSpan = infoObjs[0].maxRowSpan || 1;
      for (let index = 0; index < maxRowSpan; index++) {
        rows.push([]);
      }
      Object.values(infoObjs).forEach((infoObj) => {
        const list = infoObj.list || [];
        const rowspan = infoObj.rowspan || 1;
        const usedKey = [];
        Object.keys(rows).forEach((rowKey) => {
          if (outputTableKeyNames.includes(infoObj.key)) {
            rows[rowKey].push(list.length > 0 ? list[0] : emptySpanValue);
          } else {
            const listKey = Math.floor(parseInt(rowKey) / rowspan);
            if (listKey < list.length && !usedKey.includes(listKey)) {
              usedKey.push(listKey);
              rows[rowKey].push(list[listKey]);
            } else {
              rows[rowKey].push(emptySpanValue);
            }
          }
        });
      });
      // add html
      html += "<tr>";
      let currentTd = "";
      Object.values(rows).forEach((row) => {
        // add text
        text += row.join("");

        // add html
        Object.keys(row).forEach((rowKey) => {
          if (row[rowKey] !== emptySpanValue) {
            const infoObj = infoObjs[rowKey];
            if (tabKeyValue === row[rowKey]) {
              if (currentTd) {
                html += currentTd + "</td>";
              } else {
                let preValue = "";
                const preKey = parseInt(rowKey) - 1;
                if (preKey >= 0) {
                  preValue = row[preKey];
                }
                if (preValue !== emptySpanValue) {
                  html += "<td>" + "</td>";
                }
              }
              currentTd = "";
            } else if (enterKeyValue === row[rowKey]) {
              if (currentTd) {
                html += currentTd + "</td>" + "</tr>" + "<tr>";
              }
              currentTd = "";
            } else {
              if (!currentTd) {
                const rowspan = infoObj.rowspan || 1;
                if (rowspan == 1) {
                  currentTd += "<td>";
                } else {
                  currentTd += `<td rowspan="${rowspan}">`;
                }
              }
              currentTd += row[rowKey];
            }
          }
        });
      });
      if (currentTd) {
        currentTd += "</td>";
      }
      html += currentTd + "</tr>";
      const removeEnd = "<tr></tr>";
      while (html.endsWith(removeEnd)) {
        html = html.substring(0, html.length - removeEnd.length);
      }
    }
  });
  html += "</tbody></table>";
  if (!text) {
    html = "";
  } else {
    text = text.replaceAll(emptySpanValue, "");
  }
  return { text, html };
};

const getExportDataInfo = (list) => {
  const data_list = [];
  const data_headers = [];
  const clipboardInfoObj = [];
  Object.values(outputTableVariableParams).forEach((value) => {
    clipboardInfoObj.push({
      itemKey: value.name,
      precision: value.precision || "",
    });
  });
  Object.values(list).forEach((item) => {
    const infoObjs = getClipboardCopyItemText(clipboardInfoObj, item, false);
    if (infoObjs.length > 0) {
      const rows = [];
      const maxRowSpan = infoObjs[0].maxRowSpan || 1;
      for (let index = 0; index < maxRowSpan; index++) {
        rows.push([]);
      }
      Object.values(infoObjs).forEach((infoObj) => {
        data_headers.push(infoObj.key);
        const list = infoObj.list || [];
        const rowspan = infoObj.rowspan || 1;
        const usedKey = [];
        Object.keys(rows).forEach((rowKey) => {
          if (outputTableKeyNames.includes(infoObj.key)) {
            rows[rowKey].push(list.length > 0 ? list[0] : emptySpanValue);
          } else {
            const listKey = Math.floor(parseInt(rowKey) / rowspan);
            if (listKey < list.length && !usedKey.includes(listKey)) {
              usedKey.push(listKey);
              rows[rowKey].push(list[listKey]);
            } else {
              rows[rowKey].push(emptySpanValue);
            }
          }
        });
      });
      data_list.push(...rows);
    }
  });
  return {
    headers: data_headers,
    list: data_list,
  };
};

const getOutputTableData = (item) => {
  const data = { ...(item || {}) };
  Object.values(outputTableVariableParams).forEach((value) => {
    if (copyKeyDirect.includes(value.key)) {
      if (value.isNumber && item[value.key]) {
        data[value.key] = formatDataPrecision(item[value.key], value.precision);
      } else {
        data[value.key] = item[value.key];
      }
    } else if (copyKeyRaw.includes(value.key) && item.ccr_raw_info) {
      if (value.isNumber && item.ccr_raw_info[value.key]) {
        data[value.key] = formatDataPrecision(
          item.ccr_raw_info[value.key],
          value.precision
        );
      } else {
        data[value.key] = item.ccr_raw_info[value.key];
      }
      if (keyDates.includes(value.key) && data[value.key]) {
        data[value.key] = formatDateFromNs(data[value.key]);
      }
    } else if (
      copyKeyRawCalculateResult.includes(value.key) &&
      item.ccr_raw_info &&
      item.ccr_raw_info.calculate_result
    ) {
      if (value.isNumber && item.ccr_raw_info.calculate_result[value.key]) {
        data[value.key] = formatDataPrecision(
          item.ccr_raw_info.calculate_result[value.key],
          value.precision
        );
      } else {
        data[value.key] = item.ccr_raw_info.calculate_result[value.key];
      }
    } else if (
      copyKeyRawMeasureData.includes(value.key) &&
      item.ccr_raw_info &&
      item.ccr_raw_info.measure_data &&
      item.ccr_raw_info.measure_data.list &&
      item.ccr_raw_info.measure_data.list.length > 0
    ) {
      if (value.isNumber && item.ccr_raw_info.measure_data.list[0][value.key]) {
        data[value.key] = formatDataPrecision(
          item.ccr_raw_info.measure_data.list[0][value.key],
          value.precision
        );
      } else {
        data[value.key] = item.ccr_raw_info.measure_data.list[0][value.key];
        if (keyCycle.includes(value.key) && data[value.key] !== undefined) {
          data[value.key] = parseInt(data[value.key]) + 1;
        }
      }
    } else if (
      copyKeyRawUncertainCalculate.includes(value.key) &&
      item.ccr_raw_info &&
      item.ccr_raw_info.uncertain_calculate
    ) {
      if (value.isNumber && item.ccr_raw_info.uncertain_calculate[value.key]) {
        data[value.key] = formatDataPrecision(
          item.ccr_raw_info.uncertain_calculate[value.key],
          value.precision
        );
      } else {
        data[value.key] = item.ccr_raw_info.uncertain_calculate[value.key];
      }
    }
  });
  return data;
};

const precisionDefault = 6;

const formatDataPrecision = (data, precision) => {
  if (!data) {
    return data;
  }
  if (!precision && precision != 0) {
    precision = precisionDefault;
  } else {
    precision = parseFloat(precision);
  }
  let tmp = parseFloat(data);
  let value = parseFloat(tmp.toFixed(precision));
  if (value === 0 && tmp !== 0) {
    tmp = tmp + "";
    let tmpIndex = 0;
    for (tmpIndex = 0; tmpIndex < tmp.length; tmpIndex += 1) {
      if (tmp[tmpIndex] != "0" && tmp[tmpIndex] != ".") {
        break;
      }
    }
    tmpIndex = tmpIndex - 1;
    if (tmpIndex < tmp.length && tmpIndex > precision) {
      value = parseFloat(parseFloat(data).toFixed(tmpIndex));
    }
  }
  if (!value) {
    value = data;
  }
  return value;
};

const getPrecisionByKey = (key) => {
  return (
    (outputTableVariableParams.find((item) => item.key === key) || {})
      .precision || precisionDefault
  );
};

/**
 * getPositionApiObj 是获取输入框（textarea、input）的光标焦点位置的对象，里面包含各种api，其中getInputPositon方法会返回 光标的位置坐标
 */
const getFocusPosition = {
  /**
   * 主方法
   * 获取输入光标在页面中的坐标
   * @param {HTMLElement} 输入框元素
   * @return {Object} 返回left和top,bottom
   */
  getInputPositon: function (elem) {
    if (document.selection) {
      //IE Support
      elem.focus();
      var Sel = document.selection.createRange();
      return {
        left: Sel.boundingLeft,
        top: Sel.boundingTop,
        bottom: Sel.boundingTop + Sel.boundingHeight,
      };
    } else {
      // 非IE Support
      var that = this;
      var cloneDiv = "{$clone_div}",
        cloneLeft = "{$cloneLeft}",
        cloneFocus = "{$cloneFocus}";
      // cloneRight = "{$cloneRight}";
      var none = '<span style="white-space:pre-wrap;"> </span>';
      var div = elem[cloneDiv] || document.createElement("div"), //clone的div元素
        focus = elem[cloneFocus] || document.createElement("span"); //clone的span元素
      var text = elem[cloneLeft] || document.createElement("span"); //clone的span元素
      // var offset = that._offset(elem),
      that._offset(elem);
      var index = this._getFocus(elem), //获取到textarea或input的光标所在的索引
        focusOffset = { left: 0, top: 0 };

      if (!elem[cloneDiv]) {
        elem[cloneDiv] = div;
        elem[cloneFocus] = focus;
        elem[cloneLeft] = text;
        div.appendChild(text);
        div.appendChild(focus);
        document.body.appendChild(div);
        focus.innerHTML = "|";
        focus.style.cssText =
          "display:inline-block;width:0px;overflow:hidden;z-index:-100;word-wrap:break-word;word-break:break-all;";
        div.className = this._cloneStyle(elem);
        div.style.cssText =
          "visibility:hidden;display:inline-block;position:absolute;z-index:-100;word-wrap:break-word;word-break:break-all;overflow:hidden;";
      }
      div.style.left = this._offset(elem).left + "px";
      div.style.top = this._offset(elem).top + "px";
      var strTmp = elem.value
        .substring(0, index)
        .replace(/</g, "<")
        .replace(/>/g, ">")
        .replace(/\n/g, "<br/>")
        .replace(/\s/g, none);
      text.innerHTML = strTmp;

      focus.style.display = "inline-block";
      try {
        focusOffset = this._offset(focus);
      } catch (e) {
        //
      }
      focus.style.display = "none";
      return {
        left: focusOffset.left,
        top: focusOffset.top,
        bottom: focusOffset.bottom,
      };
    }
  },

  /**
   *获取光标在文本域或输入框的光标所在的位置(这里的位置是光标在第几个字符串的索引)对获取光标位置有辅助作用
   *@param {HTMLElement} 输入框元素
   */
  _getFocus: function (elem) {
    var index = 0;
    if (document.selection) {
      // IE Support
      console.log("支持");
      elem.focus();
      var Sel = document.selection.createRange();
      if (elem.nodeName === "TEXTAREA") {
        //textarea
        var Sel2 = Sel.duplicate();
        Sel2.moveToElementText(elem);
        // var index = -1;
        index = -1;
        while (Sel2.inRange(Sel)) {
          Sel2.moveStart("character");
          index++;
        }
      } else if (elem.nodeName === "INPUT") {
        // input
        Sel.moveStart("character", -elem.value.length);
        index = Sel.text.length;
      }
    } else if (elem.selectionStart || elem.selectionStart == "0") {
      // Firefox support
      index = elem.selectionStart;
    }
    return index;
  },
  /**
   *获取元素在页面中位置
   *@param {HTMLElement} 输入框元素
   */
  _offset: function (elem) {
    var box = elem.getBoundingClientRect(),
      doc = elem.ownerDocument,
      body = doc.body,
      docElem = doc.documentElement;
    var clientTop = docElem.clientTop || body.clientTop || 0,
      clientLeft = docElem.clientLeft || body.clientLeft || 0;
    var top = box.top + (self.pageYOffset || docElem.scrollTop) - clientTop,
      left = box.left + (self.pageXOffset || docElem.scrollLeft) - clientLeft;
    return {
      left: left,
      top: top,
      right: left + box.width,
      bottom: top + box.height,
    };
  },
  // 克隆元素样式并返回类
  _cloneStyle: function (elem, cache) {
    if (!cache && elem["${cloneName}"]) return elem["${cloneName}"];
    var className,
      name,
      rstyle = /^(number|string)$/;
    var rname = /^(content|outline|outlineWidth)$/; //Opera: content; IE8:outline && outlineWidth
    var cssText = [],
      sStyle = elem.style;

    for (name in sStyle) {
      if (!rname.test(name)) {
        var val;
        val = this._getStyle(elem, name);
        if (val !== "" && rstyle.test(typeof val)) {
          // Firefox 4
          name = name.replace(/([A-Z])/g, "-$1").toLowerCase();
          cssText.push(name);
          cssText.push(":");
          cssText.push(val);
          cssText.push(";");
        }
      }
    }
    cssText = cssText.join("");
    elem["${cloneName}"] = className = "clone" + new Date().getTime();
    this._addHeadStyle("." + className + "{" + cssText + "}");
    return className;
  },
  // 获取元素某个属性样式值
  _getStyle:
    "getComputedStyle" in window
      ? function (elem, name) {
          return getComputedStyle(elem, null)[name];
        }
      : function (elem, name) {
          return elem.currentStyle[name];
        },
  // 动态插入style标签（样式）
  _addHeadStyle: function (content) {
    var style = this._style[document];
    if (!style) {
      style = this._style[document] = document.createElement("style");
      document.getElementsByTagName("head")[0].appendChild(style);
    }
    (style.styleSheet && (style.styleSheet.cssText += content)) ||
      style.appendChild(document.createTextNode(content));
  },
  _style: {},
};

const bytesToHex = (bytes) => {
  for (var hex = [], i = 0; i < bytes.length; i++) {
    hex.push((bytes[i] >>> 4).toString(16));
    hex.push((bytes[i] & 0xf).toString(16));
  }
  return hex.join("");
};

const hexToBytes = (hex) => {
  for (var bytes = [], c = 0; c < hex.length; c += 2)
    bytes.push(parseInt(hex.substr(c, 2), 16));
  return bytes;
};

const copyText = (text, callback) => {
  if (!text) {
    callback && callback(text);
    return;
  }
  const copyClipboardCallback = (text) => {
    callback && callback(text);
  };
  if (navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        copyClipboardCallback(text);
      })
      .catch((err) => {
        console.log("copy err", err);
        copyClipboardCallback(null);
      });
  } else {
    copyClipboardCallback(null);
  }
};

const pasteText = (callback) => {
  const clipboardCallback = (text) => {
    callback && callback(text);
  };
  if (navigator.clipboard && navigator.clipboard.readText) {
    navigator.clipboard
      .readText()
      .then((text) => {
        clipboardCallback(text);
      })
      .catch((err) => {
        console.log("paste err", err);
        clipboardCallback(null);
      });
  } else {
    clipboardCallback(null);
  }
};

const copyItem = (clipboardItem, callback) => {
  if (!clipboardItem) {
    callback && callback(clipboardItem);
    return;
  }
  const copyClipboardCallback = (clipboardItem) => {
    callback && callback(clipboardItem);
  };
  // const plain =
  //   "ghc1\tghc2\tghc3\tghc7\tgch8\tgch9\tgch11\ngch4\tghc5\tghc6\t\t\tgch10\t";
  // const html =
  //   '<table><colgroup><col><col><col></colgroup><tbody><tr><td>ghc1</td><td>ghc2</td><td>ghc3</td><td rowspan="2">ghc7</td><td rowspan="2">gch8</td><td>gch9</td><td rowspan="2">gch11</td></tr><tr><td>gch4</td><td>ghc5</td><td>ghc6</td><td>gch10</td></tr></tbody></table>';
  // // let png =
  // //   "";
  // // png = new Uint8Array(hexToBytes(png)).buffer;
  // clipboardItem = new ClipboardItem({
  //   "text/plain": new Blob([plain], { type: "text/plain" }),
  //   "text/html": new Blob([html], { type: "text/html" }),
  //   // "image/png": new Blob([png], { type: "image/png" }),
  // });
  if (navigator.clipboard && navigator.clipboard.write) {
    navigator.clipboard
      .write([clipboardItem])
      .then(() => {
        copyClipboardCallback(clipboardItem);
      })
      .catch((err) => {
        console.log("copy err", err);
        copyClipboardCallback(null);
      });
  } else {
    copyClipboardCallback(null);
  }
};

const pasteItem = (callback) => {
  const clipboardCallback = (text) => {
    callback && callback(text);
  };
  if (navigator.clipboard && navigator.clipboard.read) {
    navigator.clipboard
      .read()
      .then((text) => {
        let found = false;
        for (const item of text) {
          if (item.types.includes("text/plain")) {
            found = true;
            item.getType("text/plain").then((blob) => {
              blob
                .text()
                .then((t) => {
                  console.log("plain", t);
                  clipboardCallback(t);
                })
                .catch((err) => {
                  console.log("plain err", err);
                  clipboardCallback(null);
                });
            });
          }
          if (item.types.includes("text/html")) {
            item.getType("text/html").then((blob) => {
              blob.text().then((t) => {
                console.log("html", t);
              });
            });
          }
          if (item.types.includes("image/png")) {
            item.getType("image/png").then((blob) => {
              blob.arrayBuffer().then((t) => {
                const ht = bytesToHex(new Uint8Array(t));
                const bt = hexToBytes(ht);
                const btb = new Uint8Array(bt).buffer;
                console.log("png", t);
                console.log("png ht", ht);
                console.log("png bt", bt);
                console.log("png bt buffer", btb);
              });
            });
          }
        }
        if (!found) {
          clipboardCallback(null);
        }
      })
      .catch((err) => {
        console.log("paste err", err);
        clipboardCallback(null);
      });
  } else {
    clipboardCallback(null);
  }
};

export {
  getClipboardInfoObj,
  outputTableVariableParams,
  outputTableKeyParams,
  getFocusPosition,
  copyText,
  pasteText,
  copyItem,
  pasteItem,
  precisionValues,
  getOutputTableKeyValue,
  getOutputTableVariableItem,
  getExportDataInfo,
  getClipboardCopyText,
  getOutputTableData,
  formatDataPrecision,
  getPrecisionByKey,
};
