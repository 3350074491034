import axios from "axios";

// 文件上传
const upload = (url, file, success = () => {}, fail = () => {}) => {
  const formData = new FormData();
  formData.append("file", file);
  axios
    .post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      success(res);
    })
    .catch((err) => {
      fail(err);
    });
};

// 网络请求
const request = (
  url,
  method = "get",
  data = {},
  success = () => {},
  fail = () => {}
) => {
  const params = {
    requestData: data,
    requestId: new Date().getTime(),
    timestamp: new Date().getTime(),
  };

  let paramsStr = "";
  if (method === "get" && data) {
    for (const [key, value] of Object.entries(data)) {
      paramsStr = `${paramsStr}&${key}=${value}`;
    }
    paramsStr = paramsStr.replace(/^&/, "?");
  }
  const defaultOptions = {
    url: url + paramsStr,
    method,
    withCredentials: true,
    timeout: 36000000,
    data: params,
    headers: {
      "Content-Type": "application/json",
    },
  };

  // POST传参序列化(添加请求拦截器)
  axios.interceptors.request.use(
    (config) => config,
    (error) => Promise.reject(error.data)
  );
  // 返回状态判断(添加响应拦截器)
  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    (error) => {
      if (error && error.code === "ECONNABORTED") {
        // Message({ message: "网络超时", type: "error" });
      }
      if (error && error.response && error.response.status === 500) {
        // Message({ message: "服务器错误", type: "error" });
      }
      if (error && error.response && error.response.status === 502) {
        // Message({
        //   message: "服务升级中...如等待时间过长，请联系管理员",
        //   type: "error"
        // });
      }
      if (
        error.response &&
        error.response.status &&
        error.response.status === 403
      ) {
        if (
          error.response.headers.redirect &&
          error.response.headers.redirect === "REDIRECT"
        ) {
          window.location = error.response.headers.contentpath;
        }
      }
    }
  );

  axios(defaultOptions)
    .then((res) => {
      success(res);
    })
    .catch((err) => {
      fail(err);
    });
};

export { upload, request };
