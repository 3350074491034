import { reactive } from "vue";

const licenseData = {
  registerUrl:
    "https://hosea.spirilib.com/wizconnect/h5/user_interface/web/vue/tianping/dist/#/active",
  registerCode: "",
  registerCode1: "",
  registerCode2: "",
  registerCode3: "",
  registerCode4: "",
  activeCode: "",
  licenseCode: "",
  code: "",
  registerPwdRaw: "",
  registerPwd: "",
  registerSuccess: false,
  searchName: "",
  searchCode: "",
  searchModules: [],
  addName: "",
  addExpireDate: "",
  addEmails: [],
  addModules: [],
  inputValue: "",
  inputVisible: false,
  InputRef: null,
  inputScopeValue: [],
  inputScopeVisible: [],
  InputScopeRef: [],
  InputResendEmailSelected: {},
  InputResendEmailRef: {},
  allModules: [],
  allActiveCodes: [],

  handleAddClose(value) {
    this.addEmails.splice(this.addEmails.indexOf(value), 1);
  },

  handleAddInputConfirm(value) {
    this.addEmails.push(value);
  },

  setLicenseCode(value) {
    this.licenseCode = value;
  },

  setRegisterCodes(value) {
    if (!value || value.length != 4) {
      return;
    }
    this.registerCode1 = value[0];
    this.registerCode2 = value[1];
    this.registerCode3 = value[2];
    this.registerCode4 = value[3];
    console.log("paste text update", value);
  },

  setRegisterSuccess(value) {
    this.registerSuccess = value;
  },

  setAllActiveCodes(value) {
    console.log("setAllActiveCodes", value);
    this.allActiveCodes = value;
    this.resetScopes();
  },

  setCode(value) {
    this.code = value;
  },

  setAllModules(value) {
    this.allModules = value;
  },

  addItem(value) {
    this.allActiveCodes.push(value);
    this.resetScopes();
  },

  resetScopes() {
    this.inputScopeValue = [];
    this.inputScopeVisible = [];
    this.InputScopeRef = [];
    Object.keys(this.allActiveCodes).forEach(() => {
      this.inputScopeValue.push("");
      this.inputScopeVisible.push(false);
      this.InputScopeRef.push(null);
    });
  },

  updateItem(value) {
    Object.keys(this.allActiveCodes).forEach((key) => {
      if (this.allActiveCodes[key].internal_id == value.internal_id) {
        this.allActiveCodes[key] = Object.assign(
          this.allActiveCodes[key],
          value
        );
      }
    });
  },

  updateEmailStatus(value) {
    Object.keys(this.allActiveCodes).forEach((key) => {
      if (this.allActiveCodes[key].internal_id == value.internal_id) {
        this.allActiveCodes[key] = Object.assign(
          this.allActiveCodes[key],
          value
        );
      }
    });
  },

  updateResendEmailStatus(value) {
    Object.keys(this.allActiveCodes).forEach((key) => {
      if (this.allActiveCodes[key].internal_id == value.internal_id) {
        this.allActiveCodes[key] = Object.assign(
          this.allActiveCodes[key],
          value
        );
      }
    });
  },
};
export default reactive(licenseData);
