const isNewVersion = () => {
  const vueVersion = localStorage.getItem("vueVersionCurrent") || "0.0.1";
  let localVueVersion = localStorage.getItem("vueVersion");
  localStorage.setItem("vueVersion", vueVersion);
  if (!localVueVersion || localVueVersion != vueVersion) {
    console.log("检测到新版本，请点击确认刷新页面哦");
    window.location.reload(true);
  }
};

export default {
  isNewVersion,
};
