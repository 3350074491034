<template>
  <CommandBtn />
  <DeviceList />
</template>

<script>
import { inject, toRefs } from "vue";
import CommandBtn from "./CommandBtn.vue";
import DeviceList from "./DeviceList.vue";

export default {
  name: "BasicRightContent",
  props: {
    msg: String,
  },
  components: {
    CommandBtn,
    DeviceList,
  },
  setup() {
    const menuData = inject("menuData");

    const data = { ...toRefs(menuData) };
    return data;
  },
};
</script>
