import { request } from "./net";

const host =
  window.location.protocol +
  "//" +
  window.location.host.replace("8080", "8777") +
  "/web_interface";

const needRedirectToLicense = (params, success, fail) => {
  request(
    `${host}/license/need_redirect_to_license`,
    "post",
    params,
    success,
    fail
  );
};

const getCode = (params, success, fail) => {
  request(`${host}/license/get_code`, "post", params, success, fail);
};

const getAllModules = (params, success, fail) => {
  request(`${host}/license/get_all_modules`, "post", params, success, fail);
};

const getOpenModules = (params, success, fail) => {
  request(`${host}/license/get_open_modules`, "post", params, success, fail);
};

const activeModules = (params, success, fail) => {
  request(`${host}/license/active_modules`, "post", params, success, fail);
};

const generateActiveCode = (params, success, fail) => {
  request(
    `${host}/license/server/generate_active_code`,
    "post",
    params,
    success,
    fail
  );
};

const getActiveCode = (params, success, fail) => {
  request(
    `${host}/license/server/get_active_code`,
    "post",
    params,
    success,
    fail
  );
};

const verifyActiveCode = (params, success, fail) => {
  request(
    `${host}/license/server/verify_active_code`,
    "post",
    params,
    success,
    fail
  );
};

const updateActiveCode = (params, success, fail) => {
  request(
    `${host}/license/server/update_active_code`,
    "post",
    params,
    success,
    fail
  );
};

const sendEmails = (params, success, fail) => {
  request(`${host}/license/server/send_emails`, "post", params, success, fail);
};

const resendActiveEmails = (params, success, fail) => {
  request(
    `${host}/license/server/resend_active_emails`,
    "post",
    params,
    success,
    fail
  );
};

const checkGeneratePermission = (params, success, fail) => {
  request(
    `${host}/license/server/check_generate_permission`,
    "post",
    params,
    success,
    fail
  );
};

export {
  needRedirectToLicense,
  getCode,
  getAllModules,
  getOpenModules,
  activeModules,
  generateActiveCode,
  getActiveCode,
  verifyActiveCode,
  updateActiveCode,
  sendEmails,
  resendActiveEmails,
  checkGeneratePermission,
};
