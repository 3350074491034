import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "./styles/element/index.scss";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import * as Icons from "@element-plus/icons-vue";
import bus from "./bus/bus";

console.log("process.env", process.env);
if (process.env.NODE_ENV !== "development") {
  console.log = function () {};
  console.error = function () {};
  console.dir = function () {};
}

const app = createApp(App);
app.config.globalProperties.$bus = bus;
Object.keys(Icons).forEach((key) => {
  app.component(key, Icons[key]);
});
app.use(ElementPlus, { size: "small" }).use(store).use(router).mount("#app");
