import { request } from "./net";

const host =
  window.location.protocol +
  "//" +
  window.location.host.replace("8080", "8777") +
  "/web_interface";

const getIp = (params, success, fail) => {
  request(`${host}/get_ip`, "post", params, success, fail);
};

export { getIp };
