import menuData from "./menu";
import deviceData from "./device";
import licenseData from "./license";

const store = createStore({
  menuData,
  deviceData,
  licenseData,
});

function createStore(params) {
  return {
    install(app) {
      this.provide(app);
    },
    provide(app) {
      const keys = params && Object.keys(params);
      keys &&
        keys.forEach((item) => {
          // 注册
          app.provide(item, params[item]);
        });
    },
  };
}
export default store;
