<template>
  <el-table
    @header-click="headerClick"
    :data="services"
    class="max-row-divider"
    :border="true"
    :resizable="true"
    v-loading="loading"
    :row-class-name="tableRowClassName"
    @row-click="rowClick"
  >
    <el-table-column
      prop="selected"
      :label="`已选(${selectedData.length})`"
      width="120px"
      header-align="center"
    >
      <template #header>
        <el-space>
          <div>{{ `已选(${selectedData.length})` }}</div>
          <el-tooltip
            class="box-item"
            effect="dark"
            content=""
            placement="top-start"
            :offset="20"
          >
            <template #content
              >已选说明：<br /><br />1.点击已选文字可以 “全选” 或者
              “取消全选”<br />2.括号中展示了已选中数量<br />3.点击下面选择框可以多选<br />4.点击下表格中非选择框部分会单选中该行<br />5.选择框灰色表示改行不能被选中</template
            >
            <el-icon><QuestionFilled /></el-icon>
          </el-tooltip>
        </el-space>
      </template>
      <template #default="scope">
        <div class="row-center">
          <el-checkbox
            v-model="scope.row.selected"
            label=" "
            @change="_updateSelect"
            :disabled="scope.row.disabled"
          >
          </el-checkbox>
        </div>
      </template>
    </el-table-column>
    <el-table-column prop="name" label="名称" sort-by="'name'">
      <template #default="scope">
        <div class="tb-content">
          <span>{{ scope.row.name }}</span>
        </div>
      </template>
    </el-table-column>
    <el-table-column
      prop="interfaceTips"
      label="接口"
      sort-by="'interfaceTips'"
    >
      <template #default="scope">
        <div class="tb-content">
          <span>{{ scope.row.interfaceTips }}</span>
        </div>
      </template>
    </el-table-column>
    <el-table-column
      prop="stateTips"
      label="运行状态"
      min-width="300px"
      sort-by="['state_normal','stateTips']"
    >
      <template #default="scope">
        <div class="status-bg">
          <div class="progress-bg">
            <el-progress
              v-show="scope.row.progressEnabled"
              class="my-progress"
              :stroke-width="6"
              :show-text="false"
              stroke-linecap="butt"
              :percentage="scope.row.progress"
              status="success"
            >
            </el-progress>
          </div>
          <div class="status-icon tb-content">
            <el-space alignment="center">
              <div>
                <div id="state_icon_normal" v-show="scope.row.state_normal">
                  <svg
                    class="icon"
                    width="10"
                    height="10"
                    xmlns="http://www.w3.org/2000/svg"
                    data-v-365b8594=""
                  >
                    <circle
                      cx="50%"
                      cy="50%"
                      r="47%"
                      fill="#FEFFFE"
                      stroke="#BBBBBB"
                      stroke-width="3%"
                    />
                  </svg>
                </div>
                <div id="state_icon_running" v-show="scope.row.state_running">
                  <svg
                    class="icon"
                    width="10"
                    height="10"
                    xmlns="http://www.w3.org/2000/svg"
                    data-v-365b8594=""
                  >
                    <circle
                      cx="50%"
                      cy="50%"
                      r="47%"
                      fill="#83F275"
                      stroke="#BBBBBB"
                      stroke-width="3%"
                    />
                  </svg>
                </div>
                <div id="state_icon_waiting" v-show="scope.row.state_waiting">
                  <svg
                    class="icon"
                    width="10"
                    height="10"
                    xmlns="http://www.w3.org/2000/svg"
                    data-v-365b8594=""
                  >
                    <circle
                      cx="50%"
                      cy="50%"
                      r="47%"
                      fill="#E6A23C"
                      stroke="#BBBBBB"
                      stroke-width="3%"
                    />
                  </svg>
                </div>
                <div id="state_icon_error" v-show="scope.row.state_error">
                  <svg
                    class="icon"
                    width="10"
                    height="10"
                    xmlns="http://www.w3.org/2000/svg"
                    data-v-365b8594=""
                  >
                    <circle
                      cx="50%"
                      cy="50%"
                      r="47%"
                      fill="#F25B5B"
                      stroke="#BBBBBB"
                      stroke-width="3%"
                    />
                  </svg>
                </div>
              </div>
              <span :class="scope.row.state_error ? 'error-tips' : ''">{{
                scope.row.stateTips
              }}</span>
            </el-space>
          </div>
        </div>
      </template>
    </el-table-column>
    <el-table-column
      prop="lastData"
      label="最近一次数据"
      sort-by="['last_trigger_time','lastData']"
    >
      <template #default="scope">
        <div class="tb-content">
          <span>{{ scope.row.lastData }}</span>
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { inject, toRefs } from "vue";
import { getService } from "../api/device";
import { uuid } from "../util/uuid_util";
import { formatDate, milliUnit } from "../util/date_util";

export default {
  name: "DeviceList",
  props: {
    msg: String,
  },
  methods: {
    headerClick(column) {
      if (column.property == "selected") {
        this.toggleAllSelect();
      }
    },
    rowClick(row, column, event) {
      if (column.property != "selected") {
        console.log("_updateSelect click1 row", row, column);
        row.selected = !row.selected;
        this._updateSelect(row);
      } else {
        var target = "" + event.target.className;
        if (target.indexOf("el-checkbox") < 0 && !row.disabled) {
          console.log("_updateSelect click2 row", row, column, event, target);
          row.selected = !row.selected;
          this._updateSelect();
        }
      }
    },
    _updateSelect(row = null) {
      console.log("_updateSelect", row);
      this.updateSelect(row);
    },
    tableRowClassName({ row }) {
      if (row.selected) {
        return "selected-row";
      }
      return "unselected-row";
    },
    notifyStartUpdateService() {
      console.log("notifyStartUpdateService");
      this.loading = true;
      this.startUpdateService(() => {
        this.loading = false;
      });
    },
    startUpdateService(success) {
      console.log(
        "startUpdateService",
        "uuid",
        this.myUuid,
        "success",
        success,
        "timer",
        this.timer
      );
      if (this.timer) {
        clearInterval(this.timer);
        this.timer = null;
      }
      if (this.rightContent != "BasicRightContent") {
        if (typeof success == "function") {
          success();
        }
        return;
      }
      if (this.updateServiceLoading) {
        console.log("updateServiceLoading");
        if (typeof success == "function") {
          success();
        }
        return;
      }
      this.updateServiceLoading = true;
      this.lastUpdateTime = Date.now();
      this.updateServiceCount++;
      getService(
        {},
        (data) => {
          const logCount = 30;
          if (this.updateServiceCount % logCount == 1) {
            console.log(
              "updateServiceCount",
              this.updateServiceCount,
              "time cost",
              (Date.now() - this.updateServiceLogTime) / logCount,
              "milliseconds",
              "data",
              data
            );
            this.updateServiceLogTime = Date.now();
          }
          if (data && data.data && data.data.data && data.data.code > 0) {
            const servicesData = {};
            Object.keys(this.services).forEach((key) => {
              const item = this.services[key];
              servicesData[item.internal_id] = item;
            });
            const newServicesData = {};
            const newServices = [];
            Object.keys(data.data.data).forEach((key) => {
              const item = data.data.data[key];
              const internal_id = item.internal_id;
              if (internal_id) {
                const oldItem = servicesData[internal_id];
                if (oldItem) {
                  item.selected = oldItem.selected;
                }
                if (item.param_data) {
                  item.interfaceTips = item.param_data.port;
                }
                item.lockScreenName = "";
                if (
                  item.param_data &&
                  item.param_data.output_screen_enabled &&
                  item.param_data.output_screen_name &&
                  item.param_data.keyboard_data &&
                  item.param_data.keyboard_data.length > 1
                ) {
                  item.lockScreenName = item.param_data.output_screen_name;
                }
                if ("state_idle" == item.state) {
                  item.state_normal = true;
                  item.state_running = false;
                  item.state_waiting = false;
                  item.state_error = false;
                  if (item.last_stop_time) {
                    item.stateTips =
                      "停止于" + formatDate(item.last_stop_time / milliUnit);
                    if (item.trigger_count != undefined) {
                      item.stateTips +=
                        "，已采集" + item.trigger_count + "个数据";
                    }
                  } else {
                    item.stateTips = "未启动";
                  }
                } else if ("state_error" == item.state) {
                  item.state_normal = false;
                  item.state_running = false;
                  item.state_waiting = false;
                  item.state_error = true;
                  let errorTime = item.last_stop_time;
                  if (!errorTime) {
                    errorTime = item.internal_updated_time;
                  }
                  if (errorTime) {
                    item.stateTips =
                      "异常发生于" + formatDate(errorTime / milliUnit);
                    if (item.error_msg) {
                      item.stateTips += "，异常说明：" + item.error_msg;
                    }
                  }
                } else {
                  if (
                    item.start_time &&
                    item.start_time > Date.now() * milliUnit
                  ) {
                    item.state_normal = false;
                    item.state_running = false;
                    item.state_waiting = true;
                    item.state_error = false;
                    item.stateTips = "等待中，开始时间";
                    item.stateTips += formatDate(item.start_time / milliUnit);
                  } else {
                    item.state_normal = false;
                    item.state_running = true;
                    item.state_waiting = false;
                    item.state_error = false;
                    item.stateTips = "运行中";
                    if (item.lockScreenName) {
                      item.stateTips +=
                        "，已锁定目标窗口“" + item.lockScreenName + "”";
                    }
                    if (item.trigger_count != undefined) {
                      item.stateTips +=
                        "，已采集" + item.trigger_count + "个数据";
                    }
                    if (item.end_time) {
                      item.stateTips +=
                        "，将于" +
                        formatDate(item.end_time / milliUnit) +
                        "结束";
                    }
                  }
                }
                item.lastData = item.last_read_data;
                if (!item.lastData && !item.state_normal) {
                  item.lastData = "暂未读取到数据";
                }
                item.progressEnabled =
                  item.state_running &&
                  item.end_time &&
                  item.start_time &&
                  item.end_time > item.start_time;
                if (item.progressEnabled) {
                  item.progress = parseInt(
                    (100 * (Date.now() * milliUnit - item.start_time)) /
                      (item.end_time - item.start_time)
                  );
                  if (item.progress > 100) {
                    item.progress = 100;
                  }
                  if (item.progress < 0) {
                    item.progress = 0;
                  }
                }
                if (item.state_running && item.progressEnabled) {
                  item.stateTips += "，" + item.progress + "%";
                }
                newServices.push(item);
                newServicesData[internal_id] = item;
              }
            });
            this.setServices(newServices);
            let update = false;
            Object.keys(servicesData).forEach((key) => {
              if (!(servicesData[key] in newServicesData)) {
                update = true;
              }
            });
            if (update) {
              this.updateSelect();
            }
          }
          this.reStartUpdateService(success);
        },
        (err) => {
          console.log(
            "updateServiceCount",
            this.updateServiceCount,
            "err",
            err
          );
          this.reStartUpdateService(success);
        }
      );
    },

    reStartUpdateService(success) {
      if (typeof success == "function") {
        success();
      }
      this.updateServiceLoading = false;
      const delta = Date.now() - this.lastUpdateTime;
      if (delta >= this.updateServiceDelta) {
        this.startUpdateService();
      } else {
        const that = this;
        this.timer = setTimeout(() => {
          clearInterval(this.timer);
          this.timer = null;
          that.startUpdateService();
        }, this.updateServiceDelta - delta);
      }
    },
  },
  setup() {
    const deviceData = inject("deviceData");
    const menuData = inject("menuData");
    const timer = null;
    const lastUpdateTime = Date.now();
    const updateServiceDelta = 1 * 1000;
    const updateServiceCount = 0;
    const updateServiceLogTime = Date.now();
    const updateServiceLoading = false;
    const myUuid = uuid();

    const data = {
      ...toRefs(deviceData),
      ...toRefs(menuData),
      timer,
      lastUpdateTime,
      updateServiceDelta,
      updateServiceCount,
      updateServiceLogTime,
      updateServiceLoading,
      myUuid,
    };
    return data;
  },
  mounted() {
    console.log("mounted device list");
    this.loading = true;
    this.startUpdateService(() => {
      this.loading = false;
    });
    this.$bus.$on("notifyStartUpdateService", this.notifyStartUpdateService);
  },

  unmounted() {
    console.log("unmounted device list");
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
    this.$bus.$off("notifyStartUpdateService", this.notifyStartUpdateService);
  },
};
</script>

<style scoped>
.max-row {
  width: 100%;
}

.max-row-divider {
  width: 100%;
  margin-top: 20px;
}

.status-bg {
  display: flex;
  width: 100%;
  min-height: 60px;
  align-items: center;
}

.error-tips {
  color: #f25b5b;
}

.progress-bg {
  z-index: 1;
  position: absolute;
  height: 6px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
}

.my-progress {
  height: 6px;
  width: 100%;
}

.status-icon {
  z-index: 999;
}

.tb-content {
  margin-left: 10px;
  margin-right: 10px;
}

.row-center {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.el-table :deep(.el-table__row) .el-table__cell {
  padding: 0px !important;
}

.el-table :deep(.el-table__row) .cell {
  padding: 0px !important;
}

.el-table :deep(.selected-row) {
  --el-table-tr-bg-color: #6666c4 !important;
  --el-table-row-hover-bg-color: #d1d1f0 !important;
}

.el-table :deep(.unselected-row) {
  --el-table-tr-bg-color: #ffffff !important;
  --el-table-row-hover-bg-color: #f5f7fa !important;
}

.el-table {
  --el-table-header-text-color: #ffffff !important;
  --el-table-text-color: #101010 !important;
  --el-table-header-bg-color: #41419f !important;
}

:deep(.el-progress-bar__inner) {
  background-color: #00aa46;
  border-radius: 0px;
}

:deep(.el-progress-bar__outer) {
  background-color: #00000000;
  border-radius: 0px;
}
</style>
