const milliUnit = 1000000;
const secUnit = 1000000000;

const getDayHourMinuteSecondFromNs = (timeNs) => {
  if (timeNs < secUnit) {
    return "0秒";
  }
  const seconds = parseInt(timeNs / secUnit);
  if (seconds < 60) {
    return (seconds % 60) + "秒";
  }
  const minutes = parseInt(seconds / 60);
  if (minutes < 60) {
    return (minutes % 60) + "分钟" + (seconds % 60) + "秒";
  }
  const hours = parseInt(minutes / 60);
  if (hours < 24) {
    return (
      (hours % 24) + "小时" + (minutes % 60) + "分钟" + (seconds % 60) + "秒"
    );
  }
  const days = parseInt(hours / 24);
  return (
    days +
    "天" +
    (hours % 24) +
    "小时" +
    (minutes % 60) +
    "分钟" +
    (seconds % 60) +
    "秒"
  );
};

const formatDate = (value) => {
  if (!value) {
    return null;
  }
  let date = new Date(value);
  let y = date.getFullYear();
  let MM = date.getMonth() + 1;
  MM = MM < 10 ? "0" + MM : MM;
  let d = date.getDate();
  d = d < 10 ? "0" + d : d;
  let h = date.getHours();
  h = h < 10 ? "0" + h : h;
  let m = date.getMinutes();
  m = m < 10 ? "0" + m : m;
  let s = date.getSeconds();
  s = s < 10 ? "0" + s : s;
  return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
};

const formatTime = (value) => {
  if (!value) {
    return null;
  }
  let date = new Date(value);
  let h = date.getHours();
  h = h < 10 ? "0" + h : h;
  let m = date.getMinutes();
  m = m < 10 ? "0" + m : m;
  let s = date.getSeconds();
  s = s < 10 ? "0" + s : s;
  return h + ":" + m + ":" + s;
};

const formatDateFromNs = (timeNs) => {
  if (!timeNs) {
    return null;
  }
  return formatDate(timeNs / milliUnit);
};

const formatTimeFromNs = (timeNs) => {
  if (!timeNs) {
    return null;
  }
  return formatTime(timeNs / milliUnit + Date.parse("1970-01-01 00:00:00"));
};

export {
  secUnit,
  milliUnit,
  getDayHourMinuteSecondFromNs,
  formatDate,
  formatDateFromNs,
  formatTime,
  formatTimeFromNs,
};
