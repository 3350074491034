<template>
  <div class="bottom_container" id="myChartContainer" v-loading="loadingData">
    <div class="row">
      <el-button class="divider-btn" @click="showDrawer"
        >动态更新图表</el-button
      >
      <el-button
        class="divider-btn"
        @click="restoreDimensions"
        v-show="this.timeDimensions"
        >恢复至原始窗口</el-button
      >
    </div>
    <div class="chart-container" v-loading="loadingDbGraphData">
      <div id="myChart" class="chart-container-canvas"></div>
      <div id="legend" class="chart-container-legend"></div>
    </div>
    <MyDatabaseLatestGraphDrawer ref="MyDatabaseLatestGraphDrawer" />
  </div>
</template>

<script>
import { getDeviceGraph } from "../api/device";
import { inject, toRefs, ref } from "vue";
import Dygraph from "dygraphs";
import MyDatabaseLatestGraphDrawer from "./MyDatabaseLatestGraphDrawer.vue";

export default {
  name: "MyDatabaseGraph",
  props: {},
  components: {
    MyDatabaseLatestGraphDrawer,
  },
  methods: {
    showDrawer() {
      this.$refs.MyDatabaseLatestGraphDrawer.showDrawer();
    },
    restoreDimensions() {
      this.initDbData(true);
    },
    initDbData(reset = false) {
      if (this.loadingData) {
        console.log("getDeviceGraph is loading");
        return;
      }
      this.$refs.MyDatabaseLatestGraphDrawer.initDbData(true);
      console.log("getDeviceGraph initDbData", reset);
      const graphFlag = Date.now();
      this.graphFlag = graphFlag;
      if (reset) {
        this.timeDimensions = null;
      }
      this.loadingData = true;
      getDeviceGraph(
        {
          deviceNameSelect: this.deviceNameSelect,
          runIdSelect: this.runIdSelect,
          timeDimensions: this.timeDimensions,
        },
        (data) => {
          this.loadingData = false;
          console.log("getDeviceGraph data for graph", data);
          if (data && data.data && data.data.data && data.data.code > 0) {
            this.myChartData = data.data.data.list || [];
            Object.values(this.myChartData).forEach((value) => {
              value[0] = new Date(value[0]);
            });
            this.myChartDataValueRange = [
              data.data.data.min || 0,
              data.data.data.max || 2,
            ];
            const valueDelta =
              (this.myChartDataValueRange[1] - this.myChartDataValueRange[0]) /
              10;
            this.myChartDataValueRange[0] =
              this.myChartDataValueRange[0] - valueDelta;
            this.myChartDataValueRange[1] =
              this.myChartDataValueRange[1] + valueDelta;
            this.myChartDataDateWindow = [
              data.data.data.startTime || 0,
              data.data.data.endTime || 0,
            ];
            const dateDelta =
              (this.myChartDataDateWindow[1] - this.myChartDataDateWindow[0]) /
              10;
            this.myChartDataDateWindow[0] =
              this.myChartDataDateWindow[0] - dateDelta;
            this.myChartDataDateWindow[1] =
              this.myChartDataDateWindow[1] + dateDelta;
            const dbDataDeviceNameExperiments = data.data.data.labels || [];
            if (dbDataDeviceNameExperiments.length === 0) {
              dbDataDeviceNameExperiments.push("设备");
            }
            this.myChartDataLabels = ["时间", ...dbDataDeviceNameExperiments];
            this.reloadGraph(graphFlag);
          }
        },
        (err) => {
          this.loadingData = false;
          console.log("getDeviceGraph err for graph", err);
        }
      );
    },
    reloadGraph(graphFlag) {
      if (this.graphFlag !== graphFlag) {
        console.log(
          "getDeviceGraph no need to reload Grapth",
          graphFlag,
          this.graphFlag,
          this.graphFlag - graphFlag
        );
        return;
      }
      const options = {
        file: this.myChartData,
        labels: this.myChartDataLabels,
        valueRange: this.myChartDataValueRange,
        dateWindow: this.myChartDataDateWindow,
      };
      console.log("getDeviceGraph reloadGraph options", options);
      if (this.myChart) {
        this.myChart.updateOptions(options);
      }
      this.resize();
    },
    resize() {
      this.$nextTick(() => {
        if (this.myChart) {
          const resizeSt = Date.now();
          const graph = document.getElementById("myChartContainer");
          console.log(
            "getDeviceGraph graph",
            graph,
            graph.offsetWidth,
            graph.offsetHeight
          );
          this.myChart.resize(400, 400);
          this.myChart.resize(graph.offsetWidth - 200, 400);
          console.log("getDeviceGraph resize time cost", Date.now() - resizeSt);
        }
      });
    },
    updateDimensions(minDate, maxDate, yRanges) {
      console.log("getDeviceGraph updateDimensions", minDate, maxDate, yRanges);
      if (this.timeDimensions != [minDate, maxDate]) {
        this.timeDimensions = [minDate, maxDate];
        this.initDbData();
      }
    },
  },
  mounted() {
    const graph = document.getElementById("myChart");
    this.myChart = new Dygraph(graph, this.myChartData, {
      drawPoints: true,
      highlightSeriesOpts: { strokeWidth: 2 },
      labelsKMB: true,
      connectSeparatedPoints: true,
      legend: "always",
      animatedZooms: true,
      valueRange: this.myChartDataValueRange,
      labels: this.myChartDataLabels,
      labelsDiv: document.getElementById("legend"),
      labelsSeparateLines: true,
      zoomCallback: this.updateDimensions,
      dateWindow: this.myChartDataDateWindow,
    });
    this.resize();
    this.initDbData();
  },
  unmounted() {
    this.myChartData = ref([]);
    this.myChartDataValueRange = [0, 2];
    this.myChartDataDateWindow = [0, 0];
    this.myChartDataLabels = ["时间", "设备"];
    if (this.myChart) {
      this.myChart.destroy();
      this.myChart = null;
    }
  },
  setup() {
    const deviceData = inject("deviceData");
    const menuData = inject("menuData");
    const myChart = ref(null);
    const myChartData = ref([]);
    const myChartDataValueRange = ref([0, 2]);
    const myChartDataDateWindow = ref([0, 0]);
    const myChartDataLabels = ref(["时间", "设备"]);
    const graphFlag = ref(Date.now());
    const loadingData = ref(false);
    const timeDimensions = ref(null);

    const data = {
      ...toRefs(deviceData),
      ...toRefs(menuData),
      myChart,
      myChartData,
      myChartDataValueRange,
      myChartDataDateWindow,
      myChartDataLabels,
      graphFlag,
      loadingData,
      timeDimensions,
    };
    return data;
  },
};
</script>

<style scoped>
.bottom_container {
  min-height: 400px;
  margin-top: 60px;
}
.chart-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 20px;
}

.chart-container-canvas {
  width: 400px;
  height: 400px;
  display: inline-block;
  vertical-align: top;
}

.chart-container-legend {
  display: inline-block;
  vertical-align: top;
}

.divider-btn {
  height: 36px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
