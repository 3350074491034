<template>
  <el-dialog v-model="dialogFormVisible" title="设置运行信息">
    <el-space class="row" direction="vertical" alignment="start">
      <div v-if="manualIdEnabled" class="title">设置实验名称：</div>
      <el-space
        v-if="manualIdEnabled"
        wrap
        direction="horizontal"
        alignment="start"
      >
        <span> 实验名称 </span>
        <el-input
          v-model="experimentId"
          autocomplete="off"
          placeholder="请输入此次实验名称"
        />
      </el-space>
      <br v-if="manualIdEnabled && startWithEndEnabled" />
      <br v-if="manualIdEnabled && startWithEndEnabled" />
      <el-space
        v-if="startWithEndEnabled"
        wrap
        direction="horizontal"
        alignment="start"
      >
        <div class="title">设置停止信息：</div>
        <el-switch
          @change="onStartParamsChanged"
          v-model="startWithEndPreciseDateEnabled"
          active-text="按运行到指定日期停止"
          inactive-text="按持续多少秒数停止"
        />
      </el-space>
      <el-space
        v-if="startWithEndEnabled"
        wrap
        direction="horizontal"
        alignment="start"
      >
        <span> 运行 </span>
        <el-input
          @change="onStartParamsChanged"
          v-if="!startWithEndPreciseDateEnabled"
          v-model="startWithEndDeltaSec"
          placeholder="请输入持续时间"
        />
        <span v-if="!startWithEndPreciseDateEnabled"> 秒后自动停止 </span>

        <span v-if="startWithEndPreciseDateEnabled"> 到 </span>
        <el-date-picker
          @change="onStartParamsChanged"
          v-if="startWithEndPreciseDateEnabled"
          v-model="startWithEndPreciseDate"
          type="datetime"
          value-format="YYYY-MM-DD HH:mm:ss"
          :disabledDate="endDisabledDate"
          placeholder="选择具体结束日期"
        />
        <span v-if="startWithEndPreciseDateEnabled"> 后自动停止 </span>
      </el-space>
    </el-space>
    <template #footer>
      <span>
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="start">开始运行</el-button>
      </span>
    </template>
  </el-dialog>

  <el-space wrap class="row">
    <el-button @click="preStart" :disabled="!this.enableStart">运行</el-button>
    <el-button :disabled="true">暂停</el-button>
    <el-button @click="stop" :disabled="!this.enableStop">停止</el-button>
    <div class="line-divider" />
    <el-dropdown
      v-if="!showSubCommands"
      @visible-change="dropDownVisibleChange"
    >
      <el-button
        >编辑<el-icon v-if="!dropDownVisible" class="el-icon--right">
          <arrow-down />
        </el-icon>
        <el-icon v-if="dropDownVisible" class="el-icon--right">
          <arrow-up />
        </el-icon>
      </el-button>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item>
            <el-button @click="addDevice">新建</el-button>
          </el-dropdown-item>
          <el-dropdown-item>
            <el-button @click="updateDevice" :disabled="!this.enableModify"
              >修改</el-button
            >
          </el-dropdown-item>
          <el-dropdown-item>
            <el-button @click="copyDevice" :disabled="!this.enableCopy"
              >复制</el-button
            >
          </el-dropdown-item>
          <el-dropdown-item>
            <el-button @click="deleteDevice" :disabled="!this.enableDelete"
              >删除</el-button
            >
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <el-button v-if="showSubCommands" @click="addDevice">新建</el-button>
    <el-button
      v-if="showSubCommands"
      @click="updateDevice"
      :disabled="!this.enableModify"
      >修改</el-button
    >
    <el-button
      v-if="showSubCommands"
      @click="copyDevice"
      :disabled="!this.enableCopy"
      >复制</el-button
    >
    <el-button
      v-if="showSubCommands"
      @click="deleteDevice"
      :disabled="!this.enableDelete"
      >删除</el-button
    >
    <div v-if="showSubCommands" @click="toggleSubCommands">
      <el-icon class="sub-command">
        <ArrowLeftBold />
      </el-icon>
    </div>
    <div v-if="!showSubCommands" @click="toggleSubCommands">
      <el-icon class="sub-command">
        <ArrowRightBold />
      </el-icon>
    </div>
  </el-space>
  <el-space wrap direction="vertical" alignment="start" class="row-divider">
    <el-space wrap direction="horizontal">
      <el-checkbox
        @change="onStartParamsChanged"
        v-model="manualIdEnabled"
        :disabled="experimentIdDisabled"
      >
      </el-checkbox>
      <span> 启动时输入实验信息</span>
    </el-space>
    <el-space wrap direction="horizontal">
      <el-checkbox
        @change="onStartParamsChanged"
        v-model="startWithEndEnabled"
        :disabled="autoStopDisabled"
      >
      </el-checkbox>
      <span> 自动停止</span>
    </el-space>
  </el-space>
</template>

<script>
import { inject, toRefs, ref } from "vue";
import { ElMessageBox, ElMessage, ElLoading } from "element-plus";
import dayjs from "dayjs";
import {
  deleteParamService,
  startService,
  stopService,
  addStartParams,
  getStartParams,
} from "../api/device";
import { formatDate } from "../util/date_util";

export default {
  name: "CommandBtn",
  props: {
    msg: String,
  },
  unmounted() {
    this.dialogFormVisible = false;
  },
  mounted() {
    this.dialogFormVisible = false;
    getStartParams(
      {},
      (data) => {
        console.log("getStartParams data", data);
        if (data && data.data && data.data.data && data.data.code > 0) {
          this.setStartParams(data.data.data);
        }
      },
      (err) => {
        console.log("getStartParams err", err);
      }
    );
  },
  methods: {
    dropDownVisibleChange(value) {
      console.log("dropDownVisibleChange", value);
      this.dropDownVisible = value;
    },
    toggleSubCommands() {
      this.showSubCommands = !this.showSubCommands;
    },
    endDisabledDate(e) {
      return e.getTime() < Date.now() - 86400000; //前一天不能选择
    },
    notifyStartUpdateService() {
      this.$bus.$emit("notifyStartUpdateService");
    },
    addDevice() {
      this.setAddDeviceItem({});
      this.addOrUpdateDevice();
    },
    onStartParamsChanged(e) {
      if (this.startWithEndPreciseDateEnabled && this.startWithEndPreciseDate) {
        const dateMilliseconds = +dayjs(this.startWithEndPreciseDate);
        if (dateMilliseconds < Date.now() + 60 * 1000) {
          // min 60 seconds
          this.startWithEndPreciseDate = "";
          ElMessage.error("自动结束日期必须晚于 一分钟 以上！");
          return;
        }
      }
      if (this.startWithEndDeltaSec) {
        this.startWithEndDeltaSec = parseFloat(
          parseFloat(this.startWithEndDeltaSec).toFixed(1)
        );
      }
      if (!this.startWithEndDeltaSec) {
        this.startWithEndDeltaSec = "";
      }
      var startParams = {
        lastExperimentId: this.lastExperimentId,
        startParamsInternalId: this.startParamsInternalId,
        manualIdEnabled: this.manualIdEnabled,
        startWithEndEnabled: this.startWithEndEnabled,
        startWithEndPreciseDateEnabled: this.startWithEndPreciseDateEnabled,
        startWithEndDeltaSec: this.startWithEndDeltaSec || 0,
        startWithEndPreciseDate: this.startWithEndPreciseDate,
      };
      console.log("onStartParamsChanged", e, "startParams", startParams);
      addStartParams(
        startParams,
        (data) => {
          console.log("addStartParams data", data);
        },
        (err) => {
          console.log("addStartParams err", err);
        }
      );
    },
    getLastIndex(value) {
      var indexValue = "";
      var validIndex = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
      value = value + "";
      for (var index = value.length; index > 0; index--) {
        if (parseInt(value[index - 1]) in validIndex) {
          indexValue = value[index - 1] + indexValue;
        } else {
          break;
        }
      }
      if (indexValue) {
        return (
          value.substring(0, value.length - indexValue.length) +
          (parseInt(indexValue) + 1)
        );
      } else {
        return value + "1";
      }
    },
    getManualIdEnabled() {
      let manualIdEnabled = this.manualIdEnabled;
      if (this.experimentIdDisabled) {
        manualIdEnabled = false;
      }
      return manualIdEnabled;
    },
    getStartWithEndEnabled() {
      let startWithEndEnabled = this.startWithEndEnabled;
      if (this.autoStopDisabled) {
        startWithEndEnabled = false;
      }
      return startWithEndEnabled;
    },
    preStart() {
      if (this.getManualIdEnabled() || this.getStartWithEndEnabled()) {
        if (this.lastExperimentId) {
          this.experimentId = this.getLastIndex(this.lastExperimentId);
        }
        this.dialogFormVisible = true;
      } else {
        this.start();
      }
    },
    start() {
      if (this.startWithEndDeltaSec) {
        this.startWithEndDeltaSec = parseFloat(
          parseFloat(this.startWithEndDeltaSec).toFixed(1)
        );
      }
      if (!this.startWithEndDeltaSec) {
        this.startWithEndDeltaSec = "";
      }
      if (this.dialogFormVisible) {
        if (!this.experimentId && this.getManualIdEnabled()) {
          ElMessage.error("请先输入实验名称！");
          return;
        }
      }
      this.dialogFormVisible = false;
      const startServiceIds = [];
      Object.values(this.services).forEach((key) => {
        if (key.selected && (key.state_normal || key.state_error)) {
          startServiceIds.push({ internal_id: key.internal_id });
        }
      });
      console.log("start");
      if (startServiceIds.length == 0) {
        console.log("start none");
        this.clearSelect();
        return;
      }
      const loading = ElLoading.service({
        lock: true,
        text: "启动中...",
        background: "rgba(0, 0, 0, 0.7)",
      });
      setTimeout(() => {
        loading.close();
      }, 20000);
      var startExperimentIdSkip = false;
      var startExperimentId = null;
      if (this.experimentId) {
        startExperimentId = this.experimentId;
        this.lastExperimentId = this.experimentId;
        this.onStartParamsChanged();
      } else {
        startExperimentId =
          "实验 " +
          formatDate(Date.now()) +
          " " +
          startServiceIds.length +
          " 台";
      }
      if (this.experimentIdDisabled) {
        startExperimentId = null;
        startExperimentIdSkip = true;
      }
      let startWithEndEnabled = this.startWithEndEnabled;
      if (this.autoStopDisabled) {
        startWithEndEnabled = false;
      }
      var nowMilli = Date.now();
      startService(
        {
          startServiceIds: startServiceIds,
          experimentId: startExperimentId,
          experimentIdSkip: startExperimentIdSkip,
          manualIdEnabled: this.manualIdEnabled,
          startWithEndEnabled: startWithEndEnabled,
          startWithEndPreciseDateEnabled: this.startWithEndPreciseDateEnabled,
          startWithEndDeltaSec: this.startWithEndDeltaSec || 0,
          startWithEndPreciseDate: this.startWithEndPreciseDate,
          startMilli: nowMilli,
        },
        (data) => {
          loading.close();
          console.log("data", data);
          if (data && data.data) {
            if (data.data.code > 0) {
              var newServices = this.services;
              Object.keys(newServices).forEach((key) => {
                var item = newServices[key];
                item.state_normal = false;
                item.state_running = false;
                item.state_waiting = true;
                item.state_error = false;
                item.stateTips = "等待中，开始时间";
                item.stateTips += formatDate(nowMilli);
              });
              this.setServices(newServices);
              this.clearSelect();
              this.notifyStartUpdateService();
            } else {
              ElMessage.error("网络错误3：" + JSON.stringify(data));
            }
          } else {
            ElMessage.error("网络错误2：" + JSON.stringify(data));
          }
        },
        (err) => {
          loading.close();
          console.log("err", err);
          ElMessage.error("网络错误1：" + JSON.stringify(err));
        }
      );
    },
    stop(success) {
      const stopServiceIds = [];
      Object.values(this.services).forEach((key) => {
        if (key.selected && (key.state_running || key.state_waiting)) {
          stopServiceIds.push({ internal_id: key.internal_id });
        }
      });
      console.log("stop");
      if (stopServiceIds.length == 0) {
        console.log("stop none");
        this.clearSelect();
        return;
      }
      const loading = ElLoading.service({
        lock: true,
        text: "停止中...",
        background: "rgba(0, 0, 0, 0.7)",
      });
      setTimeout(() => {
        loading.close();
      }, 20000);
      stopService(
        stopServiceIds,
        (data) => {
          loading.close();
          console.log("data", data);
          if (data && data.data) {
            if (data.data.code > 0) {
              this.clearSelect();
              this.notifyStartUpdateService();
              if (typeof success == "function") {
                success();
              }
            } else {
              ElMessage.error("网络错误3：" + JSON.stringify(data));
            }
          } else {
            ElMessage.error("网络错误2：" + JSON.stringify(data));
          }
        },
        (err) => {
          loading.close();
          console.log("err", err);
          ElMessage.error("网络错误1：" + JSON.stringify(err));
        }
      );
    },
    copyDeviceItem(value) {
      const selected = {};
      Object.assign(selected, value);
      if (value.param_data) {
        selected.param_data = {};
        Object.assign(selected.param_data, value.param_data);
      }
      return selected;
    },
    updateDevice() {
      const selected = {};
      Object.values(this.services).forEach((key) => {
        if (key.selected) {
          Object.assign(selected, this.copyDeviceItem(key));
        }
      });
      if (selected.state_normal || selected.state_error) {
        this.setAddDeviceItem(selected);
        this.addOrUpdateDevice();
      } else {
        ElMessageBox.confirm(
          "所选设备处于活动状态，是否停止该设备并进入修改设置界面？",
          "确认停止",
          {
            confirmButtonText: "停止",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            console.log("stop start");
            this.stop(() => {
              this.setAddDeviceItem(selected);
              this.addOrUpdateDevice();
            });
          })
          .catch((err) => {
            console.log("stop canceled", err);
            if ("cancel" != err) {
              ElMessage.error("错误：" + JSON.stringify(err));
            }
          });
      }
    },
    copyDevice() {
      const selected = {};
      Object.values(this.services).forEach((key) => {
        if (key.selected) {
          Object.assign(selected, this.copyDeviceItem(key));
        }
      });
      let index = 0;
      let newName = selected.name + index;
      let needMoreName = true;
      while (needMoreName) {
        index += 1;
        newName = selected.name + index;
        let found = false;
        Object.values(this.services).forEach((key) => {
          if (key.name == newName) {
            found = true;
          }
        });
        if (!found) {
          needMoreName = false;
          break;
        }
      }
      selected.name = newName;
      if (selected.param_data) {
        selected.param_data.name = newName;
      }
      selected.internal_id = null;
      selected.params_internal_id = null;
      this.setAddDeviceItem(selected);
      this.addOrUpdateDevice();
    },
    addOrUpdateDevice() {
      this.setRightContent("AddOrUpdateDevice");
    },
    deleteDevice() {
      const deleteParamIds = [];
      const deleteServiceIds = [];
      const selectedDataName = [];
      Object.values(this.services).forEach((key) => {
        if (key.selected) {
          selectedDataName.push(key.name);
          deleteParamIds.push({ internal_id: key.params_internal_id });
          deleteServiceIds.push({ internal_id: key.internal_id });
        }
      });
      ElMessageBox.confirm(
        "确认删除" +
          selectedDataName.join("、") +
          "吗？如果删除该设备，会一并删除该设备在数据库中的数据。如果要保留数据，建议采用【修改】操作",
        "确认删除",
        {
          confirmButtonText: "删除",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          console.log("delete start");
          const loading = ElLoading.service({
            lock: true,
            text: "删除中...",
            background: "rgba(0, 0, 0, 0.7)",
          });
          setTimeout(() => {
            loading.close();
          }, 20000);
          deleteParamService(
            deleteParamIds,
            (data) => {
              loading.close();
              console.log("data", data);
              if (data && data.data) {
                if (data.data.code > 0) {
                  this.deleteServices(deleteServiceIds);
                } else {
                  ElMessage.error("网络错误3：" + JSON.stringify(data));
                }
              } else {
                ElMessage.error("网络错误2：" + JSON.stringify(data));
              }
            },
            (err) => {
              loading.close();
              console.log("err", err);
              ElMessage.error("网络错误1：" + JSON.stringify(err));
            }
          );
        })
        .catch((err) => {
          console.log("delete canceled", err);
          if ("cancel" != err) {
            ElMessage.error("错误：" + JSON.stringify(err));
          }
        });
    },
  },
  setup() {
    const deviceData = inject("deviceData");
    const menuData = inject("menuData");
    const dialogFormVisible = ref(false);
    const dropDownVisible = ref(false);

    const data = {
      ...toRefs(deviceData),
      ...toRefs(menuData),
      dialogFormVisible,
      dropDownVisible,
    };
    return data;
  },
};
</script>

<style scoped>
.row {
  width: 100%;
}

.row-divider {
  width: 100%;
  margin-top: 0px;
}

.line-divider {
  background-color: #bbbbbb;
  width: 1px;
  height: 30px;
  margin-right: 10px;
}

.title {
  font-size: medium;
  font-weight: bold;
}

.sub-command {
  margin: 10px;
  font-size: 20px;
}

.el-button {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
}
</style>
