<template>
  <div class="edit_info" v-loading="loading">
    <el-drawer
      v-model="showFlag"
      :direction="direction"
      :title="titleSendToClipboardInfo"
      size="80%"
      @close="hide"
      :lock-scroll="false"
    >
      <template #default>
        <div class="tips">
          <div class="tips-title">说明:</div>
          <div class="tips-content">
            {{ "输入英文大括号 {} ，然后选择变量名称，表示将输出改变了内容" }}
          </div>
          <div class="tips-content">
            {{
              "输入英文尖括号 <> ，然后选择输入的按键，表示将输出一个键盘按键"
            }}
          </div>
        </div>
        <div class="input_content">
          <div class="input_flex">
            <div class="input_tips">编辑</div>
            <el-input
              id="input_content"
              v-model="clipboardInfo"
              :autosize="{ minRows: 4 }"
              type="textarea"
              placeholder="请按照上面说明编辑您的发送到剪切板配置"
              @input="onClipboardInfoInput"
              :autofocus="true"
            />

            <div class="input_tips input_tips_valid">有效配置</div>
            <el-space wrap v-if="this.clipboardInfoObj" class="input_valid_bg">
              <div v-for="item in this.clipboardInfoObj" :key="item">
                <div
                  v-if="typeof item === 'object' && item.precision"
                  class="input_valid_variable_number"
                >
                  {{
                    " { " +
                    item.itemKey +
                    (item.precision ? " ." + item.precision + "f" : "") +
                    " } "
                  }}
                </div>
                <div
                  v-else-if="typeof item === 'object'"
                  class="input_valid_variable"
                >
                  {{
                    " { " +
                    item.itemKey +
                    (item.precision ? " ." + item.precision + "f" : "") +
                    " } "
                  }}
                </div>
                <div v-else-if="item === 'enter'" class="input_valid_key_enter">
                  {{ " < " + item + " > " }}
                </div>
                <div v-else class="input_valid_key">
                  {{ " < " + item + " > " }}
                </div>
              </div>
            </el-space>

            <el-button class="btn" @click="save" :disabled="saveDisabled">
              保存
            </el-button>
          </div>
          <div class="input_flex">
            <div class="input_tips">预览</div>
            <div
              class="input_html"
              v-if="previewHtml"
              v-html="previewHtml"
            ></div>
            <div v-else class="input_preview_empty">
              暂无法预览、请继续完成编辑
            </div>
          </div>
        </div>
      </template>
    </el-drawer>

    <div
      class="dialogPrecision"
      :style="{ left: dialogPrecisionLeft, top: dialogPrecisionTop }"
      v-if="dialogPrecisionVisible"
    >
      <PinyinSelect
        id="dialogPrecision"
        :style="{ 'min-width': '300px' }"
        v-model="dialogPrecision"
        :options="dialogPrecisionList"
        :props="{
          label: 'name',
          value: 'name',
        }"
        @optionClick="dialogPrecisionClose"
        @cancelSelect="dialogPrecisionClose"
        :placeholder="`请选择 ${this.dialogVariable} 的精度，默认 ${this.dialogPrecisionDefault}`"
      />
    </div>

    <div
      class="dialogVariable"
      :style="{ left: dialogVariableLeft, top: dialogVariableTop }"
      v-if="dialogVariableVisible"
    >
      <PinyinSelect
        id="dialogVariable"
        :style="{ 'min-width': '200px' }"
        v-model="dialogVariable"
        :options="dialogVariableList"
        :props="{
          label: 'tips',
          value: 'name',
        }"
        @optionClick="dialogVariableClose"
        @cancelSelect="dialogVariableClose"
        placeholder="请选择按键（可拼音搜索）"
      />
    </div>

    <div
      class="dialogKey"
      :style="{ left: dialogKeyLeft, top: dialogKeyTop }"
      v-if="dialogKeyVisible"
    >
      <PinyinSelect
        id="dialogKey"
        :style="{ 'min-width': '200px' }"
        v-model="dialogKey"
        :options="dialogKeyList"
        :props="{
          label: 'name',
          value: 'name',
        }"
        @optionClick="dialogKeyClose"
        @cancelSelect="dialogKeyClose"
        placeholder="请选择按键（可拼音搜索）"
      />
    </div>
  </div>
</template>

<script>
import { inject, toRefs, ref } from "vue";
import { setConfig } from "../api/device";
import {
  getClipboardInfoObj,
  getClipboardCopyText,
  outputTableVariableParams,
  outputTableKeyParams,
  getFocusPosition,
  precisionValues,
} from "../util/clipboard_util";
import PinyinSelect from "./PinyinSelect.vue";

const defaultDataItemSelected = {
  time: 1637863378000000000,
  experiment_internal_id: "20211108_2228fulltest",
  internal_id: "e13ad00fa3f8b57280730a0d8877e55b",
  device_name: "ccr",
  data: "-0.008082699999994248",
  unit: "mg",
  ccr_raw_info: {
    JobName: "fulltest",
    Nr: "8",
    timeStamp: "2021-11-26T02:02:58",
    cycles: 5,
    scale: "S",
    method: "ABBA",
    preCycles: "1",
    centerCycles: "0",
    delay: "25",
    integration: "5",
    profile: "E1",
    A: "F3",
    A2: "",
    B1: "F4",
    B2: "",
    B3: "",
    B4: "",
    nettoA: "0.2",
    nettoA2: "0",
    nettoB1: "0.2",
    nettoB2: "0",
    nettoB3: "0",
    nettoB4: "0",
    nameA: "200mg*_REF002_TARE01",
    nameA2: "",
    nameB1: "200mg*_TEST002_TARE02",
    nameB2: "",
    nameB3: "",
    nameB4: "",
    comparex: "0",
    Result: "0",
    positionA: "F3",
    positionB: "F4",
    nominalA: "0.2",
    nominalB: "0.2",
    nameB: "200mg*_TEST002_TARE02",
    ccr_file_name: "20211108_2228fulltest",
    ccr_date: 1637863378000000000,
    measure_data: {
      size: 4,
      list: [
        {
          seq: "A1",
          num: 203.526777,
          density_air_raw: 1.1637349061610698,
          cycle: "0",
          diff: -0.007933500000007143,
          mean: -0.008082699999994248,
        },
        {
          seq: "B1",
          num: 203.521654,
          density_air_raw: 1.1637349061610698,
        },
        {
          seq: "B2",
          num: 203.524231,
          density_air_raw: 1.1637349061610698,
        },
        {
          seq: "A2",
          num: 203.534975,
          density_air_raw: 1.1637349061610698,
        },
        {
          seq: "A1",
          num: 203.535669,
          density_air_raw: 1.1637349061610698,
          cycle: "1",
          diff: -0.00820749999999748,
        },
        {
          seq: "B1",
          num: 203.529377,
          density_air_raw: 1.1637349061610698,
        },
        {
          seq: "B2",
          num: 203.531131,
          density_air_raw: 1.1637349061610698,
        },
        {
          seq: "A2",
          num: 203.541254,
          density_air_raw: 1.1637349061610698,
        },
        {
          seq: "A1",
          num: 203.541558,
          density_air_raw: 1.1637349061610698,
          cycle: "2",
          diff: -0.008051999999992177,
        },
        {
          seq: "B1",
          num: 203.535254,
          density_air_raw: 1.1637349061610698,
        },
        {
          seq: "B2",
          num: 203.536692,
          density_air_raw: 1.1637349061610698,
        },
        {
          seq: "A2",
          num: 203.546492,
          density_air_raw: 1.1637349061610698,
        },
        {
          seq: "A1",
          num: 203.546567,
          density_air_raw: 1.1637349061610698,
          cycle: "3",
          diff: -0.0080664999999982,
        },
        {
          seq: "B1",
          num: 203.540138,
          density_air_raw: 1.1637349061610698,
        },
        {
          seq: "B2",
          num: 203.541542,
          density_air_raw: 1.1637349061610698,
        },
        {
          seq: "A2",
          num: 203.551246,
          density_air_raw: 1.1637349061610698,
        },
        {
          seq: "A1",
          num: 203.55115,
          density_air_raw: 1.1637349061610698,
          cycle: "4",
          diff: -0.008153999999976236,
        },
        {
          seq: "B1",
          num: 203.544315,
          density_air_raw: 1.1637349061610698,
        },
        {
          seq: "B2",
          num: 203.545677,
          density_air_raw: 1.1637349061610698,
        },
        {
          seq: "A2",
          num: 203.55515,
          density_air_raw: 1.1637349061610698,
        },
      ],
      unit: "mg",
    },
    unit: "mg",
    comparator: "CCR10-1000",
    density_air_raw: 1.1637349061610698,
    mean_mg: -0.008082699999994248,
    calculate_result: {
      comparator: "CCR10-1000",
      nameA: "200mg*_REF002_TARE01",
      nameB: "200mg*_TEST002_TARE02",
      method: "ABBA",
      cycles: 5,
      delta_pan_mg: 0.002,
      delta_indication_mg: -0.010082699999994248,
      sd_delta_indication_mg: 0.00010502892458079056,
      boyancy_corr_mg: -0.000003435297487972015,
      delta_mass_mg: -0.010086135297482219,
      mass_ref_g: 0.20032,
      mass_test_g: 0.2003099138647025,
      mass_corr_test_mg: 0.3099138647025086,
      eu_mass_test_mg: 0.040017115999044534,
    },
    uncertain_calculate: {
      u_weighing_mg: 0.00004697036299326926,
      repeatability_mg: 0.0002,
      cycles: 5,
      u_mass_ref_mg: 0.02,
      u_boyancy_corr_mg: 0.000013674986168875182,
      delta_mass_mg: -0.010086135297482219,
      mass_ref_g: 0.20032,
      density_ref_kg: 7980,
      u_density_ref_kg: 50,
      density_test_kg: 7950,
      u_density_test_kg: 70,
      density_air_kg: 1.1637349061610698,
      u_density_air_kg: 0.1,
      u_comparator_sys_mg: 0.0005,
      u_delta_pan_mg: 0.0003,
      cu_mass_test_mg: 0.020008557999522267,
      eu_mass_test_mg: 0.040017115999044534,
    },
    delta_mass_mg: -0.010086135297482219,
    unique_keys: "558b2303fec48a28df12908bdd1bf99b",
  },
  ccr_s: "0.00010502892458079056",
};

export default {
  name: "EditSendToClipboardInfo",
  props: {
    msg: String,
  },
  components: {
    PinyinSelect,
  },
  setup() {
    const deviceData = inject("deviceData");
    const showFlag = ref(false);
    const direction = ref("rtl");
    const titleSendToClipboardInfo = ref("编辑发送到剪切板配置");
    const loading = ref(false);
    const clipboardInfo = ref(
      (deviceData.sendToClipboardInfo.value || {}).data || ""
    );
    const lastClipboardInfo = ref(
      (deviceData.sendToClipboardInfo.value || {}).data || ""
    );
    const clipboardInfoObj = ref(null);
    const dialogPrecisionVisible = ref(false);
    const dialogPrecision = ref("");
    const dialogPrecisionDefault = ref("");
    const dialogPrecisionLeft = ref("0px");
    const dialogPrecisionTop = ref("0px");
    const dialogPrecisionList = ref(precisionValues);
    const dialogVariableVisible = ref(false);
    const dialogVariable = ref("");
    const dialogVariableLeft = ref("0px");
    const dialogVariableTop = ref("0px");
    const dialogVariableList = ref(outputTableVariableParams);
    const dialogKeyVisible = ref(false);
    const dialogKey = ref("");
    const dialogKeyLeft = ref("0px");
    const dialogKeyTop = ref("0px");
    const dialogKeyList = ref(outputTableKeyParams);
    const saveDisabled = ref(true);
    const insertPrefix = ref("");
    const insertSuffix = ref("");
    const previewHtml = ref("");
    const activeDataItemSelected = ref(null);

    const data = {
      ...toRefs(deviceData),
      showFlag,
      direction,
      titleSendToClipboardInfo,
      loading,
      clipboardInfo,
      lastClipboardInfo,
      clipboardInfoObj,
      dialogPrecisionVisible,
      dialogPrecision,
      dialogPrecisionDefault,
      dialogPrecisionLeft,
      dialogPrecisionTop,
      dialogPrecisionList,
      dialogVariableVisible,
      dialogVariable,
      dialogVariableLeft,
      dialogVariableTop,
      dialogVariableList,
      dialogKeyVisible,
      dialogKey,
      dialogKeyLeft,
      dialogKeyTop,
      dialogKeyList,
      saveDisabled,
      insertPrefix,
      insertSuffix,
      previewHtml,
      activeDataItemSelected,
    };
    return data;
  },
  methods: {
    show(activeDataItemSelected) {
      this.activeDataItemSelected = activeDataItemSelected;
      this.clipboardInfo = (this.sendToClipboardInfo.value || {}).data || "";
      this.lastClipboardInfo =
        (this.sendToClipboardInfo.value || {}).data || "";
      this.setClipboardInfo(this.clipboardInfo);
      this.saveDisabled = true;
      this.showFlag = true;
    },

    hide() {
      this.showFlag = false;
      this.dialogPrecisionVisible = false;
      this.dialogVariableVisible = false;
      this.dialogKeyVisible = false;
    },

    save() {
      this.loading = true;
      setConfig(
        { ...this.sendToClipboardInfo, value: { data: this.clipboardInfo } },
        (res) => {
          if (res && res.data && res.data.data) {
            this.sendToClipboardInfo = res.data.data;
          }
          this.loading = false;
          console.log("setConfig res", res);
          this.hide();
        },
        (err) => {
          this.loading = false;
          console.log("setConfig err", err);
        }
      );
    },

    onClipboardInfoInput(value) {
      this.insertPrefix = "";
      this.insertSuffix = "";
      let addValue = "";
      let info = this.lastClipboardInfo;
      if (!info) {
        addValue = value;
      } else if (value) {
        for (
          let index = 0;
          index < info.length && index < value.length;
          index++
        ) {
          if (value.startsWith(info.substring(0, index + 1))) {
            this.insertPrefix = info.substring(0, index + 1);
          }
        }
        for (
          let index = 0;
          index < info.length - this.insertPrefix.length &&
          index < value.length - this.insertPrefix.length;
          index++
        ) {
          if (
            value.endsWith(info.substring(info.length - index - 1, info.length))
          ) {
            this.insertSuffix = info.substring(
              info.length - index - 1,
              info.length
            );
          }
        }
        addValue = value.substring(
          this.insertPrefix.length,
          value.length - this.insertSuffix.length
        );
      }
      try {
        if (addValue == "{") {
          const a = document.getElementById("input_content");
          const pos = getFocusPosition.getInputPositon(a);
          this.dialogVariableLeft = pos.left + "px";
          this.dialogVariableTop = pos.top + "px";
          this.dialogKeyVisible = false;
          this.dialogPrecisionVisible = false;
          this.dialogVariableVisible = true;
          this.dialogVariable = "";
          document.getElementById("input_content").blur();
          this.$nextTick(() => {
            document.getElementById("dialogVariable").focus();
          });
        } else if (addValue == "<") {
          const a = document.getElementById("input_content");
          const pos = getFocusPosition.getInputPositon(a);
          this.dialogKeyLeft = pos.left + "px";
          this.dialogKeyTop = pos.top + "px";
          this.dialogPrecisionVisible = false;
          this.dialogVariableVisible = false;
          this.dialogKeyVisible = true;
          this.dialogKey = "";
          document.getElementById("input_content").blur();
          this.$nextTick(() => {
            document.getElementById("dialogKey").focus();
          });
        } else {
          info = value;
        }
      } catch (e) {
        info = value;
      }
      this.setClipboardInfo(info);
    },

    setClipboardInfo(info) {
      if (info != this.clipboardInfo) {
        this.clipboardInfo = info;
      }
      this.previewHtml = "";
      this.clipboardInfoObj = getClipboardInfoObj(this.clipboardInfo);
      this.lastClipboardInfo = this.clipboardInfo;
      this.saveDisabled =
        ((this.sendToClipboardInfo.value || {}).data || "") ==
        this.clipboardInfo;
      if (!this.clipboardInfoObj) {
        if (
          !this.clipboardInfo &&
          this.sendToClipboardInfo.value &&
          this.sendToClipboardInfo.value.data
        ) {
          this.saveDisabled = false;
        } else {
          this.saveDisabled = true;
        }
      } else {
        const list = [];
        if (this.activeDataItemSelected) {
          list.push(this.activeDataItemSelected, this.activeDataItemSelected);
        } else {
          list.push(defaultDataItemSelected, defaultDataItemSelected);
        }
        this.previewHtml = getClipboardCopyText(
          this.clipboardInfoObj,
          list
        ).html;
      }
    },

    dialogPrecisionClose() {
      if (!this.dialogPrecisionVisible) {
        console.log("dialogPrecisionClose twice");
        return;
      }
      this.dialogPrecisionVisible = false;
      let selection = -1;
      if (this.dialogVariable) {
        let info =
          this.insertPrefix +
          ` { ${this.dialogVariable} .${
            this.dialogPrecision || this.dialogPrecisionDefault
          }f } `;
        selection = info.length;
        this.setClipboardInfo(info + this.insertSuffix);
      }
      this.dialogPrecision = "";
      this.dialogVariable = "";

      try {
        document.getElementById("dialogPrecision").blur();
        this.$nextTick(() => {
          document.getElementById("input_content").focus();
          if (selection >= 0) {
            document
              .getElementById("input_content")
              .setSelectionRange(selection, selection);
          }
        });
      } catch (e) {
        //
      }
    },

    dialogVariableClose() {
      if (!this.dialogVariableVisible) {
        console.log("dialogVariableClose twice");
        return;
      }
      const itemSelect = outputTableVariableParams.find(
        (item) => item.name === this.dialogVariable
      );
      if (itemSelect && itemSelect.isNumber) {
        this.dialogVariableVisible = false;
        let selection = this.insertPrefix.length;

        try {
          document.getElementById("dialogVariable").blur();
          this.$nextTick(() => {
            document.getElementById("input_content").focus();
            if (selection >= 0) {
              document
                .getElementById("input_content")
                .setSelectionRange(selection, selection);
            }
            this.$nextTick(() => {
              const a = document.getElementById("input_content");
              const pos = getFocusPosition.getInputPositon(a);
              this.dialogPrecisionLeft = pos.left + "px";
              this.dialogPrecisionTop = pos.top + "px";
              this.dialogKeyVisible = false;
              this.dialogPrecisionVisible = true;
              this.dialogVariableVisible = false;
              this.dialogPrecision = "";
              this.dialogPrecisionDefault = itemSelect.precision;
              document.getElementById("input_content").blur();
              this.$nextTick(() => {
                document.getElementById("dialogPrecision").focus();
              });
            });
          });
        } catch (e) {
          //
        }
      } else {
        this.dialogVariableVisible = false;
        let selection = -1;
        if (this.dialogVariable) {
          let info = this.insertPrefix + ` { ${this.dialogVariable} } `;
          selection = info.length;
          this.setClipboardInfo(info + this.insertSuffix);
        }
        this.dialogVariable = "";

        try {
          document.getElementById("dialogVariable").blur();
          this.$nextTick(() => {
            document.getElementById("input_content").focus();
            if (selection >= 0) {
              document
                .getElementById("input_content")
                .setSelectionRange(selection, selection);
            }
          });
        } catch (e) {
          //
        }
      }
    },

    dialogKeyClose() {
      if (!this.dialogKeyVisible) {
        console.log("dialogKeyClose twice");
        return;
      }
      let selection = -1;
      this.dialogKeyVisible = false;
      if (this.dialogKey) {
        let info = this.insertPrefix + ` < ${this.dialogKey} > `;
        selection = info.length;
        this.setClipboardInfo(info + this.insertSuffix);
      }
      this.dialogKey = "";
      try {
        document.getElementById("dialogKey").blur();
        this.$nextTick(() => {
          document.getElementById("input_content").focus();
          if (selection >= 0) {
            document
              .getElementById("input_content")
              .setSelectionRange(selection, selection);
          }
        });
      } catch (e) {
        //
      }
    },
  },
};
</script>
<style scoped>
.edit_info .title {
  width: 100%;
  text-align: start;
  margin: 10px 0px;
}

.edit_info .el-drawer__body {
  overflow: auto;
}

:deep(.el-drawer__close-btn) {
  position: fixed !important;
}

.tips {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
}

.input_content {
  margin: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.input_flex {
  flex: 1;
  display: flex;
  width: 0px;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
}

.input_tips {
  font-size: 12px;
  padding-bottom: 10px;
  padding-left: 10px;
}

.input_tips_valid {
  margin-top: 10px;
}

.input_valid_bg {
  border: 1px solid #d4d7df;
  padding: 10px;
}

.input_valid_variable {
  color: #ffffff;
  background-color: #646bd9;
}

.input_valid_variable_number {
  color: #ffffff;
  background-color: #6c98c6;
}

.input_valid_key {
  color: #ffffff;
  background-color: #ff7fae;
}

.input_valid_key_enter {
  color: #ffffff;
  background-color: #e25a53;
}

.input_html {
  overflow-x: auto;
  width: 100%;
}

.input_preview_empty {
  width: 100%;
  height: 200px;
  line-height: 200px;
  text-align: center;
}

.btn {
  margin: 20px;
  padding: 20px 80px;
}

.tips-content {
  font-size: 14px;
}

.tips-title {
  font-size: 18px;
}

.dialogPrecision {
  position: absolute;
  z-index: 9000;
}

.dialogVariable {
  position: absolute;
  z-index: 9000;
}

.dialogKey {
  position: absolute;
  z-index: 9000;
}

:deep(table) {
  table-layout: fixed;
  word-break: break-all;
  word-wrap: break-word;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
}

:deep(td) {
  padding: 10px;
}
</style>
