<template>
  <div class="edit_info">
    <el-drawer
      v-model="showFlag"
      :direction="direction"
      title="砝码设备信息"
      size="80%"
      :lock-scroll="false"
    >
      <template #default>
        <el-collapse>
          <el-collapse-item name="titleStandardDevice">
            <template #title>
              <div class="title">{{ titleStandardDevice }}</div>
            </template>
            <EditDrawerTableItemInfo
              :type="titleStandardDevice"
              :title="titleStandardDevice"
              :getDrawerTableItemInfo="getStandardDeviceInfo"
              :addDrawerTableItemInfo="addStandardDeviceInfo"
              :deleteDrawerTableItemInfo="deleteStandardDeviceInfo"
              :onDrawerTableItemInfoChanged="getCalculateResultUpdateStatus"
              :uniqueKeys="uniqueKeysStandardDevice"
              :drawerTableInfo="drawerTableInfoStandardDevice"
            />
          </el-collapse-item>

          <el-collapse-item name="titleTestedWeightUnitedInfo">
            <template #title>
              <div class="title">{{ titleTestedWeightUnitedInfo }}</div>
            </template>
            <EditDrawerTableItemInfo
              :type="titleTestedWeightUnitedInfo"
              :title="titleTestedWeightUnitedInfo"
              :getDrawerTableItemInfo="getTestedWeightUnitedInfo"
              :addDrawerTableItemInfo="addTestedWeightUnitedInfo"
              :deleteDrawerTableItemInfo="deleteTestedWeightUnitedInfo"
              :onDrawerTableItemInfoChanged="getCalculateResultUpdateStatus"
              :uniqueKeys="uniqueKeysTestedWeightUnitedInfo"
              :drawerTableInfo="drawerTableInfoTestedWeightUnitedInfo"
            />
          </el-collapse-item>

          <el-collapse-item name="titleTestedWeightInfo">
            <template #title>
              <div class="title">{{ titleTestedWeightInfo }}</div>
            </template>
            <EditDrawerTableItemInfo
              :type="titleTestedWeightInfo"
              :title="titleTestedWeightInfo"
              :getDrawerTableItemInfo="getTestedWeightInfo"
              :addDrawerTableItemInfo="addTestedWeightInfo"
              :deleteDrawerTableItemInfo="deleteTestedWeightInfo"
              :onDrawerTableItemInfoChanged="getCalculateResultUpdateStatus"
              :uniqueKeys="uniqueKeysTestedWeightInfo"
              :drawerTableInfo="drawerTableInfoTestedWeightInfo"
              :checkKeyValid="checkKeyValidTableInfoTestedWeightInfo"
            />
          </el-collapse-item>

          <el-collapse-item name="titleEnvironmentParamsInfo">
            <template #title>
              <div class="title">{{ titleEnvironmentParamsInfo }}</div>
            </template>
            <EditDrawerTableItemInfo
              :type="titleEnvironmentParamsInfo"
              :title="titleEnvironmentParamsInfo"
              :getDrawerTableItemInfo="getEnvironmentParamsInfo"
              :addDrawerTableItemInfo="addEnvironmentParamsInfo"
              :deleteDrawerTableItemInfo="deleteEnvironmentParamsInfo"
              :onDrawerTableItemInfoChanged="getCalculateResultUpdateStatus"
              :uniqueKeys="uniqueKeysEnvironmentParamsInfo"
              :drawerTableInfo="drawerTableInfoEnvironmentParamsInfo"
              :maxSize="1"
              :hideDelete="true"
            />
          </el-collapse-item>

          <el-collapse-item name="titlePalletMassDifferenceInfo">
            <template #title>
              <div class="title">{{ titlePalletMassDifferenceInfo }}</div>
            </template>
            <EditDrawerTableItemInfo
              :type="titlePalletMassDifferenceInfo"
              :title="titlePalletMassDifferenceInfo"
              :getDrawerTableItemInfo="getPalletMassDifferenceInfo"
              :addDrawerTableItemInfo="addPalletMassDifferenceInfo"
              :deleteDrawerTableItemInfo="deletePalletMassDifferenceInfo"
              :onDrawerTableItemInfoChanged="getCalculateResultUpdateStatus"
              :uniqueKeys="uniqueKeysPalletMassDifferenceInfo"
              :drawerTableInfo="drawerTableInfoPalletMassDifferenceInfo"
            />
          </el-collapse-item>
        </el-collapse>

        <el-button
          class="calculate_result_btn"
          @click="calculateResultUpdate"
          :disabled="!calculateResultNeedUpdate"
          :color="calculateResultNeedUpdate ? '#F56C6C' : ''"
          >更新至当前数据表</el-button
        >
      </template>
    </el-drawer>
  </div>
</template>

<script>
import { inject, toRefs, ref } from "vue";
import { ElMessage, ElLoading } from "element-plus";
import {
  getStandardDeviceInfo,
  addStandardDeviceInfo,
  deleteStandardDeviceInfo,
  getTestedWeightUnitedInfo,
  addTestedWeightUnitedInfo,
  deleteTestedWeightUnitedInfo,
  getTestedWeightInfo,
  addTestedWeightInfo,
  deleteTestedWeightInfo,
  getEnvironmentParamsInfo,
  addEnvironmentParamsInfo,
  deleteEnvironmentParamsInfo,
  getPalletMassDifferenceInfo,
  addPalletMassDifferenceInfo,
  deletePalletMassDifferenceInfo,
  needUpdateCalculateResults,
  updateCalculateResults,
} from "../api/device";
import { getWeightScopeRange, formatCompareData } from "../util/formats_util";
import EditDrawerTableItemInfo from "./EditDrawerTableItemInfo.vue";

const uniqueKeysStandardDevice = ["name", "serial"];
const drawerTableInfoStandardDevice = [
  {
    prop: "name",
    label: "型号规格",
    tooltips:
      "匹配“计算结果”表的名称A列，可以输入：<br /><br />“型号规格”——代表唯一性数据，<br />“?”——代表该批次所有型号规格砝码，<br /><br />匹配时先按更严格条件匹配（“型号规格”>“?”)",
  },
  {
    prop: "serial",
    label: "编号",
    tooltips:
      "匹配“计算结果”表的名称A列，可以输入：<br /><br />“出厂编号”——代表唯一性数据，<br />“?”——代表该批次所有出厂编号砝码，<br />“出厂编号1_出厂编号2”——代表所有出厂编号1或者出厂编号2的砝码，<br /><br />匹配时先按更严格条件匹配（“出厂编号”>“出厂编号1_出厂编号2”>“?”)",
  },
  {
    prop: "level",
    label: "准确度等级",
    type: "select",
    defaultValue: "E1",
    options: ["E0", "E1", "E2", "F1", "F2"],
  },
  {
    prop: "correction",
    label: "约定质量值 [g]",
    type: "number",
    precision: 6,
  },
  {
    prop: "standard_uncertainty",
    label: "标准不确定度 [mg]",
    type: "number",
    precision: 6,
  },
  {
    prop: "duration",
    label: "有效期",
    type: "date",
  },
];

const uniqueKeysTestedWeightUnitedInfo = ["name", "serial"];
const drawerTableInfoTestedWeightUnitedInfo = [
  {
    prop: "name",
    label: "型号规格",
    tooltips:
      "匹配“计算结果”表的名称A和名称B列，可以输入：<br /><br />“型号规格”——代表唯一性数据，<br />“?”——代表该批次所有型号规格砝码，<br /><br />匹配时先按更严格条件匹配（“型号规格”>“?”)",
  },
  {
    prop: "serial",
    label: "编号",
    tooltips:
      "匹配“计算结果”表的名称A名称B列，可以输入：<br /><br />“出厂编号”——代表唯一性数据，<br />“?”——代表该批次所有出厂编号砝码，<br />“出厂编号1_出厂编号2”——代表所有出厂编号1或者出厂编号2的砝码，<br /><br />匹配时先按更严格条件匹配（“出厂编号”>“出厂编号1_出厂编号2”>“?”)",
  },
  {
    prop: "density",
    label: "密度 [kg/m³]",
    type: "number",
    precision: 6,
  },
  {
    prop: "density_uncertainty",
    label: "标准不确定度 [kg/m³]",
    type: "number",
    precision: 6,
  },
  {
    prop: "duration",
    label: "有效期",
    type: "date",
  },
];

const uniqueKeysTestedWeightInfo = ["name", "scope"];
const drawerTableInfoTestedWeightInfo = [
  {
    prop: "name",
    label: "名称",
  },
  {
    prop: "scope",
    label: "应用范围",
    tooltips:
      "输入一个数值范围，格式是 <br />最小范围数值 + 单位 + 中划线（-） + 最大数值范围 + 单位 <br />采用左包围规则，可以等于最小范围，不能等于最大范围",
  },
  {
    prop: "readability",
    label: "可读性 [mg]",
    type: "number",
    precision: 6,
  },
  {
    prop: "repeatability",
    label: "重复性 [mg]",
    type: "number",
    precision: 6,
  },
  {
    prop: "extended_uncertainty",
    label: "B类标准不确定度 [mg]",
    type: "number",
    precision: 6,
  },
  {
    prop: "duration",
    label: "有效期",
    type: "date",
  },
];

const uniqueKeysEnvironmentParamsInfo = ["name"];
const drawerTableInfoEnvironmentParamsInfo = [
  {
    prop: "value",
    label: "数值 [kg/m³]",
    type: "实时",
    precision: 3,
  },
  {
    prop: "extended_uncertainty",
    label: "标准不确定度 [kg/m³]",
    type: "number",
    precision: 6,
  },
  {
    prop: "duration",
    label: "有效期",
    type: "date",
  },
];

const uniqueKeysPalletMassDifferenceInfo = ["pallet_a", "pallet_b"];
const drawerTableInfoPalletMassDifferenceInfo = [
  {
    prop: "pallet_a",
    label: "托盘 A",
  },
  {
    prop: "pallet_b",
    label: "托盘 B",
  },
  {
    prop: "delta",
    label: "托盘B - 托盘A重量差值 [mg]",
    type: "number",
    precision: 6,
  },
  {
    prop: "extended_uncertainty",
    label: "标准不确定度 [mg]",
    type: "number",
    precision: 6,
  },
  {
    prop: "duration",
    label: "有效期",
    type: "date",
  },
];

export default {
  name: "EditStandardDeviceInfo",
  props: {
    msg: String,
    onUpdateCalculateResults: Function,
  },
  components: {
    EditDrawerTableItemInfo,
  },
  setup() {
    const deviceData = inject("deviceData");
    const showFlag = ref(false);
    const direction = ref("rtl");
    const titleStandardDevice = ref("标准砝码");
    const titleTestedWeightUnitedInfo = ref("标准和被测砝码密度");
    const titleTestedWeightInfo = ref("比较仪");
    const titleEnvironmentParamsInfo = ref("空气密度");
    const titlePalletMassDifferenceInfo = ref("托盘");
    const calculateResultNeedUpdate = ref(false);

    const data = {
      ...toRefs(deviceData),
      showFlag,
      direction,
      titleStandardDevice,
      uniqueKeysStandardDevice,
      drawerTableInfoStandardDevice,
      getStandardDeviceInfo,
      addStandardDeviceInfo,
      deleteStandardDeviceInfo,
      titleTestedWeightUnitedInfo,
      uniqueKeysTestedWeightUnitedInfo,
      drawerTableInfoTestedWeightUnitedInfo,
      getTestedWeightUnitedInfo,
      addTestedWeightUnitedInfo,
      deleteTestedWeightUnitedInfo,
      titleTestedWeightInfo,
      uniqueKeysTestedWeightInfo,
      drawerTableInfoTestedWeightInfo,
      getTestedWeightInfo,
      addTestedWeightInfo,
      deleteTestedWeightInfo,
      titleEnvironmentParamsInfo,
      uniqueKeysEnvironmentParamsInfo,
      drawerTableInfoEnvironmentParamsInfo,
      getEnvironmentParamsInfo,
      addEnvironmentParamsInfo,
      deleteEnvironmentParamsInfo,
      titlePalletMassDifferenceInfo,
      uniqueKeysPalletMassDifferenceInfo,
      drawerTableInfoPalletMassDifferenceInfo,
      getPalletMassDifferenceInfo,
      addPalletMassDifferenceInfo,
      deletePalletMassDifferenceInfo,
      calculateResultNeedUpdate,
    };
    return data;
  },
  mounted() {
    this.getCalculateResultUpdateStatus();
  },
  methods: {
    show() {
      this.showFlag = true;
      this.getCalculateResultUpdateStatus();
    },

    hide() {
      this.showFlag = false;
    },

    checkKeyValidTableInfoTestedWeightInfo(key, value, newValue) {
      if ("scope" === key && value && newValue && value[key] && newValue[key]) {
        const scope = getWeightScopeRange(value[key]);
        const newScope = getWeightScopeRange(newValue[key]);
        if (newScope[0] >= newScope[1]) {
          return "应用范围不符合规范，请点击问号查看规范说明！";
        }
        if (
          formatCompareData(value["name"]) ===
          formatCompareData(newValue["name"])
        ) {
          const errorTips = "应用范围重复，请修改应用范围！";
          if (scope[0] >= newScope[0] && scope[0] < newScope[1]) {
            return errorTips;
          } else if (scope[1] > newScope[0] && scope[1] <= newScope[1]) {
            return errorTips;
          }
        }
      }
      return null;
    },

    getPrams() {
      let allSelect = false;
      if (this.multiDeviceShow) {
        allSelect = this.selectedDataDeviceListIdAll;
      } else {
        allSelect = this.selectedDataListIdAll;
      }
      if (allSelect) {
        return {
          query: {
            deviceNameSelect: this.deviceNameSelect,
            runIdSelect: this.runIdSelect,
            dbDataListCurrentPage: 1,
            dbDataListPageSize: -1,
          },
        };
      } else {
        const internal_ids = [];

        if (this.multiDeviceShow) {
          internal_ids.push(...this.selectedDataDeviceListIds);
        } else {
          Object.keys(this.selectedDataListAllTheDeviceIds).forEach((key) => {
            internal_ids.push(...this.selectedDataListAllTheDeviceIds[key]);
          });
        }
        return {
          internal_ids,
        };
      }
    },

    calculateResultUpdate() {
      const loading = ElLoading.service({
        lock: true,
        text: "正在更新数据，请稍后...",
        background: "rgba(0, 0, 0, 0.7)",
      });
      updateCalculateResults(
        this.getPrams(),
        (data) => {
          loading.close();
          console.log("updateCalculateResults data", data);
          if (data && data.data && data.data.data && data.data.code > 0) {
            this.getCalculateResultUpdateStatus();
            this.onUpdateCalculateResults && this.onUpdateCalculateResults();
            ElMessage.success("已成功更新数据！");
          } else {
            ElMessage.error("更新数据失败！");
          }
        },
        (err) => {
          loading.close();
          console.log("updateCalculateResults err", err);
          ElMessage.error("更新数据失败1！");
        }
      );
    },

    getCalculateResultUpdateStatus() {
      needUpdateCalculateResults(
        this.getPrams(),
        (data) => {
          console.log("needUpdateCalculateResults data", data);
          if (data && data.data && data.data.code > 0) {
            this.calculateResultNeedUpdate = data.data.data === "1";
          }
        },
        (err) => {
          console.log("needUpdateCalculateResults err", err);
        }
      );
    },
  },
};
</script>
<style scoped>
.edit_info .title {
  width: 100%;
  text-align: start;
  margin: 10px 0px;
}

.edit_info .el-drawer__body {
  overflow: auto;
}

:deep(.el-drawer__close-btn) {
  position: fixed !important;
}

.calculate_result_btn {
  margin: 20px;
  padding: 20px 80px;
}
</style>
