<template>
  <el-menu
    @select="menuSelect"
    @open="menuOpen"
    default-active="2"
    mode="horizontal"
    :ellipsis="false"
    background-color="#6666c4"
    text-color="#101010"
    active-text-color="#000000"
  >
    <el-menu-item index="2">
      <el-icon>
        <odometer />
      </el-icon>
      <span>设备</span>
    </el-menu-item>
    <!-- <el-menu-item index="3">
      <el-icon><message-box /></el-icon>
      <span>数据库</span>
    </el-menu-item> -->
    <el-sub-menu index="3" @click="dataSelectDb">
      <template #title>
        <el-icon>
          <message-box />
        </el-icon>
        <span>数据库</span>
      </template>
      <el-menu-item-group>
        <el-menu-item
          v-for="(v, i) in ['上一次查询结果', '全部设备', ...deviceTagNames]"
          :key="'3-' + i"
          :index="'3-' + i"
        >
          <template #title
            >{{ v
            }}<el-icon
              v-if="+i >= 2"
              @click.stop="_removeTag(v)"
              class="close-right"
            >
              <CircleCloseFilled /> </el-icon
          ></template>
        </el-menu-item>
      </el-menu-item-group>
    </el-sub-menu>
    <!-- <el-menu-item index="4">
      <el-icon><setting /></el-icon>
      <span>更多设置</span>
    </el-menu-item> -->
    <el-menu-item index="5">
      <el-icon>
        <user />
      </el-icon>
      <span>软件信息</span>
    </el-menu-item>
    <el-menu-item index="6" v-if="upgradeVersionData && upgradeVersionData.url">
      <div class="upgrade_version_bg">
        <div class="upgrade_text">发现新版本</div>
        <el-tooltip
          v-if="upgradeVersionData.tips"
          class="box-item"
          effect="dark"
          :content="upgradeVersionData.tips"
          placement="top-start"
          :offset="20"
          raw-content
        >
          <el-icon class="upgrade_version"><QuestionFilled /></el-icon>
        </el-tooltip>
        <el-icon v-if="!upgradeVersionData.tips" class="upgrade_version">
          <Opportunity />
        </el-icon>
      </div>
    </el-menu-item>
  </el-menu>
</template>

<script>
import { inject, toRefs, ref } from "vue";
import { ElMessageBox, ElMessage, ElLoading } from "element-plus";
import {
  removeDeviceTag,
  getAllDeviceTags,
  startUpgradeVersion,
} from "../api/device";

export default {
  name: "NavLeft",
  props: {
    msg: String,
  },
  setup() {
    const deviceData = inject("deviceData");
    const menuData = inject("menuData");
    const upgradingTimer = ref(null);

    const data = { ...toRefs(menuData), ...toRefs(deviceData), upgradingTimer };
    return data;
  },
  mounted() {},
  unmounted() {
    this.destroyUpgradingTimer();
  },
  methods: {
    destroyUpgradingTimer() {
      if (this.upgradingTimer) {
        clearInterval(this.upgradingTimer);
        this.upgradingTimer = null;
      }
    },
    createUpgradingTimer() {
      if (!this.isWizConnector) {
        return;
      }
      const loading = ElLoading.service({
        lock: true,
        text: "系统正在升级重启中，请稍后...",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const startTime = Date.now();
      const waitTime = 30 * 1000;
      const callback = () => {
        if (Date.now() - startTime < waitTime) {
          console.log("wait restart");
          return;
        }
        getAllDeviceTags(
          {},
          (data) => {
            console.log("getAllDeviceTags", data);
            if (data && data.data && data.data.data && data.data.code > 0) {
              if (this.upgradingTimer) {
                this.destroyUpgradingTimer();
                loading.close();
                window.location.reload();
              }
            }
          },
          (err) => {
            console.log("getAllDeviceTags err", err);
          }
        );
      };
      callback();
      this.destroyUpgradingTimer();
      this.upgradingTimer = setInterval(() => {
        callback();
      }, 2000);
    },
    menuSelect(e) {
      console.log("menuSelect", e);
      this.updateMenu(e);
    },
    menuOpen(e) {
      console.log("menuOpen", e);
      if (e == "3") {
        getAllDeviceTags(
          {},
          (data) => {
            console.log("getAllDeviceTags", data);
            if (data && data.data && data.data.data && data.data.code > 0) {
              this.setDeviceTags(data.data.data);
              const deviceTagNames = [];
              Object.values(data.data.data).forEach((value) => {
                deviceTagNames.push(value.device_name);
              });
              this.setDeviceTagNames(deviceTagNames);
            }
          },
          (err) => {
            console.log("getAllDeviceTags err", err);
          }
        );
      }
    },
    dataSelectDb(e) {
      var es = e && e.target && e.target.innerText;
      console.log("dataSelectDb", es, e);
      if (es == "数据库" || !es) {
        // this.updateMenu("3");
      }
    },
    _removeTag(tag_name) {
      ElMessageBox.confirm(
        "是否删除名称为：" + tag_name + " 的标签？",
        "删除标签",
        {
          confirmButtonText: "删除",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          const deviceTag = this.deviceTags.find(
            (item) => item.device_name === tag_name
          );
          if (deviceTag) {
            removeDeviceTag(
              deviceTag,
              (data) => {
                console.log("removeDeviceTag data", data);
                if (data && data.data && data.data.data && data.data.code > 0) {
                  this.setDeviceTags(
                    this.deviceTags.filter(
                      (item) => item.device_name !== deviceTag.device_name
                    )
                  );
                  this.setDeviceTagNames(
                    this.deviceTagNames.filter(
                      (item) => item !== deviceTag.device_name
                    )
                  );
                }
              },
              (err) => {
                console.log("removeDeviceTag err", err);
              }
            );
          }
        })
        .catch(() => {});
    },
    updateMenu(e) {
      if (typeof e !== "string") {
        return;
      }
      var esp = (e + "").split("-");
      if (esp[0] == "2") {
        this.setRightContent("BasicRightContent");
      } else if (esp[0] == "5") {
        this.setRightContent("SoftwareAbout");
      } else if (esp[0] == "6") {
        ElMessageBox.confirm(
          "升级新版本会立马重启，请确认没有任何正在运行的设备，否则会停止运行，是否立即升级？",
          "升级新版本",
          {
            confirmButtonText: "立即升级",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            const loading = ElLoading.service({
              lock: true,
              text: "正在升级中，请稍后...",
              background: "rgba(0, 0, 0, 0.7)",
            });
            startUpgradeVersion(
              {},
              (res) => {
                loading.close();
                console.log("startUpgradeVersion data", res);
                if (res && res.data && res.data.data) {
                  ElMessage.success("升级成功，请稍后重新打开页面");
                  this.createUpgradingTimer();
                } else {
                  ElMessage.error("升级失败2");
                }
              },
              (err) => {
                loading.close();
                console.log("startUpgradeVersion err", err);
                ElMessage.error("升级失败1");
              }
            );
          })
          .catch(() => {});
      } else if (esp[0] == "3") {
        var deviceNameEnabledItem = null;
        var lastDeviceQueryEnabled = false;
        var allDeviceQueryEnabled = false;
        if (esp.length == 2) {
          var esp1 = parseInt(esp[1]);
          if (esp1 != 0 && !esp1) {
            esp1 = -1;
          }
          if (esp1 === 0) {
            lastDeviceQueryEnabled = true;
          } else if (esp1 === 1) {
            allDeviceQueryEnabled = true;
          } else {
            esp1 = esp1 - 2;
            if (esp1 >= 0 && this.deviceTags && esp1 < this.deviceTags.length) {
              deviceNameEnabledItem = this.deviceTags[esp1];
            }
          }
        }
        console.log("deviceNameEnabledItem", deviceNameEnabledItem);
        console.log("lastDeviceQueryEnabled", lastDeviceQueryEnabled);
        console.log("allDeviceQueryEnabled", allDeviceQueryEnabled);
        this.deviceNameEnabledItem = deviceNameEnabledItem;
        this.lastDeviceQueryEnabled = lastDeviceQueryEnabled;
        this.allDeviceQueryEnabled = allDeviceQueryEnabled;
        this.notifyLoadingDatabase();
        this.setRightContent("MyDatabase");
      }
    },
    notifyLoadingDatabase() {
      this.$bus.$emit("notifyLoadingDatabase");
    },
  },
};
</script>
<style scoped>
.el-menu-item {
  color: #cbe2f9 !important;
  height: 33px;
}
.el-menu-item.is-active {
  color: #0b5cad !important;
  border-bottom: 2px solid #0b5cad !important;
  background: #ffffff !important;
}

.el-menu--horizontal > .el-sub-menu.is-active .el-sub-menu__title {
  color: #0b5cad !important;
  border-bottom: 2px solid #0b5cad !important;
  background: #ffffff !important;
}

.el-menu--horizontal > .el-sub-menu :deep(.el-sub-menu__title) {
  height: 33px;
  line-height: 33px;
  color: #cbe2f9 !important;
}

.el-menu--horizontal > .el-sub-menu.is-active :deep(.el-sub-menu__title) {
  color: #0b5cad !important;
  border-bottom: 2px solid #0b5cad !important;
  background: #ffffff !important;
}

.el-menu--horizontal {
  border-bottom: none;
}

.close-right {
  position: absolute;
  right: 12px;
}

.el-menu-item-group {
  max-height: 500px !important;
  overflow-y: auto !important;
}

.upgrade_version_bg {
  color: #24663b;
  background-color: #c3e6cd;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 20px;
  border-radius: 18px;
}

.upgrade_version {
  color: coral;
}

.upgrade_text {
  height: 28px;
  line-height: 28px;
}
</style>
