<template>
  <el-space class="container" direction="vertical" alignment="start">
    <div class="row-max divider-middle">
      <div class="title" :style="{ color: this.required_color }">名称 *</div>
      <el-input
        class="title-select"
        v-model="addDeviceItem.Name"
        placeholder="请输入设备名称"
      />
    </div>

    <div class="row-max" v-if="false">
      <div class="title" :style="{ color: this.required_color }">型号 *</div>
      <el-select
        class="title-select"
        filterable
        v-model="addDeviceItem.Model"
        placeholder="请选择设备型号"
      >
        <el-option
          v-for="item in addDeviceItemModelOptions"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
    </div>

    <div class="row-max">
      <div class="title" :style="{ color: this.required_color }">接口 *</div>
      <el-space class="title-select" alignment="start">
        <el-radio-group
          v-model="addDeviceItem.Interface"
          @change="onInterfaceChanged"
        >
          <el-radio
            :disabled="addDeviceItemInterfaceOptionsDisabled.includes(item)"
            v-for="item in addDeviceItemInterfaceOptions"
            :key="item"
            :label="item"
            :value="item"
            >{{ item }}
          </el-radio>
        </el-radio-group>
      </el-space>
    </div>

    <div class="arrow-container">
      <div
        :class="
          'arrow-up' +
          (addDeviceItem.Interface === addDeviceItemInterfaceOptionsTcp
            ? ' arrow-up-tcp'
            : addDeviceItem.Interface ===
              addDeviceItemInterfaceOptionsWebService
            ? ' arrow-up-web-service'
            : addDeviceItem.Interface === addDeviceItemInterfaceOptionsCCR
            ? ' arrow-up-ccr'
            : '')
        "
      ></div>
      <div
        :class="
          'arrow-divider' +
          (addDeviceItem.Interface === addDeviceItemInterfaceOptionsTcp
            ? ' arrow-divider-tcp'
            : addDeviceItem.Interface ===
              addDeviceItemInterfaceOptionsWebService
            ? ' arrow-divider-web-service'
            : addDeviceItem.Interface === addDeviceItemInterfaceOptionsCCR
            ? ' arrow-divider-ccr'
            : '')
        "
      ></div>
      <div class="arrow-bg">
        <div class="arrow-container-inner">
          <div
            class="row-max"
            v-if="
              addDeviceItem.Interface === addDeviceItemInterfaceOptionsRS232
            "
          >
            <div :style="{ color: this.required_color }">COM口 *</div>
            <el-select
              class="select-input"
              filterable
              allow-create
              @change="comChanged"
              @focus="comInputFocus"
              default-first-option
              v-model="addDeviceItem.InterfaceRS232Com"
              placeholder="请选择或者输入COM口"
              :loading="isPortScanning"
              loading-text="正在智能检测中，请稍后..."
              no-data-text="未扫描到任何可用设备"
            >
              <el-option
                v-for="item in addDeviceItemInterfaceRS232ComOptions"
                :key="item.port"
                :label="
                  item.port +
                  ' ' +
                  (item.itemParams ? '（' + item.itemParams + '）' : '')
                "
                :value="item.port"
              >
              </el-option>
            </el-select>
            <el-button v-if="false" @click="checkCom">智能检测</el-button>
          </div>
          <div
            class="row-max"
            v-show="addDeviceItem.InterfaceRS232ComSerialTips"
            v-if="
              addDeviceItem.Interface === addDeviceItemInterfaceOptionsRS232
            "
          >
            <span class="bold">{{
              addDeviceItem.InterfaceRS232ComSerialTips
            }}</span>
          </div>

          <div
            class="row-max"
            v-if="
              addDeviceItem.Interface === addDeviceItemInterfaceOptionsTcp ||
              addDeviceItem.Interface ===
                addDeviceItemInterfaceOptionsWebService ||
              addDeviceItem.Interface === addDeviceItemInterfaceOptionsCCR
            "
          >
            <div class="tips-input" :style="{ color: this.required_color }">
              IP地址 *
            </div>
            <el-input
              class="select-input"
              v-model="addDeviceItem.InterfaceWebServiceAddress"
              placeholder="请输入IP地址"
            />
          </div>
          <div
            class="row-max row-top-divider"
            v-if="
              addDeviceItem.Interface === addDeviceItemInterfaceOptionsTcp ||
              addDeviceItem.Interface === addDeviceItemInterfaceOptionsCCR
            "
          >
            <div class="tips-input" :style="{ color: this.required_color }">
              端口 *
            </div>
            <el-input
              class="select-input"
              v-model="addDeviceItem.InterfaceWebServicePort"
              placeholder="请输入端口"
            />
          </div>
          <div
            class="row-max row-top-divider"
            v-if="addDeviceItem.Interface === addDeviceItemInterfaceOptionsTcp"
          >
            <div class="tips-input" :style="{ color: this.required_color }">
              网络类型 *
            </div>
            <el-select
              class="select-input"
              filterable
              default-first-option
              v-model="addDeviceItem.InterfaceWebServiceProtocolLabel"
              placeholder="请选择网络类型"
              @change="onInterfaceWebServiceProtocolChanged"
            >
              <el-option
                v-for="item in Object.keys(InterfaceWebServiceProtocolOptions)"
                :key="item"
                :label="InterfaceWebServiceProtocolOptions[item]"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
          <div
            class="row-max row-top-divider"
            v-if="
              (addDeviceItem.Interface === addDeviceItemInterfaceOptionsTcp ||
                addDeviceItem.Interface ===
                  addDeviceItemInterfaceOptionsWebService ||
                addDeviceItem.Interface === addDeviceItemInterfaceOptionsCCR) &&
              InterfaceWebServiceProtocolOptionsProtocolValidCCRs.includes(
                addDeviceItem.InterfaceWebServiceProtocol
              )
            "
          >
            <div class="tips-input" :style="{ color: this.required_color }">
              比较仪名称 *
            </div>
            <el-input
              class="select-input"
              v-model="addDeviceItem.InterfaceWebServiceComparator"
              placeholder="请输入比较仪名称"
            />
          </div>

          <div
            v-if="
              !InterfaceWebServiceProtocolOptionsProtocolValidCCRs.includes(
                addDeviceItem.InterfaceWebServiceProtocol
              )
            "
            class="row-divider"
          >
            <span class="bold">应用设置</span>
          </div>
          <div
            v-if="
              !InterfaceWebServiceProtocolOptionsProtocolValidCCRs.includes(
                addDeviceItem.InterfaceWebServiceProtocol
              )
            "
            class="row-max"
          >
            <el-checkbox
              v-model="addDeviceItem.AutoPrint"
              @change="onAutoPrintChanged"
            >
            </el-checkbox>
            <span class="row-left-divider">自动采集重量值</span>
            <span>，间隔时间 </span>
            <el-input
              class="time-input"
              @change="onDeltaMsChanged"
              v-model="addDeviceItem.AutoPrintDeltaSec"
              placeholder=""
              :disabled="!addDeviceItem.AutoPrint"
            />
            <span> 秒</span>
          </div>
          <div
            v-if="
              !InterfaceWebServiceProtocolOptionsProtocolValidCCRs.includes(
                addDeviceItem.InterfaceWebServiceProtocol
              )
            "
            class="row-max"
          >
            <el-checkbox v-model="addDeviceItem.OnlyStable"> </el-checkbox>
            <span class="row-left-divider">仅采集稳定重量值</span>
          </div>
          <div
            v-if="
              !InterfaceWebServiceProtocolOptionsProtocolValidCCRs.includes(
                addDeviceItem.InterfaceWebServiceProtocol
              )
            "
            class="row-max"
          >
            <el-checkbox v-model="addDeviceItem.AutoTareOnStart"> </el-checkbox>
            <span class="row-left-divider">启动时自动去皮/归零</span>
          </div>
          <div
            v-if="
              !InterfaceWebServiceProtocolOptionsProtocolValidCCRs.includes(
                addDeviceItem.InterfaceWebServiceProtocol
              )
            "
            class="row-max"
          >
            <el-checkbox v-model="addDeviceItem.AutoTareOnPrint"> </el-checkbox>
            <span class="row-left-divider">采集重量值后自动去皮</span>
          </div>

          <div
            v-if="
              !InterfaceWebServiceProtocolOptionsProtocolValidCCRs.includes(
                addDeviceItem.InterfaceWebServiceProtocol
              )
            "
            class="row-divider"
          >
            <span class="bold">手动操作快捷键</span>
          </div>
          <div
            v-if="
              !InterfaceWebServiceProtocolOptionsProtocolValidCCRs.includes(
                addDeviceItem.InterfaceWebServiceProtocol
              )
            "
            class="row-max"
          >
            <span class="key-tips">采集重量值</span>
            <div
              v-if="addDeviceItem.ShortcutPrint"
              class="key-select-single"
              @click="onShortcutPrintClear"
              @mouseover="mouseoverShortcutPrint = true"
              @mouseout="mouseoverShortcutPrint = false"
            >
              <div class="key-select-item">
                <div
                  :class="mouseoverShortcutPrint ? 'key-select-item-bg' : ''"
                >
                  {{ keyboardValue(addDeviceItem.ShortcutPrint) }}
                </div>
                <div
                  v-if="mouseoverShortcutPrint"
                  class="key-select-item-hover"
                >
                  <el-icon class="key-select-item-hover-icon">
                    <close />
                  </el-icon>
                </div>
              </div>
            </div>
            <el-input
              v-if="!addDeviceItem.ShortcutPrint"
              class="key-input"
              @blur="onShortcutPrintBlur"
              @focus="onShortcutPrintFocus"
              @input="onShortcutPrintInput"
              @clear="onShortcutPrintClear"
              v-model="ShortcutPrintInput"
              @change="checkShortcutTarePrintSame"
              placeholder=""
              @keydown.enter.prevent="byTab($event.target.value)"
              @keydown.tab.prevent="byTab($event.target.value)"
              :disabled="addDeviceItem.AutoPrint"
            >
              <!-- <template #append>
                <el-icon @click="onShortcutPrintClear">
                  <Close />
                </el-icon>
              </template> -->
            </el-input>
          </div>
          <div
            v-if="
              !InterfaceWebServiceProtocolOptionsProtocolValidCCRs.includes(
                addDeviceItem.InterfaceWebServiceProtocol
              )
            "
            class="row-max key-divider"
          >
            <span class="key-tips">去皮/归零</span>
            <div
              v-if="addDeviceItem.ShortcutTare"
              class="key-select-single"
              @click="onShortcutTareClear"
              @mouseover="mouseoverShortcutTare = true"
              @mouseout="mouseoverShortcutTare = false"
            >
              <div class="key-select-item">
                <div :class="mouseoverShortcutTare ? 'key-select-item-bg' : ''">
                  {{ keyboardValue(addDeviceItem.ShortcutTare) }}
                </div>
                <div v-if="mouseoverShortcutTare" class="key-select-item-hover">
                  <el-icon class="key-select-item-hover-icon">
                    <close />
                  </el-icon>
                </div>
              </div>
            </div>
            <el-input
              v-if="!addDeviceItem.ShortcutTare"
              class="key-input"
              @blur="onShortcutTareBlur"
              @focus="onShortcutTareFocus"
              @input="onShortcutTareInput"
              @clear="onShortcutTareClear"
              v-model="ShortcutTareInput"
              @change="checkShortcutTarePrintSame"
              placeholder=""
              @keydown.enter.prevent="byTab($event.target.value)"
              @keydown.tab.prevent="byTab($event.target.value)"
            >
              <!-- <template #append>
                <el-icon @click="onShortcutTareClear">
                  <Close />
                </el-icon>
              </template> -->
            </el-input>
          </div>
          <span
            class="key-divider"
            v-if="
              !InterfaceWebServiceProtocolOptionsProtocolValidCCRs.includes(
                addDeviceItem.InterfaceWebServiceProtocol
              ) && addDeviceItem.checkShortcutTarePrintSame
            "
            :style="{ color: '#F56C6C' }"
            >{{ errorTipsForAutoPrintAndTareSameKeyBoard }}</span
          >
        </div>
      </div>
    </div>

    <div
      v-if="
        false &&
        !isWizConnector &&
        !InterfaceWebServiceProtocolOptionsProtocolValidCCRs.includes(
          addDeviceItem.InterfaceWebServiceProtocol
        )
      "
      class="row-max output-bg-divider"
    >
      <span>模拟键盘信号即时输出</span>
      <el-switch
        class="output-switcher"
        v-model="addDeviceItem.outputKeyBoardEnabled"
        @change="checkAutoPrint"
      >
      </el-switch>
    </div>
    <span
      class="row-max"
      v-if="
        !InterfaceWebServiceProtocolOptionsProtocolValidCCRs.includes(
          addDeviceItem.InterfaceWebServiceProtocol
        ) && addDeviceItem.outputKeyBoardEnabledChecked
      "
      :style="{ color: '#F56C6C' }"
      >自动采集间隔时间不能小于2秒，否则无法使用模拟键盘</span
    >
    <div
      v-if="
        !InterfaceWebServiceProtocolOptionsProtocolValidCCRs.includes(
          addDeviceItem.InterfaceWebServiceProtocol
        )
      "
      class="row-top-divider"
    ></div>
    <div
      v-if="
        !InterfaceWebServiceProtocolOptionsProtocolValidCCRs.includes(
          addDeviceItem.InterfaceWebServiceProtocol
        ) && addDeviceItem.outputKeyBoardEnabled
      "
      class="arrow-container"
    >
      <div class="arrow-up"></div>
      <div class="arrow-divider"></div>
      <div class="arrow-bg">
        <div class="arrow-container-inner">
          <div v-if="screenLockEnabled" class="row-max">
            <el-checkbox v-model="addDeviceItem.outputScreenEnabled">
            </el-checkbox>
            <span class="row-left-divider">锁定目标文件或窗口标题</span>
          </div>
          <el-select
            v-if="screenLockEnabled"
            class="output-select"
            filterable
            allow-create
            clearable
            @change="portScreenNameChanged"
            @focus="screenInputFocus"
            default-first-option
            v-model="addDeviceItem.outputScreenName"
            placeholder="请输入目标文件或窗口标题"
            :disabled="!addDeviceItem.outputScreenEnabled"
          >
            <el-option
              v-for="item in addDeviceItemScreenOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
          <span
            class="output-select"
            v-if="screenLockEnabled && addDeviceItem.outputScreenEnabledChecked"
            :style="{ color: '#F56C6C' }"
            >请选择或者输入窗口名称！</span
          >
          <el-table
            class="output-keys"
            :data="addDeviceItem.keyboardData"
            :border="true"
          >
            <el-table-column label="数据">
              <template #default="scope">
                <el-select
                  v-model="scope.row.data"
                  clearable
                  @change="onCmdAndDataChanged"
                  placeholder="请选择数据"
                >
                  <el-option
                    v-for="item in addDeviceItemKeyboardOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="键盘命令" min-width="160">
              <template #default="scope">
                <div class="row-max">
                  <div
                    v-if="scope.row.cmdSelect && scope.row.cmdSelect.length > 0"
                    class="key-select"
                  >
                    <div
                      class="key-select-item"
                      v-for="(item, index) in scope.row.cmdSelect"
                      :key="item"
                      @mouseover="cmdSelectMouseover(item, index)"
                      @mouseout="cmdSelectMouseout()"
                      @click="cmdSelectClick(scope.row, item, index)"
                    >
                      <div
                        :class="
                          item === mouseover.item && index === mouseover.index
                            ? 'key-select-item-bg'
                            : ''
                        "
                      >
                        {{ keyboardValue(item) }}
                      </div>
                      <div
                        v-if="
                          item === mouseover.item && index === mouseover.index
                        "
                        class="key-select-item-hover"
                      >
                        <el-icon class="key-select-item-hover-icon">
                          <close />
                        </el-icon>
                      </div>
                    </div>
                  </div>
                  <el-input
                    @blur="onCmdBlur(scope.row)"
                    @input="onCmdInput(scope.row)"
                    @focus="onCmdFocus(scope.row)"
                    @clear="onCmdClear(scope.row)"
                    @change="onCmdAndDataChanged"
                    v-model="scope.row.cmd"
                    placeholder=""
                    clearable
                    @keydown.enter.prevent="byTab($event.target.value)"
                    @keydown.tab.prevent="byTab($event.target.value)"
                  >
                    <template #append>
                      <el-icon @click="onCmdClear(scope.row)">
                        <Close />
                      </el-icon>
                    </template>
                  </el-input>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <div class="row-max row-btn-divider">
      <el-button v-show="isAdd" @click="_addTianpingAndService">保存</el-button>
      <el-button v-show="!isAdd" @click="_updateTianpingAndService"
        >修改</el-button
      >
      <el-button class="row-btn-left-divider" @click="cancel">取消</el-button>
    </div>
  </el-space>
</template>

<script>
import {
  addTianpingAndService,
  getPorts,
  getScreenNames,
  getLastKey,
  getDeviceInfo,
  getNames,
  getAllDeviceTags,
} from "../api/device";
import { inject, toRefs, computed, ref } from "vue";
import { ElMessage, ElLoading } from "element-plus";

export default {
  name: "AddOrUpdateDevice",
  props: {
    msg: String,
  },
  components: {},
  mounted() {
    this.loadingPorts = true;
    this.startLoadingPorts(false);
  },
  unmounted() {
    this.addDeviceItemInterfaceRS232ComOptionsParams = {};
    this.loadingPorts = false;
    this.loadingShortcutPrint = false;
    this.loadingShortcutTare = false;
    this.loadingShortcutCmd = false;
  },
  methods: {
    onInterfaceChanged: function (item) {
      if (
        this.addDeviceItem.Interface === this.addDeviceItemInterfaceOptionsRS232
      ) {
        this.addDeviceItem.InterfaceWebServiceProtocol =
          this.InterfaceWebServiceProtocolOptionsProtocolRs232;
      } else if (
        this.addDeviceItem.Interface === this.addDeviceItemInterfaceOptionsTcp
      ) {
        // Object.keys(this.InterfaceWebServiceProtocolOptions).forEach((key) => {
        //   const value = this.InterfaceWebServiceProtocolOptions[key];
        //   if (value === this.addDeviceItem.InterfaceWebServiceProtocolLabel) {
        //     this.addDeviceItem.InterfaceWebServiceProtocol = key;
        //   }
        // });
        this.addDeviceItem.InterfaceWebServiceProtocol =
          this.addDeviceItem.InterfaceWebServiceProtocolOptionsProtocolSbiTcpClient;
      } else if (
        this.addDeviceItem.Interface ===
        this.addDeviceItemInterfaceOptionsWebService
      ) {
        this.addDeviceItem.InterfaceWebServiceProtocol =
          this.InterfaceWebServiceProtocolOptionsProtocolWebCCR;
      } else if (
        this.addDeviceItem.Interface === this.addDeviceItemInterfaceOptionsCCR
      ) {
        this.addDeviceItem.InterfaceWebServiceProtocol =
          this.InterfaceWebServiceProtocolOptionsProtocolCCR;
      }
      console.log(
        "onInterfaceChanged",
        item,
        this.addDeviceItem.InterfaceWebServiceProtocol
      );
    },
    onInterfaceWebServiceProtocolChanged: function (item) {
      console.log("onInterfaceWebServiceProtocolChanged", item);
      this.addDeviceItem.InterfaceWebServiceProtocol = item;
      this.addDeviceItem.InterfaceWebServiceProtocolLabel =
        this.InterfaceWebServiceProtocolOptions[item] || "";
    },
    keyboardValue: function (item) {
      var itemShow = item + "";
      if (
        itemShow.split(" ").length === 2 &&
        itemShow.split(" ")[0] === itemShow.split(" ")[1]
      ) {
        itemShow = itemShow.split(" ")[0];
      }
      return itemShow;
    },
    byTab: function (event) {
      console.log("tab", event);
    },
    checkShortcutTarePrintSame() {
      this.addDeviceItem.checkShortcutTarePrintSame =
        this.addDeviceItem.ShortcutPrint &&
        this.addDeviceItem.ShortcutPrint === this.addDeviceItem.ShortcutTare;
      console.log(
        "checkShortcutTarePrintSame",
        this.addDeviceItem.checkShortcutTarePrintSame
      );
    },
    checkLoadingPorts() {
      if (!this.loadingPorts) {
        return;
      }
      setTimeout(() => {
        if (!this.loadingPorts) {
          return;
        }
        this.startLoadingPorts(false);
      }, 10000);
    },
    startLoadingPorts(once) {
      var callbackPorts = () => {
        var callbackScreen = () => {
          if (!once) {
            this.checkLoadingPorts();
          }
        };
        if (!this.screenLockEnabled) {
          callbackScreen();
        } else {
          getScreenNames(
            {},
            (data) => {
              callbackScreen();
              console.log("get screen data", data);
              if (data && data.data && data.data.data && data.data.code > 0) {
                const newOptions = [];
                Object.keys(data.data.data).forEach((key) => {
                  var item = data.data.data[key];
                  newOptions.push(item);
                });
                this.addDeviceItemScreenOptions = newOptions;
              }
            },
            (err) => {
              callbackScreen();
              console.log("get screen err", err);
            }
          );
        }
      };
      this.isPortScanning =
        (this.addDeviceItemInterfaceRS232ComOptions || []).length === 0;
      getPorts(
        {},
        (data) => {
          this.isPortScanning = false;
          callbackPorts();
          console.log("loading ports data", data);
          if (data && data.data && data.data.data && data.data.code > 0) {
            const newOptions = [];
            Object.keys(data.data.data).forEach((key) => {
              var item = data.data.data[key];
              var itemParams =
                this.addDeviceItemInterfaceRS232ComOptionsParams[item];
              newOptions.push({
                port: item,
                itemParams: itemParams,
              });
            });
            this.addDeviceItemInterfaceRS232ComOptions = newOptions;
          }
        },
        (err) => {
          this.isPortScanning = false;
          callbackPorts();
          console.log("loading ports err", err);
        }
      );
    },
    onCmdInput(e) {
      console.log("onCmdInput", e);
      if (this.addDeviceItem.onCmdFocus) {
        this.addDeviceItem.onCmdFocus.cmd = "";
      }
    },
    onCmdBlur(e) {
      console.log("onCmdBlur", e);
      this.loadingShortcutCmd = false;
    },
    onCmdFocus(e) {
      console.log("onCmdFocus", e);
      this.addDeviceItem.onCmdFocus = e;
      this.loadingShortcutCmd = true;
      this.startLoadingShortcut();
    },
    cmdSelectMouseover(e_i, index) {
      if (
        e_i &&
        (this.mouseover.item != e_i || this.mouseover.index != index)
      ) {
        this.mouseover = {
          item: e_i,
          index,
        };
      }
    },
    cmdSelectMouseout() {
      this.mouseover = {};
    },
    cmdSelectClick(e, e_i, index) {
      console.log("cmdSelectClick", e, e_i, index);
      if (e && e.cmdSelect && e_i && index >= 0) {
        e.cmdSelect.splice(index, 1);
        this.mouseover = {};
      }
    },
    onCmdSelectChanged(e) {
      console.log("onCmdSelectChanged", e);
      if (e) {
        if (this.addDeviceItem.onCmdFocus) {
          if (!this.addDeviceItem.onCmdFocus.cmdSelect) {
            this.addDeviceItem.onCmdFocus.cmdSelect = [];
          }
          this.addDeviceItem.onCmdFocus.cmdSelect.push(e);
          this.addDeviceItem.onCmdFocus.cmd = "";
        }
      }
    },
    onCmdSelectPop(e) {
      console.log("onCmdSelectPop", e);
      if (e && e.cmdSelect) {
        e.cmdSelect.pop();
        e.cmd = "";
      }
    },
    onCmdClear(e) {
      console.log("onCmdClear", e);
      if (e) {
        e.cmdSelect = [];
        e.cmd = "";
      }
    },
    onCmdChange(e) {
      console.log("onCmdChange", e);
      if (this.addDeviceItem.onCmdFocus) {
        this.onCmdSelectChanged(e);
        this.onCmdAndDataChanged(e);
        this.addDeviceItem.onCmdFocus.cmd = "";
      }
    },
    onCmdAndDataChanged(e) {
      console.log("onCmdAndDataChanged", e, this.addDeviceItem.keyboardData);
      if (this.addDeviceItem.keyboardData) {
        var valid = true;
        Object.keys(this.addDeviceItem.keyboardData).forEach((key) => {
          var item = this.addDeviceItem.keyboardData[key];
          if (!item.data) {
            valid = false;
          }
        });
        if (valid) {
          this.addDeviceItem.keyboardData.push({
            data: "",
            cmd: "",
            cmdSelect: [],
            index: this.addDeviceItem.keyboardData.length + 1,
          });
        }
      }
    },
    checkAutoPrint(e) {
      console.log("checkAutoPrint", e);
      if (this.addDeviceItem.AutoPrint) {
        if (
          !this.addDeviceItem.AutoPrintDeltaMs ||
          this.addDeviceItem.AutoPrintDeltaMs < 2000
        ) {
          if (this.addDeviceItem.outputKeyBoardEnabled) {
            this.addDeviceItem.outputKeyBoardEnabledChecked = true;
            this.addDeviceItem.outputKeyBoardEnabledLast = true;
          }
          this.addDeviceItem.outputKeyBoardEnabled = false;
        } else {
          if (this.addDeviceItem.outputKeyBoardEnabledChecked) {
            this.addDeviceItem.outputKeyBoardEnabled =
              this.addDeviceItem.outputKeyBoardEnabledLast;
          }
          this.addDeviceItem.outputKeyBoardEnabledChecked = false;
        }
      } else {
        if (this.addDeviceItem.outputKeyBoardEnabledChecked) {
          this.addDeviceItem.outputKeyBoardEnabled =
            this.addDeviceItem.outputKeyBoardEnabledLast;
        }
        this.addDeviceItem.outputKeyBoardEnabledChecked = false;
      }
    },
    onAutoPrintChanged(e) {
      console.log("onAutoPrintChanged", e);
      this.checkAutoPrint();
      if (this.addDeviceItem.AutoPrint) {
        this.ShortcutPrintTemp = this.addDeviceItem.ShortcutPrint;
        this.addDeviceItem.ShortcutPrint = "";
      } else {
        this.addDeviceItem.ShortcutPrint = this.ShortcutPrintTemp;
        this.ShortcutPrintTemp = "";
      }
    },
    onDeltaMsChanged(e) {
      console.log("onDeltaMsChanged", e);
      if (e) {
        var ms = parseFloat(parseFloat(e).toFixed(1));
        if (!ms) {
          ms = 0.1;
        }
        if (ms < 0.1) {
          ms = 0.1;
        }
        this.addDeviceItem.AutoPrintDeltaMs = ms * 1000;
        this.addDeviceItem.AutoPrintDeltaSec = ms;
      } else {
        this.addDeviceItem.AutoPrintDeltaMs = 100;
        this.addDeviceItem.AutoPrintDeltaSec = 0.1;
      }
      this.checkAutoPrint();
    },
    onShortcutPrintFocus(e) {
      console.log("onShortcutPrintFocus", e);
      this.loadingShortcutPrint = true;
      this.startLoadingShortcut();
      this.checkShortcutTarePrintSame();
    },
    onShortcutPrintBlur(e) {
      console.log("onShortcutPrintBlur", e);
      this.loadingShortcutPrint = false;
      this.checkShortcutTarePrintSame();
    },
    onShortcutPrintInput(e) {
      console.log("onShortcutPrintInput", e);
      this.ShortcutPrintInput = "";
      this.mouseoverShortcutPrint = false;
      this.addDeviceItem.ShortcutPrint = this.addDeviceItem.ShortcutPrintLast;
    },
    onShortcutPrintClear(e) {
      console.log("onShortcutPrintClear", e);
      this.addDeviceItem.ShortcutPrint = "";
    },
    onShortcutTareFocus(e) {
      console.log("onShortcutTareFocus", e);
      this.loadingShortcutTare = true;
      this.startLoadingShortcut();
      this.checkShortcutTarePrintSame();
    },
    onShortcutTareBlur(e) {
      console.log("onShortcutTareBlur", e);
      this.loadingShortcutTare = false;
      this.checkShortcutTarePrintSame();
    },
    onShortcutTareInput(e) {
      console.log("onShortcutTareInput", e);
      this.ShortcutTareInput = "";
      this.mouseoverShortcutTare = "";
      this.addDeviceItem.ShortcutTare = this.addDeviceItem.ShortcutTareLast;
    },
    onShortcutTareClear(e) {
      console.log("onShortcutTareClear", e);
      this.addDeviceItem.ShortcutTare = "";
    },
    startLoadingShortcut() {
      if (
        !this.loadingShortcutPrint &&
        !this.loadingShortcutTare &&
        !this.loadingShortcutCmd
      ) {
        return;
      }
      var callback = () => {
        if (
          !this.loadingShortcutPrint &&
          !this.loadingShortcutTare &&
          !this.loadingShortcutCmd
        ) {
          return;
        }
        this.startLoadingShortcut();
      };
      getLastKey(
        {},
        (data) => {
          console.log(
            "getLastKey data",
            data && data.data && data.data.data && data.data.data.key,
            "raw",
            data
          );
          if (data && data.data && data.data.data && data.data.code > 0) {
            var item = data.data.data;
            if (
              item.key &&
              Math.abs(item.time_ns - Date.now() * 1000 * 1000) <
                3 * 1000 * 1000 * 1000
            ) {
              if (this.loadingShortcutPrint) {
                this.addDeviceItem.ShortcutPrintLast = item.key;
                this.addDeviceItem.ShortcutPrint =
                  this.addDeviceItem.ShortcutPrintLast;
              } else if (this.loadingShortcutTare) {
                this.addDeviceItem.ShortcutTareLast = item.key;
                this.addDeviceItem.ShortcutTare =
                  this.addDeviceItem.ShortcutTareLast;
              } else if (this.loadingShortcutCmd) {
                if (this.addDeviceItem.onCmdFocus && item.key) {
                  this.onCmdSelectChanged(item.key);
                  this.onCmdAndDataChanged(item.key);
                  this.addDeviceItem.onCmdFocus.cmd = "";
                }
              }
            } else {
              console.log("invalid item", item);
            }
          }
          callback();
        },
        (err) => {
          console.log("getLastKey err", err);
          callback();
        }
      );
    },
    checkCom(e) {
      console.log(
        "checkCom",
        e,
        "com port",
        this.addDeviceItem.InterfaceRS232Com
      );
      const myCom = [];
      if (this.addDeviceItem.InterfaceRS232Com) {
        myCom.push(this.addDeviceItem.InterfaceRS232Com);
      } else {
        Object.keys(this.addDeviceItemInterfaceRS232ComOptions).forEach(
          (key) => {
            var item = this.addDeviceItemInterfaceRS232ComOptions[key];
            myCom.push(item.port);
          }
        );
      }
      var size = myCom.length;
      if (size > 0) {
        var index = 0;
        const loading = ElLoading.service({
          lock: true,
          text: "智能检测中...",
          background: "rgba(0, 0, 0, 0.7)",
        });
        setTimeout(() => {
          loading.close();
        }, 20000);
        Object.keys(myCom).forEach((key) => {
          const port = myCom[key];
          var callback = () => {
            if (!this.addDeviceItemInterfaceRS232ComOptionsParams[port]) {
              this.addDeviceItemInterfaceRS232ComOptionsParams[port] =
                "通讯异常！";
            }
            if (this.addDeviceItem.InterfaceRS232Com == port) {
              this.addDeviceItem.InterfaceRS232ComSerialTips =
                this.addDeviceItemInterfaceRS232ComOptionsParams[port];
            }
            var newOption = this.addDeviceItemInterfaceRS232ComOptions;
            Object.keys(newOption).forEach((key) => {
              var item = newOption[key];
              if (item.port == port) {
                item.itemParams =
                  this.addDeviceItemInterfaceRS232ComOptionsParams[port];
                this.addDeviceItemInterfaceRS232ComOptions = newOption;
              }
            });
            index += 1;
            if (index >= size) {
              loading.close();
            }
          };
          getDeviceInfo(
            { port: port },
            (data) => {
              console.log("get device info data", data);
              if (data && data.data && data.data.data && data.data.code > 0) {
                if (data.data.data.serial_number || data.data.data.model_id) {
                  this.addDeviceItemInterfaceRS232ComOptionsParams[port] =
                    "连接正常";
                  if (data.data.data.serial_number) {
                    this.addDeviceItemInterfaceRS232ComOptionsParams[port] +=
                      "，序列号：" + data.data.data.serial_number;
                  }
                  if (data.data.data.model_id) {
                    this.addDeviceItemInterfaceRS232ComOptionsParams[port] +=
                      "，型号：" + data.data.data.model_id;
                  }
                } else if (data.data.data.port_fail) {
                  this.addDeviceItemInterfaceRS232ComOptionsParams[port] =
                    "找到串口，通信失败";
                } else if (data.data.data.no_port) {
                  this.addDeviceItemInterfaceRS232ComOptionsParams[port] =
                    "未找到该串口";
                } else if (data.data.data.is_running) {
                  this.addDeviceItemInterfaceRS232ComOptionsParams[port] =
                    "正在运行中";
                }
              }
              callback();
            },
            (err) => {
              callback();
              console.log("get device info err", err);
            }
          );
        });
      }
    },
    portScreenNameChanged(e) {
      console.log("portScreenNameChanged", e);
      if (this.addDeviceItem.outputScreenName) {
        this.addDeviceItem.outputScreenEnabledChecked = false;
      }
    },
    comInputFocus(e) {
      console.log("comInputFocus", e);
      this.startLoadingPorts(true);
    },
    screenInputFocus(e) {
      console.log("screenInputFocus", e);
      this.startLoadingPorts(true);
    },
    comChanged(e) {
      console.log("comChanged", e);
      var com = e.split(" ")[0];
      this.addDeviceItem.InterfaceRS232Com = com;
      var comParams = null;
      if (com) {
        comParams = this.addDeviceItemInterfaceRS232ComOptionsParams[com];
      }
      if (comParams) {
        this.addDeviceItem.InterfaceRS232ComSerialTips = comParams;
      } else {
        this.addDeviceItem.InterfaceRS232ComSerialTips = "";
      }
    },
    cancel() {
      this.setRightContent(this.lastRightContent);
    },
    _addTianpingAndService() {
      this._realAddTianpingAndService(false);
    },
    _updateTianpingAndService() {
      this._realAddTianpingAndService(true);
    },
    _realAddTianpingAndService(update) {
      let valid = true;
      if (!this.addDeviceItem.Name || !this.addDeviceItem.Interface) {
        valid = false;
      } else if (
        this.addDeviceItem.Interface == this.addDeviceItemInterfaceOptionsRS232
      ) {
        if (!this.addDeviceItem.InterfaceRS232Com) {
          valid = false;
        }
      } else if (
        this.addDeviceItem.Interface == this.addDeviceItemInterfaceOptionsTcp
      ) {
        if (
          !this.addDeviceItem.InterfaceWebServiceAddress ||
          !this.addDeviceItem.InterfaceWebServicePort ||
          !this.addDeviceItem.InterfaceWebServiceProtocol
        ) {
          valid = false;
        }
        if (
          !this.addDeviceItem.InterfaceWebServiceComparator &&
          this.InterfaceWebServiceProtocolOptionsProtocolValidCCRs.includes(
            this.addDeviceItem.InterfaceWebServiceProtocol
          )
        ) {
          valid = false;
        }
      } else if (
        this.addDeviceItem.Interface ==
        this.addDeviceItemInterfaceOptionsWebService
      ) {
        if (
          !this.addDeviceItem.InterfaceWebServiceAddress ||
          !this.addDeviceItem.InterfaceWebServiceProtocol
        ) {
          valid = false;
        }
        if (
          !this.addDeviceItem.InterfaceWebServiceComparator &&
          this.InterfaceWebServiceProtocolOptionsProtocolValidCCRs.includes(
            this.addDeviceItem.InterfaceWebServiceProtocol
          )
        ) {
          valid = false;
        }
      } else if (
        this.addDeviceItem.Interface == this.addDeviceItemInterfaceOptionsCCR
      ) {
        if (
          !this.addDeviceItem.InterfaceWebServiceAddress ||
          !this.addDeviceItem.InterfaceWebServicePort ||
          !this.addDeviceItem.InterfaceWebServiceProtocol
        ) {
          valid = false;
        }
        if (
          !this.addDeviceItem.InterfaceWebServiceComparator &&
          this.InterfaceWebServiceProtocolOptionsProtocolValidCCRs.includes(
            this.addDeviceItem.InterfaceWebServiceProtocol
          )
        ) {
          valid = false;
        }
      }
      if (!valid) {
        this.setRequiredColor("#F56C6C");
        ElMessage.error("请填写带*号的所有必填项");
        return;
      }
      if (
        this.addDeviceItem.outputScreenEnabled &&
        !this.addDeviceItem.outputScreenName
      ) {
        this.addDeviceItem.outputScreenEnabledChecked = true;
        ElMessage.error("请选择或者输入窗口名称！");
        return;
      }
      var nameDuplicated = false;
      if (this.rawDeviceNames) {
        Object.values(this.rawDeviceNames).forEach((key) => {
          if (key.name == this.addDeviceItem.Name && !key.device_deleted) {
            nameDuplicated = true;
          }
        });
      }
      if (!update && nameDuplicated) {
        ElMessage.error("设备名称不能重复");
        return;
      }
      if (
        this.addDeviceItem.ShortcutPrint &&
        this.addDeviceItem.ShortcutPrint === this.addDeviceItem.ShortcutTare
      ) {
        ElMessage.error(this.errorTipsForAutoPrintAndTareSameKeyBoard);
        return;
      }
      const loading = ElLoading.service({
        lock: true,
        text: this.isAdd ? "新建中..." : "修改中...",
        background: "rgba(0, 0, 0, 0.7)",
      });
      setTimeout(() => {
        loading.close();
      }, 20000);
      addTianpingAndService(
        { ...this.addDeviceItem },
        (data) => {
          loading.close();
          console.log("data", data);
          if (data && data.data) {
            if (
              data.data.data &&
              data.data.code > 0 &&
              data.data.data.internal_id
            ) {
              this.setRightContent("BasicRightContent");

              getNames(
                {},
                (data) => {
                  console.log("getNames", data);
                  if (
                    data &&
                    data.data &&
                    data.data.data &&
                    data.data.code > 0
                  ) {
                    const newDeviceNames = [];
                    Object.keys(data.data.data).forEach((key) => {
                      const item = data.data.data[key];
                      newDeviceNames.push(item.name);
                    });
                    this.setDeviceNames(newDeviceNames);
                    this.setRawDeviceNames(data.data.data);
                  }
                },
                (err) => {
                  console.log("getNames err", err);
                }
              );

              getAllDeviceTags(
                {},
                (data) => {
                  console.log("getAllDeviceTags", data);
                  if (
                    data &&
                    data.data &&
                    data.data.data &&
                    data.data.code > 0
                  ) {
                    this.setDeviceTags(data.data.data);
                    const deviceTagNames = [];
                    Object.values(data.data.data).forEach((value) => {
                      deviceTagNames.push(value.device_name);
                    });
                    this.setDeviceTagNames(deviceTagNames);
                  }
                },
                (err) => {
                  console.log("getAllDeviceTags err", err);
                }
              );
            } else {
              ElMessage.error("网络错误3：" + JSON.stringify(data));
            }
          } else {
            ElMessage.error("网络错误2：" + JSON.stringify(data));
          }
        },
        (err) => {
          loading.close();
          console.log("err", err);
          ElMessage.error("网络错误1：" + JSON.stringify(err));
        }
      );
    },
  },
  setup() {
    const deviceData = inject("deviceData");
    const menuData = inject("menuData");
    const ShortcutTareInput = ref("");
    const ShortcutPrintInput = ref("");
    const mouseover = ref({});
    const mouseoverShortcutPrint = ref(false);
    const mouseoverShortcutTare = ref(false);
    const ShortcutPrintTemp = ref("");
    const isAdd = computed(
      () => deviceData.addDeviceItem.internal_id == undefined
    );
    const isDelta = computed(
      () =>
        (deviceData.addDeviceItem.DeltaTime != undefined &&
          deviceData.addDeviceItem.DeltaTime != "00:00:00" &&
          deviceData.addDeviceItem.DeltaTime != 0) ||
        (deviceData.addDeviceItem.DeltaTimeMilli != undefined &&
          deviceData.addDeviceItem.DeltaTimeMilli != 0)
    );
    const isPortScanning = ref(false);

    const data = {
      ...toRefs(deviceData),
      ...toRefs(menuData),
      isAdd,
      isDelta,
      ShortcutTareInput,
      ShortcutPrintInput,
      mouseover,
      mouseoverShortcutPrint,
      mouseoverShortcutTare,
      ShortcutPrintTemp,
      isPortScanning,
    };
    return data;
  },
};
</script>

<style scoped>
.container {
  width: 576px;
}

.row-max {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.title {
  min-width: 50px;
  text-align: start;
}

.title-select {
  min-width: 373px;
}

.divider-middle {
  margin-top: 16px;
}

.arrow-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 50px;
}

.arrow-container-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  z-index: 4;
}

.arrow-bg {
  width: 520px;
  height: 100%;
  transform: rotate(0deg);
  border: 1px solid #bbbbbb;
  background: #ffffff;
  z-index: 1;
}

.arrow-up {
  width: 10px;
  height: 10px;
  position: absolute;
  transform: rotate(45deg);
  border: 1px solid #bbbbbb;
  background: #ffffff;
  margin-left: 25px;
  margin-top: -5px;
  z-index: 2;
}

.arrow-up-tcp {
  margin-left: 105px;
}

.arrow-up-ccr {
  margin-left: 185px;
}

.arrow-up-web-service {
  margin-left: 265px;
}

.arrow-divider {
  width: 16px;
  height: 10px;
  position: absolute;
  transform: rotate(0deg);
  background: #ffffff;
  margin-left: 23px;
  margin-top: 1px;
  z-index: 3;
}

.arrow-divider-tcp {
  margin-left: 103px;
}

.arrow-divider-ccr {
  margin-left: 183px;
}

.arrow-divider-web-service {
  margin-left: 263px;
}

.select-input {
  min-width: 206px;
  margin-left: 10px;
  margin-right: 30px;
  flex: 1;
}

.tips-input {
  min-width: 100px;
  text-align: start;
}

.bold {
  font-weight: bold;
}

.row-divider {
  margin-top: 30px;
}

.row-left-divider {
  margin-left: 10px;
}

.row-top-divider {
  margin-top: 10px;
}

.time-input {
  width: 65px;
  margin-left: 10px;
}

.key-input {
  width: 260px;
}

.key-tips {
  width: 100px;
  text-align: start;
}

.key-divider {
  margin-top: 4px;
}

.output-bg-divider {
  margin-top: 40px;
}

.output-switcher {
  width: 50px;
  margin-left: 10px;
}

.output-select {
  width: 407px;
  margin-left: 24px;
  text-align: start;
}

.row-btn-divider {
  margin-top: 40px;
  margin-bottom: 40px;
}

.row-btn-left-divider {
  margin-left: 20px;
}

.output-keys {
  width: 431px;
  margin-top: 20px;
}

.el-table {
  --el-table-header-text-color: #ffffff !important;
  --el-table-text-color: #101010 !important;
  --el-table-header-bg-color: #41419f !important;
}

.key-select-single {
  padding: 2px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.key-select {
  width: 200px;
  padding: 2px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.key-select-item {
  background-color: #efefef;
  border: 1px solid #767676;
  border-radius: 4px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 1px;
  padding-bottom: 1px;
  margin: 2px;
  line-height: 22px;
  height: 24px;
  font-size: 12px;
  color: #000000;
}

.key-select-item-bg {
  color: #767676;
  opacity: 0.3;
}

.key-select-item-hover {
  position: absolute;
  margin-top: -20px;
  margin-left: -4px;
}

.key-select-item-hover-icon {
  font-size: 16px;
}

.el-input :deep(.el-input-group__prepend) {
  background-color: transparent !important;
  padding: 0px !important;
  margin: 0px !important;
}
</style>
