import { reactive } from "vue";

const menuData = {
  collapse: false,
  menuMinWidth: 300,
  menuMinWidthCollapse: 100,
  lastRightContent: "BasicRightContent",
  rightContent: "BasicRightContent",
  systemInfo: {},
  screenNameNotFound: false,
  screenNameNotFoundTips: "",
  loadingScreenNameNotFound: false,
  lastScreenNameNotFoundShowTime: 0,
  deviceConnectFailed: false,
  deviceConnectFailedTips: "",
  loadingDeviceConnectFailed: false,
  lastDeviceConnectFailedShowTime: 0,
  showSubCommands: true,
  upgradeVersionData: {},

  setUpgradeVersionData(upgradeVersionData) {
    this.upgradeVersionData = upgradeVersionData || {};
  },

  setRightContent(value) {
    this.lastRightContent = this.rightContent;
    this.rightContent = value;
  },

  setCollapse(value) {
    this.collapse = value;
  },

  getMenuMinWidth() {
    if (this.collapse) {
      return this.menuMinWidthCollapse;
    } else {
      return this.menuMinWidth;
    }
  },

  getMenuExpandTips() {
    if (this.collapse) {
      return "展开菜单";
    } else {
      return "收起菜单";
    }
  },

  setSystemInfo(value) {
    console.log("setSystemInfo", value);
    this.systemInfo = value || {};
  },

  showScreenNameNotFound(value) {
    if (!value || value.length == 0) {
      return;
    }
    if (Date.now() - this.lastScreenNameNotFoundShowTime < 60000) {
      return;
    }
    this.lastScreenNameNotFoundShowTime = Date.now();
    this.screenNameNotFound = true;
    var tips = "未找到有效的窗口，名称如下：\n\n";
    if (value) {
      Object.keys(value).forEach((key) => {
        var item = value[key];
        if (item.length > 36) {
          item = item.substring(36);
        }
        tips += item + "\n";
      });
    }
    tips +=
      "\n\n请检查包含以上名称的窗口是否已经打开？是否存在多屏幕？否则将无法正常使用模拟信号键盘输出功能！";
    this.screenNameNotFoundTips = tips;
  },

  hideScreenNameNotFound(value) {
    this.screenNameNotFound = false;
    this.lastScreenNameNotFoundShowTime = Date.now();
    this.screenNameNotFoundTips = value;
  },

  showDeviceConnectFailed(value) {
    if (!value || value.length == 0) {
      return;
    }
    if (Date.now() - this.lastDeviceConnectFailedShowTime < 60000) {
      return;
    }
    this.lastDeviceConnectFailedShowTime = Date.now();
    this.deviceConnectFailed = true;
    var tips = "以下设备连接出现异常，名称如下：\n\n";
    if (value) {
      Object.keys(value).forEach((key) => {
        var item = value[key];
        if (item.length > 36) {
          item = item.substring(36);
        }
        tips += item + "\n";
      });
    }
    tips += "\n\n请尽快检查设备异常原因，确保已经恢复！";
    this.deviceConnectFailedTips = tips;
  },

  hideDeviceConnectFailed(value) {
    this.deviceConnectFailed = false;
    this.lastDeviceConnectFailedShowTime = Date.now();
    this.deviceConnectFailedTips = value;
  },
};
export default reactive(menuData);
