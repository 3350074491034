<template>
  <div class="container">
    <div class="header">
      <img class="header-icon" :src="require('@/assets/wizconnect_logo.png')" />
      <div class="header-menu">
        <NavLeft />
      </div>
    </div>

    <div class="content">
      <component v-bind:is="rightContent"></component>
    </div>

    <div class="bottom_space">
      <el-space />
    </div>

    <el-dialog
      v-model="screenNameNotFound"
      title="锁定窗口未找到"
      width="30%"
      :before-close="handleCloseScreenNameNotFound"
    >
      <span class="tips">{{ screenNameNotFoundTips }}</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="screenNameNotFound = false"
            >知道了</el-button
          >
        </span>
      </template>
    </el-dialog>

    <el-dialog
      v-model="deviceConnectFailed"
      title="设备连接异常"
      width="30%"
      :before-close="handleCloseDeviceConnectFailed"
    >
      <span class="tips">{{ deviceConnectFailedTips }}</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="deviceConnectFailed = false"
            >知道了</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { inject, toRefs, ref } from "vue";
import BasicRightContent from "../components/BasicRightContent.vue";
import SoftwareAbout from "../components/SoftwareAbout.vue";
import AddOrUpdateDevice from "../components/AddOrUpdateDevice.vue";
import MyDatabase from "../components/MyDatabase.vue";
import NavLeft from "../components/NavLeft.vue";
import { getIp } from "../api/get_ip";
import { getNames, getAllDeviceTags } from "../api/device";
import { needRedirectToLicense } from "../api/license";
import {
  getScreenStatus,
  getConnectStatus,
  getConfigByKey,
  isWizConnector,
  handleWizConnectorKeyName,
  getUpgradeVersion,
  updateQueryConf,
} from "../api/device";

export default {
  name: "Home",
  props: {
    msg: String,
  },
  components: {
    BasicRightContent,
    SoftwareAbout,
    AddOrUpdateDevice,
    MyDatabase,
    NavLeft,
  },
  unmounted() {
    this.loadingScreenNameNotFound = false;
    this.loadingDeviceConnectFailed = false;
    this.destroyLatestVersionTimer();
  },
  mounted() {
    this.loadingScreenNameNotFound = true;
    this.startLoadingScreenNameNotFound();
    this.loadingDeviceConnectFailed = true;
    this.startLoadingDeviceConnectFailed();

    updateQueryConf(
      this.$route.query || {},
      (res) => {
        console.log("updateQueryConf res", res);
      },
      (err) => {
        console.log("updateQueryConf err", err);
      }
    );

    getNames(
      {},
      (data) => {
        console.log("getNames", data);
        if (data && data.data && data.data.data && data.data.code > 0) {
          const newDeviceNames = [];
          Object.keys(data.data.data).forEach((key) => {
            const item = data.data.data[key];
            newDeviceNames.push(item.name);
          });
          this.setDeviceNames(newDeviceNames);
          this.setRawDeviceNames(data.data.data);
        }
      },
      (err) => {
        console.log("getNames err", err);
      }
    );

    getAllDeviceTags(
      {},
      (data) => {
        console.log("getAllDeviceTags", data);
        if (data && data.data && data.data.data && data.data.code > 0) {
          this.setDeviceTags(data.data.data);
          const deviceTagNames = [];
          Object.values(data.data.data).forEach((value) => {
            deviceTagNames.push(value.device_name);
          });
          this.setDeviceTagNames(deviceTagNames);
        }
      },
      (err) => {
        console.log("getAllDeviceTags err", err);
      }
    );

    getConfigByKey(
      this.lastDeviceQueryData,
      (res) => {
        if (res && res.data && res.data.data) {
          this.lastDeviceQueryData = res.data.data;
        }
        console.log("getConfigByKey lastDeviceQueryData res", res);
      },
      (err) => {
        console.log("getConfigByKey lastDeviceQueryData err", err);
      }
    );

    getConfigByKey(
      this.sendToClipboardInfo,
      (res) => {
        if (res && res.data && res.data.data) {
          this.sendToClipboardInfo = res.data.data;
        }
        console.log("getConfigByKey sendToClipboardInfo res", res);
      },
      (err) => {
        console.log("getConfigByKey sendToClipboardInfo err", err);
      }
    );

    getConfigByKey(
      this.exportDataTemplateInfo,
      (res) => {
        if (res && res.data && res.data.data) {
          this.exportDataTemplateInfo = res.data.data;
        }
        console.log("getConfigByKey exportDataTemplateInfo res", res);
      },
      (err) => {
        console.log("getConfigByKey exportDataTemplateInfo err", err);
      }
    );

    isWizConnector(
      {},
      (res) => {
        let isWizConnector = false;
        if (res && res.data && res.data.data) {
          isWizConnector = true;
        }
        this.isWizConnector = isWizConnector;
        if (isWizConnector) {
          document.addEventListener(
            "keydown",
            (event) => {
              const keyName = event.key;
              console.log("keydown keyName = " + keyName);
              handleWizConnectorKeyName(
                {
                  keyName,
                },
                (res) => {
                  console.log("handleWizConnectorKeyName res", res);
                },
                (err) => {
                  console.log("handleWizConnectorKeyName err", err);
                }
              );
            },
            false
          );
        }
        console.log("isWizConnector res", res);
      },
      (err) => {
        console.log("isWizConnector err", err);
      }
    );

    getIp(
      {},
      (data) => {
        if (data && data.data && data.data.code == 1 && data.data.data) {
          console.log("href", window.location.href, "ip", data.data.data);
          // let host = window.location.host;
          // host = host.split(":")[0];
          // if (host != data.data.data) {
          //   window.location.href = window.location.href.replace(
          //     host,
          //     data.data.data
          //   );
          // }
        }
        needRedirectToLicense(
          {},
          (data) => {
            if (data && data.data && data.data.code == 1 && data.data.data) {
              if (window.location.href.indexOf("license") < 0) {
                this.$router.push("/license");
              }
            }
          },
          () => {}
        );
      },
      () => {}
    );

    this.createLatestVersionTimer();
  },
  methods: {
    _getUpgradeVersion() {
      getUpgradeVersion(
        {},
        (res) => {
          console.log("getUpgradeVersion res", res);
          if (res && res.data && res.data.data) {
            this.setUpgradeVersionData(res.data.data);
          }
        },
        (err) => {
          console.log("getUpgradeVersion err", err);
        }
      );
    },
    createLatestVersionTimer() {
      this._getUpgradeVersion();
      if (!this.getLatestVersionTimer) {
        this.getLatestVersionTimer = setInterval(() => {
          this._getUpgradeVersion();
        }, 2 * 60 * 1000);
      }
    },
    destroyLatestVersionTimer() {
      if (this.getLatestVersionTimer) {
        clearInterval(this.getLatestVersionTimer);
        this.getLatestVersionTimer = null;
      }
    },
    handleCloseScreenNameNotFound(value) {
      console.log("handleCloseScreenNameNotFound", value);
      this.hideScreenNameNotFound(value);
    },
    checkLoadingScreenNameNotFound() {
      if (!this.loadingScreenNameNotFound) {
        return;
      }
      setTimeout(() => {
        if (!this.loadingScreenNameNotFound) {
          return;
        }
        this.startLoadingScreenNameNotFound();
      }, 5000);
    },
    startLoadingScreenNameNotFound() {
      var callback = () => {
        this.checkLoadingScreenNameNotFound();
      };
      getScreenStatus(
        {},
        (data) => {
          callback();
          console.log("get screen status", data);
          if (data && data.data && data.data.data && data.data.code > 0) {
            this.showScreenNameNotFound(data.data.data);
          }
        },
        (err) => {
          callback();
          console.log("get screen status err", err);
        }
      );
    },
    handleCloseDeviceConnectFailed(value) {
      console.log("handleCloseDeviceConnectFailed", value);
      this.hideDeviceConnectFailed(value);
    },
    checkLoadingDeviceConnectFailed() {
      if (!this.loadingDeviceConnectFailed) {
        return;
      }
      setTimeout(() => {
        if (!this.loadingDeviceConnectFailed) {
          return;
        }
        this.startLoadingDeviceConnectFailed();
      }, 3000);
    },
    startLoadingDeviceConnectFailed() {
      var callback = () => {
        this.checkLoadingDeviceConnectFailed();
      };
      getConnectStatus(
        {},
        (data) => {
          callback();
          console.log("get connect status", data);
          if (data && data.data && data.data.data && data.data.code > 0) {
            this.showDeviceConnectFailed(data.data.data);
          }
        },
        (err) => {
          callback();
          console.log("get connect status err", err);
        }
      );
    },
  },
  setup() {
    const deviceData = inject("deviceData");
    const menuData = inject("menuData");
    const getLatestVersionTimer = ref(null);

    const data = {
      ...toRefs(menuData),
      ...toRefs(deviceData),
      getLatestVersionTimer,
    };
    return data;
  },
};
</script>
<style scoped>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  width: 100%;
  height: 52px;
  background-color: #6666c4;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.header-icon {
  height: 65%;
  margin-left: 75px;
  object-fit: scale-down;
}

.header-menu {
  margin-left: 10px;
  margin-right: 75px;
  width: 100%;
  height: 33px;
}

.tips {
  word-break: pre;
  word-wrap: pre;
  white-space: pre-line;
}

.content {
  width: 100%;
  padding-left: 90px;
  padding-right: 90px;
  box-sizing: border-box;
}

.bottom_space {
  width: 100%;
  min-height: 40px;
}
</style>
